/**
 * @ Author: Housefolios
 * @ Create Time: 2023-01-23 16:33:08
 * @ Modified by: David Helmick
 * @ Modified time: 2025-01-16 14:42:58
 * @ Description: Property details page for public properties. Contains code for sidebars and header
 */

import React, { useState, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import clsx from 'clsx'
import map from 'lodash/map'
import filter from 'lodash/filter'
import kebabCase from 'lodash/kebabCase'
import unset from 'lodash/unset'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'
import remove from 'lodash/remove'
import set from 'lodash/set'
import isNil from 'lodash/isNil'
import isNaN from 'lodash/isNaN'
import isEqual from 'lodash/isEqual'
import includes from 'lodash/includes'
import round from 'lodash/round'
import meanBy from 'lodash/meanBy'
import find from 'lodash/find'
import pull from 'lodash/pull'
import cloneDeep from 'lodash/cloneDeep'
import assign from 'lodash/assign'
import get from 'lodash/get'
import ceil from 'lodash/ceil'
import pullAt from 'lodash/pullAt'
import pullAll from 'lodash/pullAll'
import sumBy from 'lodash/sumBy'
import reduce from 'lodash/reduce'
import concat from 'lodash/concat'
import forEach from 'lodash/forEach'
import last from 'lodash/last'
import isArray from 'lodash/isArray'
import head from 'lodash/head'
import split from 'lodash/split'
import compact from 'lodash/compact'
import sortBy from 'lodash/sortBy'
import slice from 'lodash/slice'
import debounce from 'lodash/debounce'
import flatMap from 'lodash/flatMap'
import isObject from 'lodash/isObject'
import lastIndexOf from 'lodash/lastIndexOf'
import join from 'lodash/join'
import toString from 'lodash/toString'
import sum from 'lodash/sum'
import uniqBy from 'lodash/uniqBy'
import startsWith from 'lodash/startsWith'
import replace from 'lodash/replace'

import MoreVertIcon from '@mui/icons-material/MoreVert'

import Comps from '../../PropertyContainer/CenterPage/Comps'
import BuyHold from '../../PropertyContainer/CenterPage/BuyHold'
import FixFlip from '../../PropertyContainer/CenterPage/FixFlip'
import Turnkey from '../../PropertyContainer/CenterPage/Turnkey'
import BRRRR from '../../PropertyContainer/CenterPage/BRRRR'
import ShortTermRental from '../../PropertyContainer/CenterPage/ShortTermRental'
import Wholesale from '../../PropertyContainer/CenterPage/Wholesale'
import BuildToRent from '../../PropertyContainer/CenterPage/BuildToRent'
import MarketAnalysis from '../../PropertyContainer/CenterPage/MarketAnalysis'
import { useTheme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { arrayMoveImmutable } from 'array-move'
import renderInputFieldsFunction from '../../Functions/renderInput'

import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import {
  AppBar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  Drawer,
  FormControl,
  FormGroup,
  Toolbar,
  CircularProgress,
  Backdrop,
  ButtonGroup,
  useMediaQuery,
} from '@mui/material'

import Slider from 'react-slick'

import { useSubscription } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

import moment from 'moment'

import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone'
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk'
import ImageIcon from '@mui/icons-material/Image'
import RoomIcon from '@mui/icons-material/Room'
import { CompareOutlined } from '@mui/icons-material'

import { useMutation, useQuery } from '@apollo/client'
import {
  GET_COMPPROPERTIES,
  COMP_ADDED,
  COMPS_LOADED,
  GET_PROPERTY,
  GET_SIGNED_REQUEST,
  OFFER,
} from '../../PropertyContainer/queries'
import { SAVE_ASSET_MANAGEMENT } from '../../PropertyContainer/mutations'
import PropertyPDF from '../../PropertyContainer/Proforma/pdf'
import { enqueueSnackbar } from 'notistack'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import every from 'lodash/every'
import { DEFAULT_PREFERENCES } from '@/housefolios-components/OrganizationSettings/OrganizationPreferences'
import { SAVE_LOT_PROCESSES } from '@/housefolios-components/Lots/mutations'
import { GET_LOT_PROCESSES } from '@/housefolios-components/Lots/queries'
import { Link, useNavigate } from 'react-router-dom'
import AddLot from '@/housefolios-components/Lots/AddLot'
import ManageFiles from '../../PropertyContainer/CenterPage/ManageFiles'
import BOR from '../../PropertyContainer/CenterPage/BOR'
import { END_TRIAL } from '@/housefolios-components/zComponents/mutations'
import { DEFAULT_ANALYTICS } from '@/housefolios-components/Property/fragments'
import RentalComps from '../../PropertyContainer/CenterPage/RentalComps'
import FileUpload from '@/housefolios-components/PropertyContainer/PropertyDashboard/FileUpload'
import Map from '@/housefolios-components/Properties/Map'
import TempChat from '../../TempChat' // Adjust the import path as needed
import { DEFAULT_ESTIMATES } from '@/constants/defaultValues'
import { getProformaLink } from '@/utils/proforma'
import useAffiliate from '@/hooks/useAffiliate'
import { filterPreferedStrategies } from '@/utils/stratagies'
import { useParams } from 'react-router-dom'
import useEstimatesMenu from '@/hooks/useEstimatesMenu'

const hostname = import.meta.env.VITE_HOSTNAME

const drawerWidth = 240
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  //  menuButton: {
  //    marginRight: theme.spacing(2),
  //  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    //  justifyContent: 'center',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}))

function PublicPropertyDashboard(props) {
  const { id = '', view = 'Summary' } = useParams()

  const classes = useStyles()
  const theme = useTheme()
  const hiddenSmDown = useMediaQuery((theme) =>
    theme.breakpoints.down('sm'),
  )
  const hiddenSmUp = useMediaQuery((theme) =>
    theme.breakpoints.up('sm'),
  )
  const hiddenLgDown = useMediaQuery((theme) =>
    theme.breakpoints.down('lg'),
  )
  const hiddenLgUp = useMediaQuery((theme) =>
    theme.breakpoints.up('lg'),
  )

  const [{ affiliate, urlAffiliate }] = useAffiliate({})

  const [isSidebarMenuOpen, setIsSidebarMenuOpen] = useState(false)

  const [openDrawer, setOpenDrawer] = React.useState(false)
  const handleDrawerOpen = () => {
    setOpenDrawer(true)
  }
  const handleDrawerClose = () => {
    setOpenDrawer(false)
  }

  const organization = {}
  const [basicView, setBasicView] = useState(false)

  const [activeUnit, setActiveUnit] = useState(0)

  const [tab, setTab] = useState('Lease Information')
  const listItems = [
    {
      name: 'Lease Information',
      icon: (
        <FontAwesomeIcon
          icon={['far', 'database']}
          className="font-size-xl mr-2"
        />
      ),
    },
    {
      name: 'Mortgage Payments',
      icon: <CompareOutlined className="mr-2" />,
    },
    {
      name: 'Current Equity',
      icon: (
        <FontAwesomeIcon
          icon={['far', 'database']}
          className="font-size-xl mr-2"
        />
      ),
    },
    {
      name: 'Enter Income',
      icon: <CompareOutlined className="mr-2" />,
    },
    {
      name: 'Expenses',
      icon: (
        <FontAwesomeIcon
          icon={['far', 'database']}
          className="font-size-xl mr-2"
        />
      ),
    },
    {
      name: 'Enter Vacancy',
      icon: <CompareOutlined className="mr-2" />,
    },
    {
      name: 'Distributions',
      icon: (
        <FontAwesomeIcon
          icon={['far', 'database']}
          className="font-size-xl mr-2"
        />
      ),
    },
    {
      name: 'Property Manager',
      icon: <CompareOutlined className="mr-2" />,
    },
    {
      name: 'Last Owner Statement',
      icon: (
        <FontAwesomeIcon
          icon={['far', 'database']}
          className="font-size-xl mr-2"
        />
      ),
    },
  ]

  function SliderArrowNext(props) {
    const { className, onClick } = props
    return (
      <div className={className} onClick={onClick}>
        <FontAwesomeIcon icon={['fas', 'chevron-right']} />
      </div>
    )
  }

  function SliderArrowPrev(props) {
    const { className, onClick } = props
    return (
      <div className={className} onClick={onClick}>
        <FontAwesomeIcon icon={['fas', 'chevron-left']} />
      </div>
    )
  }

  const slider = useRef()
  const widgetsCarousels1B = {
    dots: true,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    adaptiveHeight: true,
    nextArrow: <SliderArrowNext />,
    prevArrow: <SliderArrowPrev />,
  }

  const [imageActiveTab, setImageActiveTab] = useState('Images')

  const tabSwitch = (tab) => {
    if (imageActiveTab !== tab) setImageActiveTab(tab)
  }

  const [compPage, setCompPage] = useState(
    parseInt(localStorage.getItem(`${id}-comps-actual-card-page`)) ||
      1,
  )
  const [potentialCompPage, setPotentialCompPage] = useState(
    parseInt(
      localStorage.getItem(`${id}-comps-potential-card-page`),
    ) || 1,
  )
  const [rentalCompPage, setRentalCompPage] = useState(
    parseInt(localStorage.getItem(`${id}-comps-rental-card-page`)) ||
      1,
  )

  let results = useQuery(GET_PROPERTY, {
    variables: { id },
  })
  let { property = {} } = results.data || {}
  if (!property) property = {}
  const propertyLoading = results.loading
  const propertyError = results.error

  const {
    acquisition = {},
    assumptions = {},
    assetManagement = {},
    location = { latitude: 0, longitude: 0 },
  } = property
  const { analysis = {}, analytics = DEFAULT_ANALYTICS } = acquisition

  results = useQuery(OFFER, {
    variables: { propertyId: id },
  })
  const { offer = {} } = results.data || {}
  const offerLoading = results.loading
  const offerError = results.error

  const [propertyInput, setPropertyInput] = useState()

  // const [source, setSource] = useState(null)

  const [propertyLoader, setPropertyLoader] = useState(false)

  const [openAmortization, setOpenAmortization] = useState(false)
  const handleOpenAmortization = () => {
    setOpenAmortization(true)
  }
  const handleCloseAmortization = () => {
    setOpenAmortization(false)
  }

  // const [openBudgetCard, setOpenBudgetCard] = React.useState(false);
  // const handleEditBudgetClick = () => {
  //   setOpenBudgetCard(!openBudgetCard);
  // };

  const [canDrag, setCanDrag] = useState(false)

  // const [openUpdateMarkets, setOpenUpdateMarkets] = useState(false)

  // const handleUpdateMarket = () => {
  //   if (!isEmpty(property.marketplaces)) setOpenUpdateMarkets(true)
  // }

  useEffect(() => {
    if (isEmpty(propertyInput) && !propertyLoading) {
      // let today = new Date()
      // let daysOnMarketCheck = moment
      //   .duration(moment(today).diff(analysis.addedToMarket))
      //   .asDays()
      // daysOnMarketCheck = Math.abs(daysOnMarketCheck)
      // daysOnMarketCheck = Math.round(daysOnMarketCheck)
      setPropertyInput({
        address: property.address,
        city: property.city,
        county: property.county,
        state: property.state,
        zip: property.zip,
        beds: !isNil(property.beds) ? [...property.beds] : [0],
        baths: !isNil(property.baths) ? [...property.baths] : [0],
        sqft: !isNil(property.sqft) ? [...property.sqft] : [0],
        year: property.year,
        description: property.description,
        notes: property.notes,
        water: property.water,
        sewer: property.sewer,
        exchange: property.exchange || [],
        market: property.market,
        type: property.type,
        plan: property.plan,
        zone: property.zone,
        parcelID: property.parcelID,
        lotSize: property.lotSize,
        basementSqft: property.basementSqft,
        remodelYear: property.remodelYear,
        parking: property.parking,
        ac: property.ac,
        homeType: property.homeType,
        floodZone: property.floodZone,
        subdivision: property.subdivision,
        propertyType: property.propertyType,
        owned: property.owned ? new Date(property.owned) : null,
        lender: property.lender,
        analysisInput: {
          listPrice: analysis.listPrice,
          daysOnMarket: analysis.daysOnMarket,
          neighborhood: analysis.neighborhood,
        },
        southernImpressions: property.southernImpressions,
        otherFiles: property.otherFiles || [],
        applyForLending: { ...property.applyForLending },
        jvFile: property.jvFile,
        //  source: {
        //    ...property.source
        //  },
        MLSID: property.MLSID || '',
      })
      const { checklists = {} } = property
    }
  }, [property, analysis, propertyInput, propertyLoading])

  const onChangeExchange = (event, name, quick = false) => {
    let newPropertyInput = { ...propertyInput }
    let exchange = [...newPropertyInput.exchange]
    if (includes(exchange, name)) pull(exchange, name)
    else exchange.push(name)
    newPropertyInput.exchange = exchange
    setPropertyInput(newPropertyInput)
    // if (quick) {
    //   onSubmitProperty(newPropertyInput)
    //   debouncedSubmitProperty.cancel()
    // }
  }
  const onChangeProperty = (event, source = null, quick = false) => {
    const { name = '', value = '', type = '' } = event.target
    let newPropertyInput = cloneDeep(propertyInput)
    let newValue = value
    // let newSource = null
    if (type === 'checkbox' && name !== 'owned')
      newValue = !get(newPropertyInput, name)
    if (type === 'number' && name !== 'zip') newValue = Number(value)
    if ((type === 'number' || type === 'dollar') && newValue < 0)
      newValue = 0
    if (name === 'owned')
      newValue = get(newPropertyInput, name) ? null : new Date()
    if (name === 'propertyType') {
      setPropertyLoader(true)
      let newAnalysisInput = cloneDeep(analysisInput)
      let newAssumptionsInput = cloneDeep(assumptionsInput)
      unset(newAssumptionsInput, '_id')
      unset(newAssumptionsInput, '__v')
      unset(newAssumptionsInput, '__typename')
      unset(newAssumptionsInput, 'documentId')
      unset(newAssumptionsInput, 'documentType')
      unset(newAssumptionsInput, 'updatedAt')
      unset(newAssumptionsInput, 'updatedBy')
      // newSource = cloneDeep(property.source)
      // if (!isArray(newSource.rent))
      //  newSource.rent = [newSource.rent]
      let length = 1
      if (includes(value, '2')) length = 2
      else if (includes(value, '3')) length = 3
      else if (includes(value, '4')) length = 4
      newPropertyInput.beds = slice(newPropertyInput.beds, 0, length)
      newPropertyInput.baths = slice(
        newPropertyInput.baths,
        0,
        length,
      )
      newPropertyInput.sqft = slice(newPropertyInput.sqft, 0, length)
      // newSource.rent = slice(newSource.rent, 0, length)
      while (newPropertyInput.beds.length < length) {
        newPropertyInput.beds.push(head(newPropertyInput.beds))
        newPropertyInput.baths.push(head(newPropertyInput.baths))
        newPropertyInput.sqft.push(head(newPropertyInput.sqft))
        //  newSource.rent.push(head(newSource.rent))
      }
      let newRentArrays = reduce(
        newAnalysisInput,
        (result, value, key) => {
          if (
            startsWith(key, 'rent') ||
            startsWith(key, 'otherIncome')
          ) {
            let newIncome = isNil(value) ? [0] : value
            if (newIncome.length > length) {
              newIncome = slice(newIncome, 0, length)
            }
            while (newIncome.length < length)
              newIncome.push(head(value))
            result[key] = newIncome
          }
          return result
        },
        {},
      )
      let newOwnerOccupied = reduce(
        newAnalysisInput,
        (result, value, key) => {
          if (startsWith(key, 'ownerOccupied')) {
            if (value >= length) result[key] = -1
          }
          return result
        },
        {},
      )
      let newAvgRate = reduce(
        newAssumptionsInput,
        (result, value, key) => {
          if (startsWith(key, 'avgRate')) {
            let newIncome = isNil(value) ? [0] : value
            if (newIncome.length > length) {
              newIncome = slice(newIncome, 0, length)
            }
            while (newIncome.length < length)
              newIncome.push(head(value))
            result[key] = newIncome
          }
          return result
        },
        {},
      )
      let newVacancys = reduce(
        newAssumptionsInput,
        (result, value, key) => {
          if (
            startsWith(key, 'vacancy') ||
            startsWith(key, 'occupancy')
          ) {
            let newIncome = isNil(value) ? [0] : value
            if (newIncome.length > length) {
              newIncome = slice(newIncome, 0, length)
            }
            while (newIncome.length < length)
              newIncome.push(head(value))
            result[key] = newIncome
          }
          return result
        },
        {},
      )
      newAnalysisInput = {
        ...newAnalysisInput,
        ...newRentArrays,
        ...newOwnerOccupied,
      }
      newAssumptionsInput = {
        ...newAssumptionsInput,
        ...newAvgRate,
        ...newVacancys,
      }
      newPropertyInput.analysisInput = newAnalysisInput
      setAnalysisInput(newAnalysisInput)
      setAssumptionsInput(newAssumptionsInput)
    }
    set(newPropertyInput, name, newValue)
    // if (source) {
    //  newSource = cloneDeep(property.source)
    //  set(newSource, last(split(name, '.')), source)
    // }
    // setSource(newSource)
    setPropertyInput(newPropertyInput)
    // if (quick) {
    //  onSubmitProperty(newPropertyInput, newSource)
    //  debouncedSubmitProperty.cancel()
    // }
  }

  const uploadFileProperty = (target) => (file) => {
    let newPropertyInput = cloneDeep(propertyInput)
    set(newPropertyInput, target, file)
    setPropertyInput(newPropertyInput)
  }

  const debouncedSubmitProperty = useRef().current
  //  debounce((propertyInput, source) => onSubmitProperty(propertyInput, source), 1000)

  useEffect(() => {
    const basePropertyInput = {
      address: property.address,
      city: property.city,
      county: property.county,
      state: property.state,
      zip: property.zip,
      beds: !isNil(property.beds) ? [...property.beds] : [0],
      baths: !isNil(property.baths) ? [...property.baths] : [0],
      sqft: !isNil(property.sqft) ? [...property.sqft] : [0],
      year: property.year,
      description: property.description,
      notes: property.notes,
      water: property.water,
      sewer: property.sewer,
      exchange: property.exchange || [],
      market: property.market,
      type: property.type,
      plan: property.plan,
      zone: property.zone,
      parcelID: property.parcelID,
      lotSize: property.lotSize,
      basementSqft: property.basementSqft,
      remodelYear: property.remodelYear,
      parking: property.parking,
      ac: property.ac,
      homeType: property.homeType,
      floodZone: property.floodZone,
      subdivision: property.subdivision,
      propertyType: property.propertyType,
      owned: property.owned ? new Date(property.owned) : null,
      lender: property.lender,
      analysisInput: {
        listPrice: analysis.listPrice,
        daysOnMarket: analysis.daysOnMarket,
        neighborhood: analysis.neighborhood,
      },
      southernImpressions: property.southernImpressions,
      otherFiles: property.otherFiles || [],
      applyForLending: { ...property.applyForLending },
      jvFile: property.jvFile,
      //  source: {
      //    ...property.source
      //  },
      MLSID: property.MLSID || '',
    }
    if (
      isEmpty(propertyInput) ||
      propertyLoading ||
      isEqual(propertyInput, basePropertyInput)
    )
      return
    debouncedSubmitProperty(propertyInput /*source*/)
    return () => debouncedSubmitProperty.cancel()
  }, [
    propertyInput,
    propertyLoading,
    debouncedSubmitProperty /*source*/,
  ])

  const [analysisInput, setAnalysisInput] = useState()

  const today = new Date()
  let daysOnMarketCheck = moment
    .duration(moment(today).diff(analysis.addedToMarket))
    .asDays()
  daysOnMarketCheck = Math.abs(daysOnMarketCheck)
  daysOnMarketCheck = Math.round(daysOnMarketCheck)

  useEffect(() => {
    if (isEmpty(analysisInput) && !propertyLoading) {
      let length = property.beds ? property.beds.length : 1
      let newRentArrays = reduce(
        analysis,
        (result, value, key) => {
          if (
            startsWith(key, 'rent') ||
            startsWith(key, 'otherIncome')
          ) {
            let newIncome = isNil(value) ? [0] : value
            if (newIncome.length > length) {
              newIncome = slice(newIncome, 0, length)
            }
            while (newIncome.length < length)
              newIncome.push(head(value))
            result[key] = newIncome
          }
          return result
        },
        {},
      )
      let newOwnerOccupied = reduce(
        analysis,
        (result, value, key) => {
          if (startsWith(key, 'ownerOccupied')) {
            if (value >= length) result[key] = -1
          }
          return result
        },
        {},
      )
      setAnalysisInput({
        listPrice:
          analysis.listPrice == null ? null : analysis.listPrice,
        afterRepairValue:
          analysis.afterRepairValue == null
            ? null
            : analysis.afterRepairValue,
        afterRepairValueBuyHold:
          analysis.afterRepairValueBuyHold == null
            ? null
            : analysis.afterRepairValueBuyHold,
        afterRepairValueFixFlip:
          analysis.afterRepairValueFixFlip == null
            ? null
            : analysis.afterRepairValueFixFlip,
        afterRepairValueTurnkey:
          analysis.afterRepairValueTurnkey == null
            ? null
            : analysis.afterRepairValueTurnkey,
        afterRepairValueSTR:
          analysis.afterRepairValueSTR == null
            ? null
            : analysis.afterRepairValueSTR,
        afterRepairValueBRRRR:
          analysis.afterRepairValueBRRRR == null
            ? null
            : analysis.afterRepairValueBRRRR,
        afterRepairValueB2R:
          analysis.afterRepairValueB2R == null
            ? null
            : analysis.afterRepairValueB2R,
        afterRepairValueBOR:
          analysis.afterRepairValueBOR == null
            ? null
            : analysis.afterRepairValueBOR,
        afterRepairValueWholesale:
          analysis.afterRepairValueWholesale == null
            ? null
            : analysis.afterRepairValueWholesale,
        rehabCost:
          analysis.rehabCost == null ? null : analysis.rehabCost,
        rehabCostBRRRR:
          analysis.rehabCostBRRRR == null
            ? null
            : analysis.rehabCostBRRRR,
        rehabCostBOR:
          analysis.rehabCostBOR == null
            ? null
            : analysis.rehabCostBOR,
        rehabCostFixFlip:
          analysis.rehabCostFixFlip == null
            ? null
            : analysis.rehabCostFixFlip,
        rehabCostTurnkey:
          analysis.rehabCostTurnkey == null
            ? null
            : analysis.rehabCostTurnkey,
        rehabCostSTR:
          analysis.rehabCostSTR == null
            ? null
            : analysis.rehabCostSTR,
        detailedRehabCost:
          analysis.detailedRehabCost == null
            ? []
            : analysis.detailedRehabCost,
        detailedRehabCostBRRRR:
          analysis.detailedRehabCostBRRRR == null
            ? []
            : analysis.detailedRehabCostBRRRR,
        detailedRehabCostBOR:
          analysis.detailedRehabCostBOR == null
            ? []
            : analysis.detailedRehabCostBOR,
        detailedRehabCostFixFlip:
          analysis.detailedRehabCostFixFlip == null
            ? []
            : analysis.detailedRehabCostFixFlip,
        detailedRehabCostTurnkey:
          analysis.detailedRehabCostTurnkey == null
            ? []
            : analysis.detailedRehabCostTurnkey,
        detailedRehabCostSTR:
          analysis.detailedRehabCostSTR == null
            ? []
            : analysis.detailedRehabCostSTR,
        rehabDays:
          analysis.rehabDays == null
            ? analysis.rehabCost === null
              ? null
              : analysis.rehabCost / 500
            : analysis.rehabDays,
        HOA: analysis.HOA == null ? null : analysis.HOA,
        HOAType: analysis.HOAType || 'Yearly',
        HOAB2R: analysis.HOAB2R == null ? null : analysis.HOAB2R,
        HOAB2RType: analysis.HOAB2RType || 'Yearly',
        HOABRRRR:
          analysis.HOABRRRR == null ? null : analysis.HOABRRRR,
        HOABRRRRType: analysis.HOABRRRRType || 'Yearly',
        HOAFixFlip:
          analysis.HOAFixFlip == null ? null : analysis.HOAFixFlip,
        HOAFixFlipType: analysis.HOAFixFlipType || 'Yearly',
        HOATurnkey:
          analysis.HOATurnkey == null ? null : analysis.HOATurnkey,
        HOATurnkeyType: analysis.HOATurnkeyType || 'Yearly',
        HOASTR: analysis.HOASTR == null ? null : analysis.HOASTR,
        HOASTRType: analysis.HOASTRType || 'Yearly',
        //  financingRepairs: analysis.financingRepairs || false,
        //  financingRepairsBRRRR: analysis.financingRepairsBRRRR || false,
        //  financingRepairsB2R: analysis.financingRepairsB2R || false,
        //  financingRepairsFixFlip: analysis.financingRepairsFixFlip || false,
        //  financingRepairsTurnkey: analysis.financingRepairsTurnkey || false,
        //  financingRepairsSTR: analysis.financingRepairsSTR || false,
        financingRepairsPercentage:
          analysis.financingRepairsPercentage || 0,
        financingRepairsPercentageBRRRR:
          analysis.financingRepairsPercentageBRRRR || 0,
        financingRepairsPercentageB2R:
          analysis.financingRepairsPercentageB2R || 0,
        financingRepairsPercentageFixFlip:
          analysis.financingRepairsPercentageFixFlip || 0,
        financingRepairsPercentageTurnkey:
          analysis.financingRepairsPercentageTurnkey || 0,
        financingRepairsPercentageSTR:
          analysis.financingRepairsPercentageSTR || 0,
        resellPrice:
          analysis.resellPrice == null ? null : analysis.resellPrice,
        resellPriceB2R:
          analysis.resellPriceB2R == null
            ? null
            : analysis.resellPriceB2R,
        resellPriceFixFlip:
          analysis.resellPriceFixFlip == null
            ? null
            : analysis.resellPriceFixFlip,
        resellPriceTurnkey:
          analysis.resellPriceTurnkey == null
            ? null
            : analysis.resellPriceTurnkey,
        resellPriceSTR:
          analysis.resellPriceSTR == null
            ? null
            : analysis.resellPriceSTR,
        resellPriceBRRRR:
          analysis.resellPriceBRRRR == null
            ? null
            : analysis.resellPriceBRRRR,
        resellPriceWholesale:
          analysis.resellPriceWholesale == null
            ? null
            : analysis.resellPriceWholesale,
        daysOnMarket:
          daysOnMarketCheck !== analysis.daysOnMarket &&
          analysis.addedToMarket
            ? daysOnMarketCheck
            : analysis.daysOnMarket == null
              ? null
              : analysis.daysOnMarket,
        addedToMarket:
          analysis.addedToMarket == null
            ? null
            : analysis.addedToMarket,
        ...newRentArrays,
        ...newOwnerOccupied,
        // ownerOccupied: analysis.ownerOccupied == null ? -1 : analysis.ownerOccupied,
        // ownerOccupiedB2R: analysis.ownerOccupiedB2R == null ? -1 : analysis.ownerOccupiedB2R,
        // ownerOccupiedBRRRR: analysis.ownerOccupiedBRRRR == null ? -1 : analysis.ownerOccupiedBRRRR,
        // ownerOccupiedFixFlip: analysis.ownerOccupiedFixFlip == null ? -1 : analysis.ownerOccupiedFixFlip,
        // ownerOccupiedTurnkey: analysis.ownerOccupiedTurnkey == null ? -1 : analysis.ownerOccupiedTurnkey,
        propertyTaxes:
          analysis.propertyTaxes == null
            ? null
            : analysis.propertyTaxes,
        offerPrice:
          analysis.offerPrice == null ? null : analysis.offerPrice,
        turnkeyOfferPrice: isNil(analysis.turnkeyOfferPrice)
          ? null
          : analysis.turnkeyOfferPrice,
        b2rLotOfferPrice: isNil(analysis.b2rLotOfferPrice)
          ? null
          : analysis.b2rLotOfferPrice,
        b2rConstructionOfferPrice: isNil(
          analysis.b2rConstructionOfferPrice,
        )
          ? null
          : analysis.b2rConstructionOfferPrice,
        b2rMezzanineOfferPrice: isNil(analysis.b2rMezzanineOfferPrice)
          ? [0]
          : analysis.b2rMezzanineOfferPrice,
        hvacAge: analysis.hvacAge,
        furnaceAge: analysis.furnaceAge,
        condensingUnitAge: analysis.condensingUnitAge,
        waterHeaterAge: analysis.waterHeaterAge,
        roofAge: analysis.roofAge,
        area: analysis.area || '',
        house: analysis.house || '',
        neighborhood: analysis.neighborhood || '',
        startingOffer: analysis.startingOffer,
        note: analysis.note,
        maxOffer: analysis.maxOffer,
        userCashOut: analysis.userCashOut,
        lender: analysis.lender,
        lotLender: analysis.lotLender,
        constructionLender: analysis.constructionLender,
        mezzanineLender: analysis.mezzanineLender,
      })
    }
  }, [analysis, analysisInput, propertyLoading])

  const onChangeAnalysis = (event, source = null, quick = false) => {
    let { name = '', value = '', type = '' } = event.target
    let newValue = value
    if (type === 'number') newValue = Number(value)
    let newAnalysisInput = cloneDeep(analysisInput)
    if (includes(name, 'financingRepairs'))
      newValue = !newAnalysisInput[name]
    if (
      name === 'userCashOut' &&
      (newValue < 0 ||
        newValue >=
          analytics.BRRRR.maxRefinanceLoanAmount -
            analytics.BRRRR.bridgeLoanAmount)
    )
      newValue = 0
    if (name === 'ownerOccupied') {
      newAnalysisInput.ownerOccupied =
        newAnalysisInput.ownerOccupied !== activeUnit
          ? activeUnit
          : -1
      name = `rent.${activeUnit}`
      newValue = 0
    }
    set(newAnalysisInput, name, newValue)
    //  if (name === 'afterRepairValue')
    //    newAnalysisInput['sellPrice'] = newValue
    if (name === 'daysOnMarket') {
      var addedToMarket = new Date()
      addedToMarket.setDate(addedToMarket.getDate() - value)
      newAnalysisInput.addedToMarket = addedToMarket
    }
    setAnalysisInput(newAnalysisInput)
    //  let newSource = null
    //  if (source && !isEqual(source, get(property, `source.${name}`))) {
    //    newSource = cloneDeep(property.source)
    //    if (!isArray(newSource.rent))
    //      newSource.rent = [newSource.rent]
    //    set(newSource, name, source)
    //    setSource(newSource)
    //  }
    if (quick) {
      submitAnalysis(newAnalysisInput /*newSource*/)
      debouncedSubmitAnalysis.cancel()
    }
  }

  const handleAnalysisAdornmentChange = (e, name, value) => {
    e.preventDefault()
    let newAnalysisInput = { ...analysisInput }
    newAnalysisInput[name] = value
    setAnalysisInput(newAnalysisInput)
    handleAssumptionsMenuClose()

    submitAnalysis(newAnalysisInput)
    debouncedSubmitAnalysis.cancel()
  }

  const submitAnalysis = (analysisInput, source = null) => {
    //  saveAnalysis({
    //    awaitRefetchQueries: true,
    //    variables: {
    //      propertyId: id,
    //      analysisInput: analysisInput,
    //    },
    //    refetchQueries: [
    //      {
    //        query: GET_PROPERTY,
    //        variables: { id: id }
    //      }
    //    ]
    //  }).then(data => {
    //    handleUpdateMarket()
    //    if (source) {
    //      updateProperty({
    //        variables: {
    //          id: id,
    //          propertyInput: {
    //            source
    //          },
    //        },
    //        refetchQueries: [
    //          {
    //            query: GET_PROPERTY,
    //            variables: { id: id }
    //          }
    //        ]
    //      })
    //    }
    //  })
  }

  const debouncedSubmitAnalysis = useRef(
    debounce(
      (analysisInput, source) =>
        submitAnalysis(analysisInput, source),
      1000,
    ),
  ).current

  useEffect(() => {
    const baseAnalysisInput = {
      listPrice:
        analysis.listPrice == null ? null : analysis.listPrice,
      afterRepairValue:
        analysis.afterRepairValue == null
          ? null
          : analysis.afterRepairValue,
      afterRepairValueBuyHold:
        analysis.afterRepairValueBuyHold == null
          ? null
          : analysis.afterRepairValueBuyHold,
      afterRepairValueFixFlip:
        analysis.afterRepairValueFixFlip == null
          ? null
          : analysis.afterRepairValueFixFlip,
      afterRepairValueTurnkey:
        analysis.afterRepairValueTurnkey == null
          ? null
          : analysis.afterRepairValueTurnkey,
      afterRepairValueSTR:
        analysis.afterRepairValueSTR == null
          ? null
          : analysis.afterRepairValueSTR,
      afterRepairValueBRRRR:
        analysis.afterRepairValueBRRRR == null
          ? null
          : analysis.afterRepairValueBRRRR,
      afterRepairValueB2R:
        analysis.afterRepairValueB2R == null
          ? null
          : analysis.afterRepairValueB2R,
      afterRepairValueBOR:
        analysis.afterRepairValueBOR == null
          ? null
          : analysis.afterRepairValueBOR,
      afterRepairValueWholesale:
        analysis.afterRepairValueWholesale == null
          ? null
          : analysis.afterRepairValueWholesale,
      rehabCost:
        analysis.rehabCost == null ? null : analysis.rehabCost,
      rehabCostBRRRR:
        analysis.rehabCostBRRRR == null
          ? null
          : analysis.rehabCostBRRRR,
      rehabCostBOR:
        analysis.rehabCostBOR == null ? null : analysis.rehabCostBOR,
      rehabCostFixFlip:
        analysis.rehabCostFixFlip == null
          ? null
          : analysis.rehabCostFixFlip,
      rehabCostTurnkey:
        analysis.rehabCostTurnkey == null
          ? null
          : analysis.rehabCostTurnkey,
      rehabCostSTR:
        analysis.rehabCostSTR == null ? null : analysis.rehabCostSTR,
      detailedRehabCost:
        analysis.detailedRehabCost == null
          ? []
          : analysis.detailedRehabCost,
      detailedRehabCostBRRRR:
        analysis.detailedRehabCostBRRRR == null
          ? []
          : analysis.detailedRehabCostBRRRR,
      detailedRehabCostBOR:
        analysis.detailedRehabCostBOR == null
          ? []
          : analysis.detailedRehabCostBOR,
      detailedRehabCostFixFlip:
        analysis.detailedRehabCostFixFlip == null
          ? []
          : analysis.detailedRehabCostFixFlip,
      detailedRehabCostTurnkey:
        analysis.detailedRehabCostTurnkey == null
          ? []
          : analysis.detailedRehabCostTurnkey,
      detailedRehabCostSTR:
        analysis.detailedRehabCostSTR == null
          ? []
          : analysis.detailedRehabCostSTR,
      rehabDays:
        analysis.rehabDays == null
          ? analysis.rehabCost === null
            ? null
            : analysis.rehabCost / 500
          : analysis.rehabDays,
      HOA: analysis.HOA == null ? null : analysis.HOA,
      HOAType: analysis.HOAType || 'Yearly',
      HOAB2R: analysis.HOAB2R == null ? null : analysis.HOAB2R,
      HOAB2RType: analysis.HOAB2RType || 'Yearly',
      HOABRRRR: analysis.HOABRRRR == null ? null : analysis.HOABRRRR,
      HOABRRRRType: analysis.HOABRRRRType || 'Yearly',
      HOAFixFlip:
        analysis.HOAFixFlip == null ? null : analysis.HOAFixFlip,
      HOAFixFlipType: analysis.HOAFixFlipType || 'Yearly',
      HOATurnkey:
        analysis.HOATurnkey == null ? null : analysis.HOATurnkey,
      HOATurnkeyType: analysis.HOATurnkeyType || 'Yearly',
      HOASTR: analysis.HOASTR == null ? null : analysis.HOASTR,
      HOASTRType: analysis.HOASTRType || 'Yearly',
      //  financingRepairs: analysis.financingRepairs || false,
      //  financingRepairsBRRRR: analysis.financingRepairsBRRRR || false,
      //  financingRepairsB2R: analysis.financingRepairsB2R || false,
      //  financingRepairsFixFlip: analysis.financingRepairsFixFlip || false,
      //  financingRepairsTurnkey: analysis.financingRepairsTurnkey || false,
      //  financingRepairsSTR: analysis.financingRepairsSTR || false,
      financingRepairsPercentage:
        analysis.financingRepairsPercentage || 0,
      financingRepairsPercentageBRRRR:
        analysis.financingRepairsPercentageBRRRR || 0,
      financingRepairsPercentageB2R:
        analysis.financingRepairsPercentageB2R || 0,
      financingRepairsPercentageFixFlip:
        analysis.financingRepairsPercentageFixFlip || 0,
      financingRepairsPercentageTurnkey:
        analysis.financingRepairsPercentageTurnkey || 0,
      financingRepairsPercentageSTR:
        analysis.financingRepairsPercentageSTR || 0,
      resellPrice:
        analysis.resellPrice == null ? null : analysis.resellPrice,
      resellPriceB2R:
        analysis.resellPriceB2R == null
          ? null
          : analysis.resellPriceB2R,
      resellPriceFixFlip:
        analysis.resellPriceFixFlip == null
          ? null
          : analysis.resellPriceFixFlip,
      resellPriceTurnkey:
        analysis.resellPriceTurnkey == null
          ? null
          : analysis.resellPriceTurnkey,
      resellPriceBRRRR:
        analysis.resellPriceBRRRR == null
          ? null
          : analysis.resellPriceBRRRR,
      resellPriceSTR:
        analysis.resellPriceSTR == null
          ? null
          : analysis.resellPriceSTR,
      resellPriceWholesale:
        analysis.resellPriceWholesale == null
          ? null
          : analysis.resellPriceWholesale,
      daysOnMarket:
        daysOnMarketCheck !== analysis.daysOnMarket &&
        analysis.addedToMarket
          ? daysOnMarketCheck
          : analysis.daysOnMarket == null
            ? null
            : analysis.daysOnMarket,
      addedToMarket:
        analysis.addedToMarket == null
          ? null
          : analysis.addedToMarket,
      rent: isNil(analysis.rent) ? null : analysis.rent,
      rentB2R: isNil(analysis.rentB2R) ? null : analysis.rentB2R,
      rentBRRRR: isNil(analysis.rentBRRRR)
        ? null
        : analysis.rentBRRRR,
      rentBOR: isNil(analysis.rentBOR) ? null : analysis.rentBOR,
      rent: isNil(analysis.rentFixFlip) ? null : analysis.rentFixFlip,
      rent: isNil(analysis.rentTurnkey) ? null : analysis.rentTurnkey,
      rentLow: analysis.rentLow == null ? [0] : analysis.rentLow,
      rentLowB2R:
        analysis.rentLowB2R == null ? [0] : analysis.rentLowB2R,
      rentLowBRRRR:
        analysis.rentLowBRRRR == null ? [0] : analysis.rentLowBRRRR,
      rentLowBOR:
        analysis.rentLowBOR == null ? [0] : analysis.rentLowBOR,
      rentLowFixFlip:
        analysis.rentLowFixFlip == null
          ? [0]
          : analysis.rentLowFixFlip,
      rentLowTurnkey:
        analysis.rentLowTurnkey == null
          ? [0]
          : analysis.rentLowTurnkey,
      rentHigh: analysis.rentHigh == null ? [0] : analysis.rentHigh,
      rentHighB2R:
        analysis.rentHighB2R == null ? [0] : analysis.rentHighB2R,
      rentHighBRRRR:
        analysis.rentHighBRRRR == null ? [0] : analysis.rentHighBRRRR,
      rentHighBOR:
        analysis.rentHighBOR == null ? [0] : analysis.rentHighBOR,
      rentHighFixFlip:
        analysis.rentHighFixFlip == null
          ? [0]
          : analysis.rentHighFixFlip,
      rentHighTurnkey:
        analysis.rentHighTurnkey == null
          ? [0]
          : analysis.rentHighTurnkey,
      otherIncome: isNil(analysis.otherIncome)
        ? null
        : analysis.otherIncome,
      otherIncomeB2R: isNil(analysis.otherIncomeB2R)
        ? null
        : analysis.otherIncomeB2R,
      otherIncomeBRRRR: isNil(analysis.otherIncomeBRRRR)
        ? null
        : analysis.otherIncomeBRRRR,
      otherIncomeFixFlip: isNil(analysis.otherIncomeFixFlip)
        ? null
        : analysis.otherIncomeFixFlip,
      otherIncomeTurnkey: isNil(analysis.otherIncomeTurnkey)
        ? null
        : analysis.otherIncomeTurnkey,
      otherIncomeSTR: isNil(analysis.otherIncomeSTR)
        ? null
        : analysis.otherIncomeSTR,
      ownerOccupied:
        analysis.ownerOccupied == null ? -1 : analysis.ownerOccupied,
      ownerOccupiedB2R:
        analysis.ownerOccupiedB2R == null
          ? -1
          : analysis.ownerOccupiedB2R,
      ownerOccupiedBRRRR:
        analysis.ownerOccupiedBRRRR == null
          ? -1
          : analysis.ownerOccupiedBRRRR,
      ownerOccupiedFixFlip:
        analysis.ownerOccupiedFixFlip == null
          ? -1
          : analysis.ownerOccupiedFixFlip,
      ownerOccupiedTurnkey:
        analysis.ownerOccupiedTurnkey == null
          ? -1
          : analysis.ownerOccupiedTurnkey,
      propertyTaxes:
        analysis.propertyTaxes == null
          ? null
          : analysis.propertyTaxes,
      offerPrice:
        analysis.offerPrice == null ? null : analysis.offerPrice,
      turnkeyOfferPrice: isNil(analysis.turnkeyOfferPrice)
        ? null
        : analysis.turnkeyOfferPrice,
      b2rLotOfferPrice: isNil(analysis.b2rLotOfferPrice)
        ? null
        : analysis.b2rLotOfferPrice,
      b2rConstructionOfferPrice: isNil(
        analysis.b2rConstructionOfferPrice,
      )
        ? null
        : analysis.b2rConstructionOfferPrice,
      b2rMezzanineOfferPrice: isNil(analysis.b2rMezzanineOfferPrice)
        ? [0]
        : analysis.b2rMezzanineOfferPrice,
      hvacAge: analysis.hvacAge,
      furnaceAge: analysis.furnaceAge,
      condensingUnitAge: analysis.condensingUnitAge,
      waterHeaterAge: analysis.waterHeaterAge,
      roofAge: analysis.roofAge,
      area: analysis.area || '',
      house: analysis.house || '',
      neighborhood: analysis.neighborhood || '',
      startingOffer: analysis.startingOffer,
      note: analysis.note,
      maxOffer: analysis.maxOffer,
      userCashOut: analysis.userCashOut,
      lender: analysis.lender,
      lotLender: analysis.lotLender,
      constructionLender: analysis.constructionLender,
      mezzanineLender: analysis.mezzanineLender,
    }
    if (
      isEmpty(analysisInput) ||
      propertyLoading ||
      isEqual(analysisInput, baseAnalysisInput)
    )
      return
    debouncedSubmitAnalysis(analysisInput /*source*/)
    return () => debouncedSubmitAnalysis.cancel()
  }, [analysisInput, propertyLoading, debouncedSubmitAnalysis])

  const [offerToCloseInput, setOfferToCloseInput] = useState({})
  const [openPreview, setOpenPreview] = useState(false)

  const baseOfferToCloseInput = {
    buyer: {
      name: get(offer, 'buyer.name'),
      address: get(offer, 'buyer.address'),
      city: get(offer, 'buyer.city'),
      state: get(offer, 'buyer.state'),
      zip: get(offer, 'buyer.zip'),
      note: get(offer, 'buyer.note'),
    },
    seller: {
      name: get(offer, 'seller.name'),
      address: get(offer, 'seller.address'),
      city: get(offer, 'seller.city'),
      state: get(offer, 'seller.state'),
      zip: get(offer, 'seller.zip'),
      note: get(offer, 'seller.note'),
    },
    contract: {
      dateContractReceived: get(
        offer,
        'contract.dateContractReceived',
      ),
      offerAmount: get(offer, 'contract.offerAmount'),
      bindingDate: get(offer, 'contract.bindingDate'),
      bindingAmount: get(offer, 'contract.bindingAmount'),
      contractCloseDate: get(offer, 'contract.contractCloseDate'),
      files: get(offer, 'contract.files'),
    },
    earnestMoney: {
      earnestMoneyDueDate: get(
        offer,
        'earnestMoney.earnestMoneyDueDate',
      ),
      earnestMoneyAmount: get(
        offer,
        'earnestMoney.earnestMoneyAmount',
      ),
      holder: get(offer, 'earnestMoney.holder'),
      earnestMoneyReceived: get(
        offer,
        'earnestMoney.earnestMoneyReceived',
      ),
      files: get(offer, 'earnestMoney.files'),
    },
    homeInspection: {
      dateReadyForInspection: get(
        offer,
        'homeInspection.dateReadyForInspection',
      ),
      dateInspectionOrdered: get(
        offer,
        'homeInspection.dateInspectionOrdered',
      ),
      dateInspectionReceived: get(
        offer,
        'homeInspection.dateInspectionReceived',
      ),
      dateDueDiligence: get(offer, 'homeInspection.dateDueDiligence'),
      files: get(offer, 'homeInspection.files'),
    },
    appraisal: {
      dateAppraisalOrdered: get(
        offer,
        'appraisal.dateAppraisalOrdered',
      ),
      dateAppraisalReceived: get(
        offer,
        'appraisal.dateAppraisalReceived',
      ),
      appraisedValue: get(offer, 'appraisal.appraisedValue'),
      files: get(offer, 'appraisal.files'),
    },
    titleWork: {
      titleCompany: get(offer, 'titleWork.titleCompany'),
      titleCompanyContact: get(
        offer,
        'titleWork.titleCompanyContact',
      ),
      titleCompanyPhone: get(offer, 'titleWork.titleCompanyPhone'),
      titleCompanyEmail: get(offer, 'titleWork.titleCompanyEmail'),
      titleWorkOrdered: get(offer, 'titleWork.titleWorkOrdered'),
      titleWorkCompleted: get(offer, 'titleWork.titleWorkCompleted'),
      borrowerType: get(offer, 'titleWork.borrowerType'),
      entity: get(offer, 'titleWork.entity'),
      applicant: get(offer, 'titleWork.applicant'),
      loanAmount: get(offer, 'titleWork.loanAmount'),
      purchasePrice: get(offer, 'titleWork.purchasePrice'),
      files: get(offer, 'titleWork.files'),
      spruceFiles: get(offer, 'titleWork.spruceFiles'),
    },
    closing: {
      dateFinalWalkThrough: get(
        offer,
        'closing.dateFinalWalkThrough',
      ),
      closingDate: get(offer, 'closing.closingDate'),
      dateClearToFund: get(offer, 'closing.dateClearToFund'),
      attorneyInformation: get(offer, 'closing.attorneyInformation'),
      attorneyPhone: get(offer, 'closing.attorneyPhone'),
      attorneyEmail: get(offer, 'closing.attorneyEmail'),
      files: get(offer, 'closing.files'),
    },
  }

  useEffect(() => {
    if (isEmpty(offerToCloseInput) && !offerLoading) {
      let newOffer = offer
      setOfferToCloseInput({
        buyer: {
          name: get(newOffer, 'buyer.name'),
          address: get(newOffer, 'buyer.address'),
          city: get(newOffer, 'buyer.city'),
          state: get(newOffer, 'buyer.state'),
          zip: get(newOffer, 'buyer.zip'),
          note: get(newOffer, 'buyer.note'),
        },
        seller: {
          name: get(newOffer, 'seller.name'),
          address: get(newOffer, 'seller.address'),
          city: get(newOffer, 'seller.city'),
          state: get(newOffer, 'seller.state'),
          zip: get(newOffer, 'seller.zip'),
          note: get(newOffer, 'seller.note'),
        },
        contract: {
          dateContractReceived: get(
            newOffer,
            'contract.dateContractReceived',
          ),
          offerAmount: get(newOffer, 'contract.offerAmount'),
          bindingDate: get(newOffer, 'contract.bindingDate'),
          bindingAmount: get(newOffer, 'contract.bindingAmount'),
          contractCloseDate: get(
            newOffer,
            'contract.contractCloseDate',
          ),
          files: get(newOffer, 'contract.files'),
        },
        earnestMoney: {
          earnestMoneyDueDate: get(
            newOffer,
            'earnestMoney.earnestMoneyDueDate',
          ),
          earnestMoneyAmount: get(
            newOffer,
            'earnestMoney.earnestMoneyAmount',
          ),
          holder: get(newOffer, 'earnestMoney.holder'),
          earnestMoneyReceived: get(
            newOffer,
            'earnestMoney.earnestMoneyReceived',
          ),
          files: get(newOffer, 'earnestMoney.files'),
        },
        homeInspection: {
          dateReadyForInspection: get(
            newOffer,
            'homeInspection.dateReadyForInspection',
          ),
          dateInspectionOrdered: get(
            newOffer,
            'homeInspection.dateInspectionOrdered',
          ),
          dateInspectionReceived: get(
            newOffer,
            'homeInspection.dateInspectionReceived',
          ),
          dateDueDiligence: get(
            newOffer,
            'homeInspection.dateDueDiligence',
          ),
          files: get(newOffer, 'homeInspection.files'),
        },
        appraisal: {
          dateAppraisalOrdered: get(
            newOffer,
            'appraisal.dateAppraisalOrdered',
          ),
          dateAppraisalReceived: get(
            newOffer,
            'appraisal.dateAppraisalReceived',
          ),
          appraisedValue: get(newOffer, 'appraisal.appraisedValue'),
          files: get(newOffer, 'appraisal.files'),
        },
        titleWork: {
          titleCompany: get(newOffer, 'titleWork.titleCompany'),
          titleCompanyContact: get(
            newOffer,
            'titleWork.titleCompanyContact',
          ),
          titleCompanyPhone: get(
            newOffer,
            'titleWork.titleCompanyPhone',
          ),
          titleCompanyEmail: get(
            newOffer,
            'titleWork.titleCompanyEmail',
          ),
          titleWorkOrdered: get(
            newOffer,
            'titleWork.titleWorkOrdered',
          ),
          titleWorkCompleted: get(
            newOffer,
            'titleWork.titleWorkCompleted',
          ),
          borrowerType: get(newOffer, 'titleWork.borrowerType'),
          entity: get(newOffer, 'titleWork.entity'),
          applicant: get(newOffer, 'titleWork.applicant'),
          loanAmount: get(newOffer, 'titleWork.loanAmount'),
          purchasePrice: get(newOffer, 'titleWork.purchasePrice'),
          files: get(newOffer, 'titleWork.files'),
          spruceFiles: get(newOffer, 'titleWork.spruceFiles'),
        },
        closing: {
          dateFinalWalkThrough: get(
            newOffer,
            'closing.dateFinalWalkThrough',
          ),
          closingDate: get(newOffer, 'closing.closingDate'),
          dateClearToFund: get(newOffer, 'closing.dateClearToFund'),
          attorneyInformation: get(
            newOffer,
            'closing.attorneyInformation',
          ),
          attorneyPhone: get(newOffer, 'closing.attorneyPhone'),
          attorneyEmail: get(newOffer, 'closing.attorneyEmail'),
          files: get(newOffer, 'closing.files'),
        },
      })
    }
  }, [offer, offerToCloseInput, offerLoading])

  const [assumptionsInput, setAssumptionsInput] = useState()

  let baseAssumptionInput = cloneDeep(assumptions)
  unset(baseAssumptionInput, '_id')
  unset(baseAssumptionInput, '__v')
  unset(baseAssumptionInput, '__typename')
  unset(baseAssumptionInput, 'documentId')
  unset(baseAssumptionInput, 'documentType')
  unset(baseAssumptionInput, 'updatedAt')
  unset(baseAssumptionInput, 'updatedBy')

  useEffect(() => {
    if (isEmpty(assumptionsInput) && !propertyLoading) {
      let newAssumptions = cloneDeep(assumptions)
      unset(newAssumptions, '_id')
      unset(newAssumptions, '__v')
      unset(newAssumptions, '__typename')
      unset(newAssumptions, 'documentId')
      unset(newAssumptions, 'documentType')
      unset(newAssumptions, 'updatedAt')
      unset(newAssumptions, 'updatedBy')
      let length = property.beds ? property.beds.length : 1
      let newAvgRate = reduce(
        newAssumptions,
        (result, value, key) => {
          if (startsWith(key, 'avgRate')) {
            let newIncome = isNil(value) ? [0] : value
            if (newIncome.length > length) {
              newIncome = slice(newIncome, 0, length)
            }
            while (newIncome.length < length)
              newIncome.push(head(value))
            result[key] = newIncome
          }
          return result
        },
        {},
      )
      let newVacancys = reduce(
        newAssumptions,
        (result, value, key) => {
          if (
            startsWith(key, 'vacancy') ||
            startsWith(key, 'occupancy')
          ) {
            let newIncome = isNil(value) ? [0] : value
            if (newIncome.length > length) {
              newIncome = slice(newIncome, 0, length)
            }
            while (newIncome.length < length)
              newIncome.push(head(value))
            result[key] = newIncome
          }
          return result
        },
        {},
      )
      setAssumptionsInput({
        ...newAssumptions,
        ...newAvgRate,
        ...newVacancys,
      })
    }
  }, [assumptions, assumptionsInput, propertyLoading])

  const onChangeAssumptions = (event, quick = false) => {
    const { name = '', value = '', type = '' } = event.target
    let newValue = value
    if (type === 'number') newValue = Number(value)
    let newAssumptionsInput = { ...assumptionsInput }
    if (type === 'checkbox') newValue = !newAssumptionsInput[name]
    if (name === 'allCashPurchase') {
      newAssumptionsInput.allCashPurchase =
        !newAssumptionsInput.allCashPurchase
      newAssumptionsInput['downPayment'] = 100
      newAssumptionsInput['rate'] = 0
      newAssumptionsInput['term'] = 0
      newAssumptionsInput['points'] = 0
      //newAssumptionsInput['payDownAccelerator'] = 0
      newAssumptionsInput['interestOnlyLoan'] = false
    } else if (name === 'allCashPurchaseTurnkey') {
      newAssumptionsInput.allCashPurchaseTurnkey =
        !newAssumptionsInput.allCashPurchaseTurnkey
      newAssumptionsInput['downPaymentTurnkey'] = 100
      newAssumptionsInput['rateTurnkey'] = 0
      newAssumptionsInput['termTurnkey'] = 0
      newAssumptionsInput['pointsTurnkey'] = 0
      //newAssumptionsInput['payDownAcceleratorTurnkey'] = 0
      newAssumptionsInput['interestOnlyLoanTurnkey'] = false
    } else if (name === 'allCashPurchaseFixFlip') {
      newAssumptionsInput.allCashPurchaseFixFlip =
        !newAssumptionsInput.allCashPurchaseFixFlip
      newAssumptionsInput['downPaymentFixFlip'] = 100
      newAssumptionsInput['rateFixFlip'] = 0
      newAssumptionsInput['termFixFlip'] = 0
      newAssumptionsInput['pointsFixFlip'] = 0
      //newAssumptionsInput['payDownAcceleratorFixFlip'] = 0
      newAssumptionsInput['interestOnlyLoanFixFlip'] = false
    } else if (name === 'allCashPurchaseSTR') {
      newAssumptionsInput.allCashPurchaseSTR =
        !newAssumptionsInput.allCashPurchaseSTR
      newAssumptionsInput['downPaymentSTR'] = 100
      newAssumptionsInput['rateSTR'] = 0
      newAssumptionsInput['termSTR'] = 0
      newAssumptionsInput['pointsSTR'] = 0
      //newAssumptionsInput['payDownAcceleratorSTR'] = 0
      newAssumptionsInput['interestOnlyLoanSTR'] = false
    } else if (name === 'bridgeCashPurchase') {
      newAssumptionsInput.bridgeCashPurchase =
        !newAssumptionsInput.bridgeCashPurchase
      newAssumptionsInput['bridgeDownPayment'] = 100
      newAssumptionsInput['bridgeRate'] = 0
      newAssumptionsInput['bridgeTerm'] = 0
      newAssumptionsInput['bridgePoints'] = 0
      //newAssumptionsInput['payDownAccelerator'] = 0
      newAssumptionsInput['bridgeInterestOnlyLoan'] = false
    } else if (name === 'refinanceCashPurchase') {
      newAssumptionsInput.refinanceCashPurchase =
        !newAssumptionsInput.refinanceCashPurchase
      newAssumptionsInput['refinanceDownPayment'] = 100
      newAssumptionsInput['refinanceRate'] = 0
      newAssumptionsInput['refinanceTerm'] = 0
      newAssumptionsInput['refinancePoints'] = 0
      //newAssumptionsInput['payDownAccelerator'] = 0
      newAssumptionsInput['refinanceInterestOnlyLoan'] = false
    } else if (name === 'lotCashPurchase') {
      newAssumptionsInput.lotCashPurchase =
        !newAssumptionsInput.lotCashPurchase
      newAssumptionsInput['lotDownPayment'] = 100
      newAssumptionsInput['lotRate'] = 0
      newAssumptionsInput['lotTerm'] = 0
      newAssumptionsInput['lotPoints'] = 0
      //newAssumptionsInput['payDownAccelerator'] = 0
      newAssumptionsInput['lotInterestOnlyLoan'] = false
    } else if (name === 'constructionCashPurchase') {
      newAssumptionsInput.constructionCashPurchase =
        !newAssumptionsInput.constructionCashPurchase
      newAssumptionsInput['constructionDownPayment'] = 100
      newAssumptionsInput['constructionRate'] = 0
      newAssumptionsInput['constructionTerm'] = 0
      newAssumptionsInput['constructionPoints'] = 0
      //newAssumptionsInput['payDownAccelerator'] = 0
      newAssumptionsInput['constructionInterestOnlyLoan'] = false
    } else if (includes(name, 'mezzanineCashPurchase')) {
      const [front, index] = split(name, '.')
      newAssumptionsInput.mezzanineCashPurchase[index] =
        !newAssumptionsInput.mezzanineCashPurchase[index]
      newAssumptionsInput['mezzanineDownPayment'][index] = 100
      newAssumptionsInput['mezzanineRate'][index] = 0
      newAssumptionsInput['mezzanineTerm'][index] = 0
      newAssumptionsInput['mezzaninePoints'][index] = 0
      //newAssumptionsInput['payDownAccelerator'] = 0
      newAssumptionsInput['mezzanineInterestOnlyLoan'][index] = false
    } else set(newAssumptionsInput, name, newValue)
    setAssumptionsInput(newAssumptionsInput)
    if (quick) {
      submitAssumptions(newAssumptionsInput)
      debouncedSubmitAssumptions.cancel()
    }
  }

  const createNewMezzanine = () => {
    let newAssumptionsInput = { ...assumptionsInput }
    newAssumptionsInput['mezzanineCashPurchase'].push(
      last(newAssumptionsInput['mezzanineCashPurchase']),
    )
    newAssumptionsInput['mezzanineDownPayment'].push(
      last(newAssumptionsInput['mezzanineDownPayment']),
    )
    newAssumptionsInput['mezzanineRate'].push(
      last(newAssumptionsInput['mezzanineRate']),
    )
    newAssumptionsInput['mezzanineTerm'].push(
      last(newAssumptionsInput['mezzanineTerm']),
    )
    newAssumptionsInput['mezzanineTermType'].push(
      last(newAssumptionsInput['mezzanineTermType']),
    )
    newAssumptionsInput['mezzaninePoints'].push(
      last(newAssumptionsInput['mezzaninePoints']),
    )
    //newAssumptionsInput['payDownAccelerator'] = 0
    newAssumptionsInput['mezzanineInterestOnlyLoan'].push(
      last(newAssumptionsInput['mezzanineInterestOnlyLoan']),
    )
    setAssumptionsInput(newAssumptionsInput)
    let newAnalysisInput = { ...analysisInput }
    if (!newAnalysisInput['b2rMezzanineOfferPrice'])
      newAnalysisInput['b2rMezzanineOfferPrice'] = [0]
    if (!newAnalysisInput['mezzanineLender'])
      newAnalysisInput['mezzanineLender'] = ['']
    newAnalysisInput['b2rMezzanineOfferPrice'].push(
      last(newAnalysisInput['b2rMezzanineOfferPrice']),
    )
    newAnalysisInput['mezzanineLender'].push(
      last(newAnalysisInput['mezzanineLender']),
    )
    setAnalysisInput(newAnalysisInput)
  }

  const [anchorElAssumptions, setAnchorElAssumptions] = useState(null)
  const [showFormatDropdown, setShowFormatDropdown] = useState('')

  const toggleAssumptionsMenuFormat = (event, name) => {
    setAnchorElAssumptions(event.target)
    setShowFormatDropdown(name)
  }

  const handleAssumptionsMenuClose = () => {
    setAnchorElAssumptions(null)
  }

  const handleAdornmentChange = (e, name, value) => {
    e.preventDefault()
    let newAssumptions = { ...assumptionsInput }
    set(newAssumptions, name, value)
    setAssumptionsInput(newAssumptions)
    handleAssumptionsMenuClose()

    submitAssumptions(newAssumptions)
    debouncedSubmitAssumptions.cancel()
  }

  // const lenderOverride = (index) => {
  //  let newAssumptionsInput = { ...assumptionsInput }
  //   if (index === 0) {
  //    const lender = find(props?.session?.me?.activeOrg?.lenders, lender => lender.value === analysisInput.lotLender)
  //    newAssumptionsInput['lotDownPayment'] = parseFloat(lender.downPayment)
  //    newAssumptionsInput['lotRate'] = parseFloat(lender.rate)
  //    newAssumptionsInput['lotTerm'] = parseFloat(lender.term)
  //    newAssumptionsInput['lotTermType'] = lender.termType
  //    newAssumptionsInput['lotPoints'] = parseFloat(lender.points)
  //   } else if (index === 1) {
  //    const lender = find(props?.session?.me?.activeOrg?.lenders, lender => lender.value === analysisInput.constructionLender)
  //    newAssumptionsInput['constructionDownPayment'] = parseFloat(lender.downPayment)
  //    newAssumptionsInput['constructionRate'] = parseFloat(lender.rate)
  //    newAssumptionsInput['constructionTerm'] = parseFloat(lender.term)
  //    newAssumptionsInput['constructionTermType'] = lender.termType
  //    newAssumptionsInput['constructionPoints'] = parseFloat(lender.points)
  //   } else {
  //    const mezIndex = index - 2
  //    const lender = find(props?.session?.me?.activeOrg?.lenders, lender => lender.value === get(analysisInput, `mezzanineLender.${mezIndex}`))
  //    newAssumptionsInput['mezzanineDownPayment'][mezIndex] = parseFloat(lender.downPayment)
  //    newAssumptionsInput['mezzanineRate'][mezIndex] = parseFloat(lender.rate)
  //    newAssumptionsInput['mezzanineTerm'][mezIndex] = parseFloat(lender.term)
  //    newAssumptionsInput['mezzanineTermType'][mezIndex] = lender.termType
  //    newAssumptionsInput['mezzaninePoints'][mezIndex] = parseFloat(lender.points)
  //   }
  //   setAssumptionsInput(newAssumptionsInput)
  // }

  const submitAssumptions = (assumptionsInput) => {
    //  saveAssumptions({
    //    awaitRefetchQueries: true,
    //    variables: {
    //      documentId: id,
    //      assumptionsInput: assumptionsInput,
    //      documentType: 'property',
    //    },
    //    refetchQueries: [
    //      {
    //        query: GET_PROPERTY,
    //        variables: { id: id }
    //      }
    //    ]
    //  }).then(data => {
    //    handleUpdateMarket()
    //  })
  }

  const debouncedSubmitAssumptions = useRef(
    debounce(
      (assumptionsInput) => submitAssumptions(assumptionsInput),
      1000,
    ),
  ).current

  useEffect(() => {
    if (
      isEmpty(assumptionsInput) ||
      propertyLoading ||
      isEqual(assumptionsInput, baseAssumptionInput)
    )
      return
    debouncedSubmitAssumptions(assumptionsInput)
    return () => debouncedSubmitAssumptions.cancel()
  }, [assumptionsInput, propertyLoading, debouncedSubmitAssumptions])

  const [anchorElEstimates, setAnchorElEstimates] = useState(null)
  const [menu, setMenu] = useState('')
  const [menuTarget, setMenuTarget] = useState('')
  const estimates = merge(DEFAULT_ESTIMATES, property.estimates)
  const [estimatesCollapse, setEstimatesCollapse] = useState({})
  const [openNoData, setOpenNoData] = useState(false)

  const handleDefinition = (name, anchorEl, target = '') => {
    setMenu(name)
    setMenuTarget(target)
    setOpenNoData(false)
    setAnchorElEstimates(anchorEl)
  }

  const changeMenu = (name, value, source = null) => {
    let newName = name
    let newValue = value
    if (
      includes(name, 'rent') ||
      name == 'avgRate' ||
      name === 'occupancyRate'
    ) {
      if (assumptionsInput[name + 'Type'] === 'rentRange') {
        onChangeRange(
          replace(name, 'rent', 'rentLow') + '.' + activeUnit,
          replace(name, 'rent', 'rentHigh') + '.' + activeUnit,
          Number(newValue),
          Number(newValue) /*source*/,
        )
        setMenu('')
        setMenuTarget('')
        setAnchorElEstimates(null)
        return
      }
      newName = name + '.' + activeUnit
    }
    if (openCurrentEquity)
      onChangeAsset({
        target: {
          name: 'equity.' + newName,
          value: newValue,
          type: 'number',
        },
      })
    else if (activeTab === 'Edit' && name !== 'rent')
      onChangeProperty(
        {
          target: {
            name: 'analysisInput.' + newName,
            value: newValue,
            type: 'number',
          },
        } /*source*/,
      )
    else if (name == 'avgRate' || name === 'occupancyRate')
      onChangeAssumptions(
        {
          target: {
            name: newName,
            value: round(newValue),
            type: 'number',
          },
        } /*source*/,
      )
    else
      onChangeAnalysis(
        {
          target: { name: newName, value: newValue, type: 'number' },
        } /*source*/,
      )
    setMenu('')
    setMenuTarget('')
    setAnchorElEstimates(null)
  }

  const onChangeRange = (
    nameLow,
    nameHigh,
    min,
    max,
    source = null,
  ) => {
    let newAnalysisInput = cloneDeep(analysisInput)
    set(newAnalysisInput, nameLow, min)
    set(newAnalysisInput, nameHigh, max)
    setAnalysisInput(newAnalysisInput)
    //  let newSource = null
    //  if (source && !isEqual(source, get(property, `source.${nameLow}`))) {
    //    newSource = cloneDeep(property.source)
    //    if (!isArray(newSource.rent))
    //      newSource.rent = [newSource.rent]
    //    set(newSource, nameLow, source)
    //    setSource(newSource)
    //  }
    //  if (source && !isEqual(source, get(property, `source.${nameHigh}`))) {
    //    newSource = cloneDeep(property.source)
    //    if (!isArray(newSource.rent))
    //      newSource.rent = [newSource.rent]
    //    set(newSource, analysisInput, source)
    //    setSource(newSource)
    //  }
  }

  const toggleCollapse = (name) => {
    let newEstimatesCollapse = { ...estimatesCollapse }
    newEstimatesCollapse[name] = !estimatesCollapse[name]
    setEstimatesCollapse(newEstimatesCollapse)
  }

  results = useQuery(GET_COMPPROPERTIES, {
    variables: { propertyId: id },
    fetchPolicy: 'cache-and-network',
  })
  let data = results.data
  const compsLoading = results.loading
  const compsError = results.error
  const compsRefetch = results.refetch
  const compSubscribeToMore = results.subscribeToMore
  let { compProperties = [] } = data || {}

  results = useSubscription(COMPS_LOADED, {
    variables: { propertyId: id },
  })
  const { compsLoaded = {} } = results.data || {}

  const [compsBackendLoading, setCompsBackendLoading] =
    useState(false)
  const [backendTriggered, setBackendTriggered] = useState(false)

  useEffect(() => {
    if (!isEmpty(compsLoaded)) {
      setCompsBackendLoading(false)
      setBackendTriggered(true)
      compsRefetch().then(({ data }) => {
        //  if (isEmpty(data.compProperties))
        //   enqueueSnackbar(`No Comps Found`, {
        //     variant: 'error',
        //     autoHideDuration: 3000,
        //   })
        //  else
        setTimeout(
          () =>
            enqueueSnackbar(`Comps Search Complete`, {
              variant: 'success',
              autoHideDuration: 3000,
            }),
          3000,
        )
      })
    }
  }, [compsLoaded])

  useEffect(() => {
    const unsubscribe = compSubscribeToMore({
      document: COMP_ADDED,
      variables: { propertyId: id },
      updateQuery: (
        prev = { compProperties: [] },
        { subscriptionData },
      ) => {
        if (!subscriptionData.data) return prev
        if (!backendTriggered) setCompsBackendLoading(true)
        const newComp = subscriptionData.data.compAdded
        return Object.assign({}, prev, {
          compProperties: uniqBy(
            concat(prev.compProperties, newComp),
            '_id',
          ),
        })
      },
    })
    return () => unsubscribe()
  }, [])

  let rentalComps = filter(compProperties, (comp) => comp.rental)
  compProperties = filter(compProperties, (comp) => !comp.rental)
  const determinedComps = filter(
    compProperties,
    (comp) => !comp.potential,
  )

  const compValues = map(determinedComps, (comp) => {
    return {
      _id: comp._id,
      propertyPrice: Number(
        comp.estval * (1 + comp.priceAdjustment / 100),
      ),
      propertyPerSqft: comp.sqft
        ? Number(comp.estval * (1 + comp.priceAdjustment / 100)) /
            comp.sqft || 0
        : null,
      sqft: comp.sqft,
    }
  })

  const average = {
    afterRepairValue: meanBy(
      filter(compValues, 'propertyPrice'),
      'propertyPrice',
    ),
    secondary: 'Adjusted comp average',
  }
  const lowestAverage = {
    afterRepairValue: meanBy(
      slice(
        sortBy(filter(compValues, 'propertyPrice'), 'propertyPrice'),
        0,
        3,
      ),
      'propertyPrice',
    ),
    secondary: 'Adjusted lowest 3 comp average',
  }

  //OVERVIEW COMMENT: code for updating estimates
  const [openUpdateEstimates, setOpenUpdateEstimates] =
    useState(false)
  const [openUpdateEstimatesDialog, setOpenUpdateEstimatesDialog] =
    useState(false)
  const [targetEstimates, setTargetEstimates] = useState([])
  const [preferredEstimates, setPreferredEstimates] = useState({})
  const [changedValues, setChangedValues] = useState([])

  const toggleTargetEstimates = (name) => {
    let newTargetEstimates = [...targetEstimates]
    if (includes(newTargetEstimates, name))
      remove(newTargetEstimates, (target) => target === name)
    else newTargetEstimates.push(name)
    setTargetEstimates(newTargetEstimates)
  }

  function formatDate(date) {
    var today = new Date(date),
      d = new Date(today.getTime()),
      //d = new Date(today.getTime() + Math.abs(today.getTimezoneOffset()*60000)),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = '' + d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
    while (year.length < 4) year = '0' + year

    return [month, day, year].join('-')
  }

  const [summaryDateValue, setSummaryDateValue] =
    useState('Current Month')
  let startDate = new Date()
  let endDate = new Date()

  startDate.setHours(0)
  startDate.setMinutes(0)
  startDate.setSeconds(0)
  startDate.setMilliseconds(0)
  endDate.setHours(23)
  endDate.setMinutes(59)
  endDate.setSeconds(59)
  endDate.setMilliseconds(999)

  switch (summaryDateValue) {
    case 'Current Month':
      startDate.setMonth(startDate.getMonth(), 1)
      endDate.setMonth(endDate.getMonth() + 1, 0)
      break
    case 'YTD':
      startDate.setMonth(0, 1)
      endDate.setMonth(endDate.getMonth(), endDate.getDate())
      break
    case '12 Month Rolling':
      startDate.setMonth(startDate.getMonth() - 11, 1)
      endDate.setMonth(endDate.getMonth() + 1, 0)
      break
    default:
      break
  }

  const summaryDate = [
    {
      name: 'summaryDateValue',
      label: 'Summary Date',
      value: summaryDateValue,
      // type: 'datePicker',
      type: 'dropdown',
      dropdownDate: true,
      onChange: (event) => setSummaryDateValue(event.target.value),
    },
  ]

  const [budgetIndex, setBudgetIndex] = useState(-1)

  let budgetOptions = [
    'Appliances',
    'Cabinets/Vanities',
    'Carpet',
    'Countertops/Sinks/Toilets',
    'Driveway/Decking/Patio',
    'Electrical',
    'Exterior',
    'Flooring',
    'Framing',
    'Insulation/Drywall',
    'Landscaping',
    'Masonry',
    'Mechanical',
    'Molding/Tile/Trim',
    'Paint',
    'Plans/Permits',
    'Plumbing',
    'Roof',
    'Siding/Gutters',
    'Site Prep',
    'Staging',
    'Windows/Doors',
    'Other',
  ]
  pullAll(
    budgetOptions,
    map(property.budgetItems, (item) => item.name),
  )
  const budgetSelected =
    budgetIndex >= 0 && property.budgetItems[budgetIndex]
  if (budgetSelected)
    budgetOptions.push(property.budgetItems[budgetIndex].name)

  const [budgetItemName, setBudgetItemName] = useState('')
  const [budgetItemBudget, setBudgetItemBudget] = useState('')
  const [budgetItemLines, setBudgetItemLines] = useState([
    { name: '', amount: 0 },
  ])
  const [budgetItemReceipts, setBudgetItemReceipts] = useState([])
  const [
    budgetItemProjectedStartDate,
    setBudgetItemProjectedStartDate,
  ] = useState(null)
  const [budgetItemProjectedEndDate, setBudgetItemProjectedEndDate] =
    useState(null)
  const [budgetItemActualStartDate, setBudgetItemActualStartDate] =
    useState(null)
  const [budgetItemActualEndDate, setBudgetItemActualEndDate] =
    useState(null)

  const budgetItemHead = [
    {
      name: 'budgetItemName',
      label: 'Name',
      value: budgetItemName || '',
      options: budgetOptions,
      onChange: (event) => setBudgetItemName(event.target.value),
      type: 'select',
      xs: 12,
    },
    {
      name: 'budgetItemBudget',
      label: 'Budget',
      value: budgetItemBudget || '',
      onChange: (event) =>
        setBudgetItemBudget(Number(event.target.value)),
      type: 'text',
      xs: 12,
    },
    {
      name: 'budgetItemProjectedStartDate',
      label: 'Projected Start Date',
      value: budgetItemProjectedStartDate || '',
      onChange: (event) =>
        setBudgetItemProjectedStartDate(event.target.value),
      type: 'date',
      xs: 12,
    },
    {
      name: 'budgetItemProjectedEndDate',
      label: 'Projected End Date',
      value: budgetItemProjectedEndDate || '',
      onChange: (event) =>
        setBudgetItemProjectedEndDate(event.target.value),
      type: 'date',
      xs: 12,
    },
    {
      name: 'budgetItemActualStartDate',
      label: 'Actual Start Date',
      value: budgetItemActualStartDate || '',
      onChange: (event) =>
        setBudgetItemActualStartDate(event.target.value),
      type: 'date',
      xs: 12,
    },
    {
      name: 'budgetItemActualEndDate',
      label: 'Actual End Date',
      value: budgetItemActualEndDate || '',
      onChange: (event) =>
        setBudgetItemActualEndDate(event.target.value),
      type: 'date',
      xs: 12,
    },
  ]

  const [assetManagementInput, setAssetManagementInput] = useState({})
  const [addEditIndex, setAddEditIndex] = useState(-1)

  const leaseInformation = [
    {
      name: `lease.${addEditIndex}.rent`,
      label: 'Rent Amount',
      type: 'dollar',
      value: get(assetManagementInput, `lease.${addEditIndex}.rent`),
      asset: true,
      required: true,
    },
    // {
    //   name: `lease.${addEditIndex}.expirationDate`,
    //   label: 'Tenent Moved Out',
    //   value: get(assetManagementInput, `lease.${addEditIndex}.expirationDate`),
    //   type: 'date',
    //   asset: true,
    // },
    {
      name: `lease.${addEditIndex}.startDate`,
      label: 'Start Date',
      value: get(
        assetManagementInput,
        `lease.${addEditIndex}.startDate`,
      ),
      max: get(assetManagementInput, `lease.${addEditIndex}.endDate`),
      type: 'date',
      asset: true,
      required: true,
    },
    {
      name: `lease.${addEditIndex}.endDate`,
      label: 'Term (months)',
      type: 'date',
      value: get(
        assetManagementInput,
        `lease.${addEditIndex}.endDate`,
      ),
      min: get(
        assetManagementInput,
        `lease.${addEditIndex}.startDate`,
      ),
      asset: true,
      required: true,
    },
    // {
    //   name: `lease.${addEditIndex}.endDate`,
    //   label: 'End Date',
    //   value: get(assetManagementInput, `lease.${addEditIndex}.endDate`),
    //   type: 'date',
    //   asset: true,
    // },
    // {
    //   name: `lease.${addEditIndex}.daysVacant`,
    //   label: 'Days Vacant',
    //   type: 'number',
    //   value: get(assetManagementInput,`lease.${addEditIndex}.daysVacant`),
    //   asset: true,
    // },
    // {
    //   name: 'Upload Files',
    //   label: 'Upload Files',
    //   type: 'upload',
    // },
  ]

  const expenses = [
    {
      name: `expenses.${addEditIndex}.expenseAmount`,
      label: 'Expense Amount',
      type: 'dollar',
      value: get(
        assetManagementInput,
        `expenses.${addEditIndex}.expenseAmount`,
      ),
      asset: true,
      required: true,
    },
    {
      name: `expenses.${addEditIndex}.expenseType`,
      label: 'Expense Type',
      type: 'select',
      options: [
        'Appliances',
        'Cabinets/Vanities',
        'Carpet',
        'Countertops/Sinks/Toilets',
        'Driveway/Decking/Patio',
        'Electrical',
        'Exterior',
        'Flooring',
        'Framing',
        'Insulation/Drywall',
        'Landscaping',
        'Masonry',
        'Mechanical',
        'Molding/Tile/Trim',
        'Paint',
        'Plans/Permits',
        'Plumbing',
        'Roof',
        'Siding/Gutters',
        'Site Prep',
        'Staging',
        'Windows/Doors',
        'Other',
      ],
      value: get(
        assetManagementInput,
        `expenses.${addEditIndex}.expenseType`,
      ),
      asset: true,
      required: true,
    },
    {
      name: `expenses.${addEditIndex}.expenseDate`,
      label: 'Expense Date',
      datePlaceholder: 'MM/DD/YYYY',
      type: 'date',
      value: get(
        assetManagementInput,
        `expenses.${addEditIndex}.expenseDate`,
      ),
      asset: true,
      required: true,
    },
    {
      name: `expenses.${addEditIndex}.expenseName`,
      label: 'Expense Name',
      type: 'text',
      value: get(
        assetManagementInput,
        `expenses.${addEditIndex}.expenseName`,
      ),
      asset: true,
    },
    {
      name: `expenses.${addEditIndex}.expenseDescription`,
      label: 'Expense Description',
      type: 'text',
      value: get(
        assetManagementInput,
        `expenses.${addEditIndex}.expenseDescription`,
      ),
      asset: true,
    },
  ]

  const vacancy = [
    {
      name: `vacancy.${addEditIndex}.startDate`,
      label: 'Start Date',
      type: 'date',
      value: get(
        assetManagementInput,
        `vacancy.${addEditIndex}.startDate`,
      ),
      max: get(
        assetManagementInput,
        `vacancy.${addEditIndex}.endDate`,
      ),
      asset: true,
      required: true,
    },
    {
      name: `vacancy.${addEditIndex}.endDate`,
      label: 'End Date',
      type: 'date',
      value: get(
        assetManagementInput,
        `vacancy.${addEditIndex}.endDate`,
      ),
      min: get(
        assetManagementInput,
        `vacancy.${addEditIndex}.startDate`,
      ),
      asset: true,
      required: true,
    },
  ]

  const distribution = [
    {
      name: `distributions.${addEditIndex}.distributionAmount`,
      label: 'Amount',
      type: 'dollar',
      value: get(
        assetManagementInput,
        `distributions.${addEditIndex}.distributionAmount`,
      ),
      asset: true,
      required: true,
    },
    {
      name: `distributions.${addEditIndex}.distributionDate`,
      label: 'Distribution Date',
      type: 'date',
      value: get(
        assetManagementInput,
        `distributions.${addEditIndex}.distributionDate`,
      ),
      asset: true,
      required: true,
    },
    {
      name: `distributions.${addEditIndex}.distributionPayee`,
      label: 'Distribution Payee',
      type: 'text',
      value: get(
        assetManagementInput,
        `distributions.${addEditIndex}.distributionPayee`,
      ),
      asset: true,
    },
    {
      name: `distributions.${addEditIndex}.distributionDescription`,
      label: 'Description',
      type: 'text',
      value: get(
        assetManagementInput,
        `distributions.${addEditIndex}.distributionDescription`,
      ),
      asset: true,
    },
  ]

  const propertyManager = [
    {
      name: 'manager.managerType',
      label: 'Property Manager',
      type: 'select',
      options: ['Third Party', 'Self'],
      value: get(assetManagementInput, 'manager.managerType'),
      asset: true,
      required: true,
    },
    {
      name: 'manager.companyName',
      label: 'Company Name',
      type: 'text',
      value: get(assetManagementInput, 'manager.companyName'),
      asset: true,
    },
    {
      name: 'manager.dateHired',
      label: 'Date Hired',
      type: 'date',
      value: get(assetManagementInput, 'manager.dateHired'),
      asset: true,
      required: true,
    },
    // {
    //   name: 'manager.endDate',
    //   label: 'End Date',
    //   type: 'date',
    //   value: get(assetManagementInput, 'manager.endDate'),
    //   asset: true,
    // },
  ]

  const lastOwnerStatement = [
    {
      name: 'lastOwner.lastOwnerStatement',
      label: 'Last Owner Statement',
      type: 'date',
      value: get(
        assetManagementInput,
        'lastOwner.lastOwnerStatement',
      ),
      asset: true,
      required: true,
    },
    // {
    //   name: 'File Upload',
    //   label: 'File Upload',
    //   type: 'upload',
    // },
  ]

  const [openLeaseInformation, setOpenLeaseInformation] =
    useState(false)
  const handleOpenLeaseInformation =
    (addEditIndex = -1) =>
    () => {
      setOpenLeaseInformation(true)
      setAssetManagementInput({
        lease:
          addEditIndex >= 0
            ? assetManagement.lease || []
            : concat(
                {
                  rent: head(analysisInput.rent),
                },
                assetManagement.lease || [],
              ),
      })
      setAddEditIndex(addEditIndex > 0 ? addEditIndex : 0)
    }
  const handleCloseLeaseInformation = () => {
    setOpenLeaseInformation(false)
    setAssetManagementInput({})
  }

  const [anchorElLeaseOptions, setAnchorElLeaseOptions] =
    React.useState(null)
  const handleClickLeaseOptions = (index) => (event) => {
    setAddEditIndex(index)
    setAnchorElLeaseOptions(event.currentTarget)
    event.stopPropagation()
  }

  const handleCloseLeaseOptions = () => {
    setAnchorElLeaseOptions(null)
  }

  const [openRecordMortgagePayments, setOpenRecordMortgagePayments] =
    useState(false)
  const handleOpenRecordMortgagePayments =
    (addEditIndex = -1) =>
    () => {
      let lastMortgage = head(get(assetManagement, 'mortgage')) || {}
      let lastMonth = lastMortgage.mortgageMonth
        ? new Date(lastMortgage.mortgageMonth)
        : null
      if (lastMonth) {
        if (lastMonth.getMonth() === 11)
          lastMonth.setFullYear(lastMonth.getFullYear() + 1, 0)
        else lastMonth.setMonth(lastMonth.getMonth() + 1)
      }
      setOpenRecordMortgagePayments(true)
      setAssetManagementInput({
        mortgage:
          addEditIndex >= 0
            ? assetManagement.mortgage || []
            : concat(
                {
                  principal: lastMortgage.principal,
                  interest: lastMortgage.interest,
                  additionalPrincipal:
                    lastMortgage.additionalPrincipal,
                  mortgageMonth: lastMonth,
                },
                assetManagement.mortgage || [],
              ),
      })
      setAddEditIndex(addEditIndex >= 0 ? addEditIndex : 0)
    }
  const handleCloseRecordMortgagePayments = () => {
    setOpenRecordMortgagePayments(false)
    setAssetManagementInput({})
  }

  const [anchorElMortgageOptions, setAnchorElMortgageOptions] =
    React.useState(null)
  const handleClickMortgageOptions = (index) => (event) => {
    setAddEditIndex(index)
    setAnchorElMortgageOptions(event.currentTarget)
    event.stopPropagation()
  }

  const handleCloseMortgageOptions = () => {
    setAnchorElMortgageOptions(null)
  }

  const [openCurrentEquity, setOpenCurrentEquity] = useState(false)
  const handleOpenCurrentEquity = () => {
    setOpenCurrentEquity(true)
    setAssetManagementInput({
      equity: merge(
        {
          currentLoanAmount:
            Number(
              get(assetManagement, 'equity.currentLoanAmount') ||
                analytics.buyHold.loanAmount ||
                0,
            ) -
            Number(
              get(assetManagement, 'mortgage.0.principal') || 0,
            ) -
            Number(
              get(
                assetManagement,
                'mortgage.0.additionalPrincipal',
              ) || 0,
            ),
          afterRepairValue: analysisInput.afterRepairValue,
        },
        { ...(assetManagement ? assetManagement.equity : {}) },
      ),
    })
  }
  const handleCloseCurrentEquity = () => {
    setOpenCurrentEquity(false)
    setAssetManagementInput({})
  }

  const [
    anchorElCurrentEquityOptions,
    setAnchorElCurrentEquityOptions,
  ] = React.useState(null)
  const handleClickCurrentEquityOptions = (index) => (event) => {
    setAddEditIndex(index)
    setAnchorElCurrentEquityOptions(event.currentTarget)
    event.stopPropagation()
  }
  const handleCloseCurrentEquityOptions = () => {
    setAnchorElCurrentEquityOptions(null)
  }

  const [openReceiveRent, setOpenReceiveRent] = useState(false)
  const handleOpenReceiveRent =
    (addEditIndex = -1) =>
    () => {
      let lastIncome = last(get(assetManagement, 'receiveRent')) || {}
      let lastMonth = lastIncome.rentPeriod
        ? new Date(lastIncome.rentPeriod)
        : null
      if (lastMonth) {
        if (lastMonth.getMonth() === 11)
          lastMonth.setFullYear(lastMonth.getFullYear() + 1, 0)
        else lastMonth.setMonth(lastMonth.getMonth() + 1)
      }
      setOpenReceiveRent(true)
      setAssetManagementInput({
        receiveRent:
          addEditIndex >= 0
            ? assetManagement.receiveRent || []
            : concat(
                {
                  collectedAmount: lastIncome.collectedAmount,
                  rentPeriod: lastMonth,
                  paymentMethod: lastIncome.paymentMethod,
                  otherIncome: lastIncome.otherIncome,
                },
                assetManagement.receiveRent || [],
              ),
      })
      setAddEditIndex(addEditIndex >= 0 ? addEditIndex : 0)
    }
  const handleCloseReceiveRent = () => {
    setOpenReceiveRent(false)
    setAssetManagementInput({})
  }

  const [anchorElReceiveRentOptions, setAnchorElReceiveRentOptions] =
    React.useState(null)
  const handleClickReceiveRentOptions = (index) => (event) => {
    setAddEditIndex(index)
    setAnchorElReceiveRentOptions(event.currentTarget)
    event.stopPropagation()
  }

  const handleCloseReceiveRentOptions = () => {
    setAnchorElReceiveRentOptions(null)
  }

  const [openEnterActuals, setOpenEnterActuals] = useState(false)
  const handleOpenEnterActuals = () => {
    setOpenEnterActuals(true)
  }
  const handleCloseEnterActuals = () => {
    setOpenEnterActuals(false)
  }

  const [openExpenses, setOpenExpenses] = useState(false)
  const handleOpenExpenses =
    (addEditIndex = -1) =>
    () => {
      setOpenExpenses(true)
      setAssetManagementInput({
        expenses:
          addEditIndex >= 0
            ? assetManagement.expenses || []
            : concat({}, assetManagement.expenses || []),
      })
      setAddEditIndex(addEditIndex > 0 ? addEditIndex : 0)
    }
  const handleCloseExpenses = () => {
    setOpenExpenses(false)
    setAssetManagementInput({})
  }

  const [anchorElExpenseOptions, setAnchorElExpenseOptions] =
    React.useState(null)
  const handleClickExpenseOptions = (index) => (event) => {
    setAddEditIndex(index)
    setAnchorElExpenseOptions(event.currentTarget)
    event.stopPropagation()
  }

  const handleCloseExpenseOptions = () => {
    setAnchorElExpenseOptions(null)
  }

  const [openExpensesDescription, setOpenExpensesDescription] =
    useState(-1)
  const handleOpenExpensesDescription = (index) => () => {
    setOpenExpensesDescription(
      openExpensesDescription === index ? -1 : index,
    )
  }

  const [openVacancy, setOpenVacancy] = useState(false)
  const handleOpenVacancy =
    (addEditIndex = -1) =>
    () => {
      setOpenVacancy(true)
      setAssetManagementInput({
        vacancy:
          addEditIndex >= 0
            ? assetManagement.vacancy || []
            : concat({}, assetManagement.vacancy || []),
      })
      setAddEditIndex(addEditIndex > 0 ? addEditIndex : 0)
    }
  const handleCloseVacancy = () => {
    setOpenVacancy(false)
    setAssetManagementInput({})
  }

  const [anchorElVacancyOptions, setAnchorElVacancyOptions] =
    React.useState(null)
  const handleClickVacancyOptions = (index) => (event) => {
    setAddEditIndex(index)
    setAnchorElVacancyOptions(event.currentTarget)
    event.stopPropagation()
  }

  const handleCloseVacancyOptions = () => {
    setAnchorElVacancyOptions(null)
  }

  const [openDistributions, setOpenDistributions] = useState(false)
  const handleOpenDistributions =
    (addEditIndex = -1) =>
    () => {
      setOpenDistributions(true)
      setAssetManagementInput({
        distributions:
          addEditIndex >= 0
            ? assetManagement.distributions || []
            : concat({}, assetManagement.distributions || []),
      })
      setAddEditIndex(addEditIndex > 0 ? addEditIndex : 0)
    }
  const handleCloseDistributions = () => {
    setOpenDistributions(false)
    setAssetManagementInput({})
  }

  const [
    anchorElDistributionOptions,
    setAnchorElDistributionOptions,
  ] = React.useState(null)
  const handleClickDistributionOptions = (index) => (event) => {
    setAddEditIndex(index)
    setAnchorElDistributionOptions(event.currentTarget)
    event.stopPropagation()
  }

  const handleCloseDistributionOptions = () => {
    setAnchorElDistributionOptions(null)
  }

  const [
    openDistributionDescription,
    setOpenDistributionDescription,
  ] = useState(-1)
  const handleOpenDistributionDescription = (index) => () => {
    setOpenDistributionDescription(
      openDistributionDescription === index ? -1 : index,
    )
  }

  const [openPropertyManager, setOpenPropertyManager] =
    useState(false)
  const handleOpenPropertyManager = () => {
    setOpenPropertyManager(true)
    setAssetManagementInput({
      manager: merge(
        { managerType: 'Third Party' },
        { ...(assetManagement ? assetManagement.manager : {}) },
      ),
    })
  }

  const handleClosePropertyManager = () => {
    setOpenPropertyManager(false)
    setAssetManagementInput({})
  }

  const [
    anchorElPropertyManagerOptions,
    setAnchorElPropertyManagerOptions,
  ] = React.useState(null)
  const handleClickPropertyManagerOptions = (index) => (event) => {
    setAddEditIndex(index)
    setAnchorElPropertyManagerOptions(event.currentTarget)
    event.stopPropagation()
  }
  const handleClosePropertyManagerOptions = () => {
    setAnchorElPropertyManagerOptions(null)
  }

  const [openLastOwnerStatement, setOpenLastOwnerStatement] =
    useState(false)
  const handleOpenLastOwnerStatement = () => {
    setOpenLastOwnerStatement(true)
    setAssetManagementInput({
      lastOwner: {
        ...(assetManagement ? assetManagement.lastOwner : {}),
      },
    })
  }
  const handleCloseLastOwnerStatement = () => {
    setOpenLastOwnerStatement(false)
    setAssetManagementInput({})
  }

  const [anchorElLastOwnerOptions, setAnchorElLastOwnerOptions] =
    React.useState(null)
  const handleClickLastOwnerOptions = (index) => (event) => {
    setAddEditIndex(index)
    setAnchorElLastOwnerOptions(event.currentTarget)
    event.stopPropagation()
  }
  const handleCloseLastOwnerOptions = () => {
    setAnchorElLastOwnerOptions(null)
  }

  const [saveAssetManagement] = useMutation(SAVE_ASSET_MANAGEMENT)

  const onChangeAsset = (event, quick = false) => {
    const { name = '', value = '', type = '' } = event.target
    let newValue = value
    if (type === 'number') newValue = Number(value)
    if (type === 'date') {
      newValue = value
      if (
        !newValue ||
        isNaN(newValue.getTime()) ||
        newValue.getFullYear() > 9999
      )
        return null
    }
    let newAssetManagementInput = cloneDeep(assetManagementInput)
    set(newAssetManagementInput, name, newValue)
    setAssetManagementInput(newAssetManagementInput)
  }

  const uploadFileAsset = (sectionName) => (files) => {
    let newAssetManagementInput = cloneDeep(assetManagementInput)
    let newFiles =
      get(newAssetManagementInput, `${sectionName}.files`) || []
    map(files, (file) => {
      newFiles.push(file)
    })
    set(newAssetManagementInput, `${sectionName}.files`, newFiles)
    setAssetManagementInput(newAssetManagementInput)
  }

  const deleteFileAsset =
    (sectionName) =>
    (saveOnChange = false) => {
      let newFiles = []
      let newAssetManagementInput = { ...assetManagementInput }
      set(newAssetManagementInput, `${sectionName}.files`, newFiles)
      setAssetManagementInput(newAssetManagementInput)
      if (saveOnChange)
        saveAssetManagementObject(null, newAssetManagementInput)()
    }

  const [openDeleteAsset, setOpenDeleteAsset] = useState(false)
  const [confirmDeleteAsset, setConfirmDeleteAsset] = useState(false)

  const handleOpenDeleteAsset =
    (name, index = -1) =>
    () => {
      let newAssetManagementInput = {}
      if (index === -1) newAssetManagementInput[name] = {}
      else {
        newAssetManagementInput[name] = cloneDeep(
          get(assetManagement, name),
        )
        pullAt(get(newAssetManagementInput, name), index)
      }
      setAssetManagementInput(newAssetManagementInput)
      setOpenDeleteAsset(true)
      setConfirmDeleteAsset(false)
    }

  const handleCloseDeleteAsset = () => {
    setOpenDeleteAsset(false)
    setAssetManagementInput({})
    setConfirmDeleteAsset(false)
  }

  const saveAssetManagementObject =
    (closeFunction, newAssetManagementInput = null) =>
    () => {
      setLoader(true)
      let saveInput = newAssetManagementInput
        ? newAssetManagementInput
        : assetManagementInput
      forEach(saveInput, (field) => {
        if (isArray(field)) {
          map(field, (n) => {
            unset(n, 'updatedBy')
            unset(n, 'updatedAt')
            unset(n, '__typename')
          })
        }
        unset(field, 'updatedBy')
        unset(field, 'updatedAt')
        unset(field, '__typename')
      })
      saveAssetManagement({
        variables: {
          propertyId: property._id,
          assetManagementInput: saveInput,
        },
        refetchQueries: [
          {
            query: GET_PROPERTY,
            variables: { id: property._id },
          },
        ],
      }).then((data) => {
        setLoader(false)
        // handleUpdateMarket()
        if (closeFunction) closeFunction()
      })
    }

  const moveLease = (index) => () => {
    let lease = assetManagement ? [...assetManagement.lease] : null
    if (!lease) return
    lease = arrayMoveImmutable(lease, index, 0)
    map(lease, (n) => {
      unset(n, 'updatedBy')
      unset(n, 'updatedAt')
      unset(n, '__typename')
    })
    saveAssetManagement({
      variables: {
        propertyId: property._id,
        assetManagementInput: {
          lease,
        },
      },
      refetchQueries: [
        {
          query: GET_PROPERTY,
          variables: { id: property._id },
        },
      ],
    }).then((data) => {
      // handleUpdateMarket()
    })
  }

  const [open, setOpen] = useState('')
  const handleOpen = (value) => {
    if (open === value) setOpen('')
    else setOpen(value)
  }

  const [openMember, setOpenMember] = useState(false)
  const [openMemberDisclaimer, setOpenMemberDisclaimer] =
    useState(false)
  const [openArchive, setOpenArchive] = useState(false)

  const [openEndTrial, setOpenEndTrial] = useState(false)

  const [endTrial] = useMutation(END_TRIAL)

  const [openAccept, setOpenAccept] = useState(false)
  const [openDiscuss, setOpenDiscuss] = useState(false)
  const [openReject, setOpenReject] = useState(false)

  let lenderOptions = []
  const [lenderOption, setLenderOption] = useState()

  let lendedOptions = []
  if (property.lender) lendedOptions.push('Lender')
  if (property.jv) lendedOptions.push('JV')
  const [lendedOption, setLendedOption] = useState()

  useEffect(() => {
    if (!lenderOption && head(lenderOptions))
      setLenderOption(head(lenderOptions))
    if (!lendedOption && head(lendedOptions))
      setLendedOption(head(lendedOptions))
  }, [lenderOptions, lendedOptions])

  const uploadFileJV = (files) => {
    let newPropertyInput = cloneDeep(propertyInput)
    let newFiles = head(files)
    set(newPropertyInput, `jvFile`, newFiles)
    setPropertyInput(newPropertyInput)
  }

  const deleteFileJV = () => {
    let newPropertyInput = cloneDeep(propertyInput)
    set(newPropertyInput, `jvFile`, null)
    setPropertyInput(newPropertyInput)
  }

  const [lastSideBar, setLastSideBar] = useState(
    localStorage.getItem('offerToCloseOpen') === 'true'
      ? 'offerToClose'
      : 'underwriting',
  )

  const toggleSidebarMenu = () =>
    setIsSidebarMenuOpen(!isSidebarMenuOpen)

  const addBudgetLine = () => {
    let newBudgetLines = [...budgetItemLines]
    newBudgetLines.push({ name: '', amount: 0 })
    setBudgetItemLines(newBudgetLines)
  }

  const removeBudgetLine = (index) => {
    let newBudgetLines = [...budgetItemLines]
    pullAt(newBudgetLines, index)
    setBudgetItemLines(newBudgetLines)
  }

  const changeBudgetLine = (event, index) => {
    const { name = '', value = '', type = '' } = event.target
    let newBudgetLines = [...budgetItemLines]
    newBudgetLines[index][name] =
      type === 'number' ? Number(value) : value
    setBudgetItemLines(newBudgetLines)
  }

  results = useQuery(GET_LOT_PROCESSES, {
    variables: {
      lotId: id,
    },
  })
  let { lotProcesses = {} } = results.data || {}
  const lotProcessesLoading = results.loading
  const lotProcessesError = results.error

  const [lotInput, setLotInput] = useState()

  const [saveLotProcessesObject] = useMutation(SAVE_LOT_PROCESSES)

  const [lotProcessesInput, setLotProcessesInput] = useState({})

  useEffect(() => {
    if (
      isEmpty(lotProcessesInput) &&
      !lotProcessesLoading &&
      !isEmpty(lotProcesses)
    ) {
      let input = cloneDeep(lotProcesses)
      unset(input, '_id')
      unset(input, '__typename')
      setLotProcessesInput(input)
    }
  }, [lotProcesses, lotProcessesInput, lotProcessesLoading])

  const onChangeLotProcess = (event, quick = false) => {
    const { name = '', value = '', type = '' } = event.target
    let newValue = value
    if (type === 'checkbox') newValue = !get(lotProcessesInput, name)
    if (type === 'number') newValue = Number(value)
    if (type === 'date') {
      newValue = value
      if (
        !newValue ||
        isNaN(newValue.getTime()) ||
        newValue.getFullYear() > 9999
      )
        return null
    }
    let newLotProcessesInput = cloneDeep(lotProcessesInput)
    set(newLotProcessesInput, name, newValue)
    setLotProcessesInput(newLotProcessesInput)
    if (quick) {
      submitLotProcess(id, newLotProcessesInput)
      debouncedSubmitLotProcess.cancel()
    }
  }

  const uploadFileLot = (target) => (files) => {
    let newLotProcessesInput = cloneDeep(lotProcessesInput)
    let newFiles = get(newLotProcessesInput, target) || []
    map(files, (file) => {
      newFiles.push(file)
    })
    set(newLotProcessesInput, target, newFiles)
    setLotProcessesInput(newLotProcessesInput)
  }

  const deleteFileLot = (target) => (index) => {
    let newLotProcessesInput = cloneDeep(lotProcessesInput)
    let newFiles = get(newLotProcessesInput, target) || []
    pullAt(newFiles, index)
    set(newLotProcessesInput, target, newFiles)
    setLotProcessesInput(newLotProcessesInput)
  }

  const submitLotProcess = (lotId, lotProcessesInput) => {
    saveLotProcessesObject({
      variables: {
        lotId: id,
        lotProcessesInput: lotProcessesInput,
      },
      refetchQueries: [
        {
          query: GET_LOT_PROCESSES,
          variables: { lotId: id },
        },
      ],
    }).then((data) => {
      //handleUpdateMarket()
    })
  }

  const debouncedSubmitLotProcess = useRef(
    debounce(
      (lotId, lotProcessesInput) =>
        submitLotProcess(lotId, lotProcessesInput),
      1000,
    ),
  ).current

  useEffect(() => {
    if (isEmpty(lotProcessesInput) || propertyLoading) return
    debouncedSubmitLotProcess(id, lotProcessesInput)
    return () => debouncedSubmitLotProcess.cancel()
  }, [lotProcessesInput, propertyLoading, debouncedSubmitLotProcess])

  const renderInputFields = renderInputFieldsFunction(
    {
      onChangeProperty,
      onChangeAnalysis,
      onChangeAsset,
      onChangeAssumptions,
      onChangeLotProcess,
      //onChangeOffer,
      onChangeExchange,
    },
    {
      showFormatDropdown,
      anchorElAssumptions,
      toggleAssumptionsMenuFormat,
      handleAssumptionsMenuClose,
      handleAdornmentChange,
      handleAnalysisAdornmentChange,
      handleDefinition,
    },
    props?.session?.me?.activeOrg?.member,
  )

  //const [activeTab, setActiveTab] = useState('Summary');
  const activeTab = view

  const urlParams = new URLSearchParams(window.location.search)
  const subdomain = urlParams.get('subdomain') || null
  const subdomainParam = subdomain ? '?subdomain=' + subdomain : ''

  let navigate = useNavigate()

  const toggle = (tab) => {
    if (activeTab !== tab)
      navigate(
        `/public-property-details/${id}/${tab}${subdomainParam}`,
      )
    //setActiveTab(tab);
  }

  const preferences = merge(cloneDeep(DEFAULT_PREFERENCES), {
    general: { strategies: get(property, 'portfolio.strategies') },
  })

  const uploadFileOther = (files) => {
    let newPropertyInput = cloneDeep(propertyInput)
    let newFiles = get(newPropertyInput, `otherFiles`) || []
    map(files, (file) => {
      newFiles.push(file)
    })
    set(newPropertyInput, `otherFiles`, newFiles)
    setPropertyInput(newPropertyInput)
  }

  const deleteFileOther = (index) => {
    let newPropertyInput = cloneDeep(propertyInput)
    let newFiles = get(newPropertyInput, `otherFiles`) || []
    pullAt(newFiles, index)
    set(newPropertyInput, `otherFiles`, newFiles)
    setPropertyInput(newPropertyInput)
  }

  const deleteFileApplyForLending = (target) => {
    let newPropertyInput = cloneDeep(propertyInput)
    let newFiles = []
    set(newPropertyInput, `applyForLending.${target}`, newFiles)
    setPropertyInput(newPropertyInput)
  }

  const findFiles = (obj, collection, path = '') => {
    if (!obj) return
    else if (obj.filename)
      return {
        ...obj,
        collection,
        path,
      }
    else if (isObject(obj) || isArray(obj)) {
      return compact(
        flatMap(obj, (field, key) => {
          return findFiles(
            field,
            collection,
            path ? path + '.' + key : toString(key),
          )
        }),
      )
    }
  }

  const deleteFileFromList = (file) => {
    if (file.collection === 'Offer to Close') {
      let sectionTarget = join(
        slice(file.path, 0, lastIndexOf(file.path, '.')),
        '',
      )
      let index = join(
        slice(file.path, lastIndexOf(file.path, '.') + 1),
        '',
      )
      let newFiles = cloneDeep(get(offer, sectionTarget))
      pullAt(newFiles, index)
      let newOfferToCloseInput = cloneDeep(offerToCloseInput)
      set(newOfferToCloseInput, `${sectionTarget}`, newFiles)
      setOfferToCloseInput(newOfferToCloseInput)
    } else if (file.collection === 'Renovation') {
      let target = join(
        slice(file.path, 0, lastIndexOf(file.path, '.')),
        '',
      )
      let index = join(
        slice(file.path, lastIndexOf(file.path, '.') + 1),
        '',
      )
      let newFiles = [...get(property.budgetItems, target)]
      pullAt(newFiles, index)
      let newBudgetItems = [...property.budgetItems]
      map(newBudgetItems, (item) => {
        unset(item, '__typename')
        map(item.lines, (line) => {
          unset(line, '__typename')
        })
      })
      set(newBudgetItems, target, newFiles)
      //  updateProperty({
      //    variables: {
      //      id: id,
      //      propertyInput: {
      //        budgetItems: newBudgetItems,
      //      },
      //    },
      //    refetchQueries: [
      //      {
      //        query: GET_PROPERTY,
      //        variables: { id: property._id }
      //      }
      //    ]
      //  })
    } else if (
      file.collection === 'Lot' ||
      file.collection === 'Build' ||
      file.collection === 'Sale'
    ) {
      let sectionTarget = join(
        slice(file.path, 0, lastIndexOf(file.path, '.')),
        '',
      )
      let index = join(
        slice(file.path, lastIndexOf(file.path, '.') + 1),
        '',
      )
      deleteFileLot(sectionTarget)(index)
    } else if (file.collection === 'Asset Management') {
      let sectionTarget = join(
        slice(file.path, 0, lastIndexOf(file.path, '.')),
        '',
      )
      sectionTarget = join(
        slice(sectionTarget, 0, lastIndexOf(sectionTarget, '.')),
        '',
      )
      deleteFileAsset(sectionTarget)(true)
    } else if (file.collection === 'Proforma') {
      //  updateProperty({
      //    variables: {
      //      id: id,
      //      propertyInput: {
      //        proformaFile: {},
      //      },
      //    },
      //    refetchQueries: [
      //      {
      //        query: GET_PROPERTY,
      //        variables: { id: property._id }
      //      }
      //    ]
      //  })
    } else if (file.collection === 'Manage Files') {
      let index = join(
        slice(file.path, lastIndexOf(file.path, '.') + 1),
        '',
      )
      deleteFileOther(index)
    } else if (file.collection === 'Apply for Lending') {
      deleteFileApplyForLending(file.path)
    }
  }

  const files = compact(
    concat(findFiles(property.marketplaceFiles, 'Marketplace Files')),
  )

  const publicImageCard = (shared) => (
    <Card
      className="card-box"
      style={{ height: '100%', width: '100%' }}
    >
      <div className="card-header d-flex align-items-center justify-content-between card-header-alt">
        <ButtonGroup variant="text">
          <Button
            className={clsx(
              'btn-outline-primary btn-transition-none',
              { active: imageActiveTab === 'Images' },
            )}
            onClick={() => {
              tabSwitch('Images')
            }}
          >
            <ImageIcon style={{ paddingRight: 5 }} />
            Images
          </Button>
          <Button
            className={clsx(
              'btn-outline-primary btn-transition-none',
              { active: imageActiveTab === 'Map' },
            )}
            onClick={() => {
              tabSwitch('Map')
            }}
          >
            <RoomIcon style={{ paddingRight: 5 }} />
            Map
          </Button>
          <Button
            className={clsx(
              'btn-outline-primary btn-transition-none',
              { active: imageActiveTab === 'Street' },
            )}
            onClick={() => {
              tabSwitch('Street')
            }}
          >
            <DirectionsWalkIcon style={{ paddingRight: 5 }} />
            Street
          </Button>
          {property.videoWalkthrough && (
            <Button
              className={clsx(
                'btn-outline-primary btn-transition-none',
                { active: imageActiveTab === 'Video' },
              )}
              onClick={() => {
                tabSwitch('Video')
              }}
            >
              <FontAwesomeIcon
                icon={['fas', 'video']}
                style={{ paddingRight: 5 }}
              />
              Video Walkthrough
            </Button>
          )}
        </ButtonGroup>
      </div>
      {imageActiveTab === 'Images' && (
        <div>
          <div>
            <Slider
              ref={slider}
              className="card-img-top slider-dots"
              {...widgetsCarousels1B}
              style={{ maxWidth: 720 }}
            >
              {!isEmpty(compact(property.images))
                ? map(compact(property.images), (image, index) => (
                    <div>
                      <Card
                        className="shadow-none"
                        style={{
                          backgroundColor: 'white',
                          margin: 1,
                        }}
                      >
                        <Grid
                          container
                          direction="column"
                          justifyContent="center"
                          style={{ height: 500 }}
                        >
                          <Grid item>
                            <div
                              className="card-img-wrapper"
                              style={{
                                textAlign: 'center',
                                backgroundColor: 'transparent',
                              }}
                            >
                              <div
                                className="blur-load"
                                style={{
                                  backgroundImage:
                                    'url(' + image.url_small + ')',
                                  height: 500,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <img
                                  src={image.url}
                                  className="card-img-top rounded"
                                  style={{
                                    maxHeight: 500,
                                    height: 'auto',
                                    borderBottomRightRadius: '0px',
                                    objectFit: 'fill',
                                  }}
                                  alt="..."
                                  loading="lazy"
                                />
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Card>
                    </div>
                  ))
                : null}
            </Slider>
          </div>
        </div>
      )}
      {imageActiveTab === 'Map' && (
        <Map
          properties={[property]}
          propertyPage
          streetView={false}
          setView={toggle}
          shared={shared}
        />
      )}
      {imageActiveTab === 'Street' && (
        <Map
          properties={[property]}
          propertyPage
          streetView={true}
          setView={toggle}
          shared={shared}
        />
      )}
      {imageActiveTab === 'Video' && (
        <div>
          <div>
            <Card
              className="shadow-none"
              style={{ backgroundColor: 'white', margin: 1 }}
            >
              <Grid
                container
                direction="column"
                justifyContent="center"
                style={{ height: 500 }}
              >
                <Grid item>
                  <div
                    className="card-img-wrapper"
                    style={{
                      textAlign: 'center',
                      backgroundColor: 'transparent',
                    }}
                  >
                    <iframe
                      src={property.videoWalkthrough}
                      /*width="640"*/ height="500"
                      width="100%"
                      frameborder="0"
                      allow="autoplay; fullscreen"
                      allowfullscreen
                      style={{ maxWidth: 720 }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Card>
          </div>
        </div>
      )}
      <div>
        {property.description && (
          <div>
            <Typography align="center">
              {property.description || 'No description included'}
            </Typography>
          </div>
        )}
        {props.marketplace && props.marketplace.preApprovedURL && (
          <div item xs={12} container justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              component="a"
              href={props.marketplace.preApprovedURL}
              target="_blank"
              style={{
                color: 'white',
                fontWeight: 700,
                padding: '0.5rem 1rem',
                fontSize: '1.25rem',
                lineHeight: '1.5',
                marginLeft: 5,
                alignContent: 'center',
              }}
            >
              Get Pre Approved
            </Button>
          </div>
        )}
      </div>
    </Card>
  )

  const renderStrategyPage = (property, shared) => {
    switch (strategyCard.page) {
      case 'BuyHold':
        return (
          <BuyHold
            property={property}
            toggleCenter={toggle}
            uploadFileProperty={uploadFileProperty}
            analysisInput={analysisInput}
            assumptionsInput={assumptionsInput}
            renderInputFields={renderInputFields(shared)}
            shared={shared}
            canDrag={canDrag}
            basicView={basicView}
            activeUnit={activeUnit}
            setActiveUnit={setActiveUnit}
            handleOpenAmortization={handleOpenAmortization}
            compProperties={compProperties}
            compValues={compValues}
            determinedComps={determinedComps}
            lowestAverage={lowestAverage}
            publicDashboard={true}
            publicImageCard={publicImageCard(shared)}
          />
        )
      case 'FixFlip':
        return (
          <FixFlip
            property={property}
            toggleCenter={toggle}
            uploadFileProperty={uploadFileProperty}
            analysisInput={analysisInput}
            assumptionsInput={assumptionsInput}
            renderInputFields={renderInputFields(shared)}
            shared={shared}
            canDrag={canDrag}
            setOpenMember={setOpenMember}
            setOpenMemberDisclaimer={setOpenMemberDisclaimer}
            activeUnit={activeUnit}
            setActiveUnit={setActiveUnit}
            fixFlipPercentage={0}
            compProperties={compProperties}
            compValues={compValues}
            determinedComps={determinedComps}
            lowestAverage={lowestAverage}
            publicDashboard={true}
            publicImageCard={publicImageCard(shared)}
          />
        )
      case 'Turnkey':
        return (
          <Turnkey
            property={property}
            toggleCenter={toggle}
            uploadFileProperty={uploadFileProperty}
            analysisInput={analysisInput}
            assumptionsInput={assumptionsInput}
            renderInputFields={renderInputFields(shared)}
            shared={shared}
            canDrag={canDrag}
            activeUnit={activeUnit}
            setActiveUnit={setActiveUnit}
            handleOpenAmortization={handleOpenAmortization}
            compProperties={compProperties}
            compValues={compValues}
            determinedComps={determinedComps}
            lowestAverage={lowestAverage}
            publicDashboard={true}
            publicImageCard={publicImageCard(shared)}
          />
        )
      case 'BRRRR':
        return (
          <BRRRR
            property={property}
            toggleCenter={toggle}
            uploadFileProperty={uploadFileProperty}
            analysisInput={analysisInput}
            assumptionsInput={assumptionsInput}
            renderInputFields={renderInputFields(shared)}
            shared={shared}
            canDrag={canDrag}
            activeUnit={activeUnit}
            setActiveUnit={setActiveUnit}
            handleOpenAmortization={handleOpenAmortization}
            compProperties={compProperties}
            compValues={compValues}
            determinedComps={determinedComps}
            lowestAverage={lowestAverage}
            publicDashboard={true}
            publicImageCard={publicImageCard(shared)}
          />
        )
      case 'ShortTermRental':
        return (
          <ShortTermRental
            property={property}
            toggleCenter={toggle}
            uploadFileProperty={uploadFileProperty}
            analysisInput={analysisInput}
            assumptionsInput={assumptionsInput}
            renderInputFields={renderInputFields(shared)}
            shared={shared}
            canDrag={canDrag}
            activeUnit={activeUnit}
            setActiveUnit={setActiveUnit}
            handleOpenAmortization={handleOpenAmortization}
            compProperties={compProperties}
            compValues={compValues}
            determinedComps={determinedComps}
            lowestAverage={lowestAverage}
            rentalComps={rentalComps}
            publicDashboard={true}
            publicImageCard={publicImageCard(shared)}
          />
        )
      case 'Wholesale':
        return (
          <Wholesale
            property={property}
            toggleCenter={toggle}
            uploadFileProperty={uploadFileProperty}
            analysisInput={analysisInput}
            assumptionsInput={assumptionsInput}
            renderInputFields={renderInputFields(shared)}
            shared={shared}
            canDrag={canDrag}
            setOpenMember={setOpenMember}
            setOpenMemberDisclaimer={setOpenMemberDisclaimer}
            setOpenEndTrial={setOpenEndTrial}
            activeUnit={activeUnit}
            setActiveUnit={setActiveUnit}
            compProperties={compProperties}
            compValues={compValues}
            determinedComps={determinedComps}
            lowestAverage={lowestAverage}
            publicDashboard={true}
            publicImageCard={publicImageCard(shared)}
          />
        )
      case 'BuildToRent':
        return (
          <BuildToRent
            property={property}
            toggleCenter={toggle}
            uploadFileProperty={uploadFileProperty}
            analysisInput={analysisInput}
            assumptionsInput={assumptionsInput}
            createNewMezzanine={createNewMezzanine}
            lenderOverride={
              () => {
                return null
              } /*lenderOverride*/
            }
            renderInputFields={renderInputFields(shared)}
            shared={shared}
            canDrag={canDrag}
            activeUnit={activeUnit}
            setActiveUnit={setActiveUnit}
            handleOpenAmortization={handleOpenAmortization}
            compProperties={compProperties}
            compValues={compValues}
            determinedComps={determinedComps}
            lowestAverage={lowestAverage}
            publicDashboard={true}
            publicImageCard={publicImageCard(shared)}
          />
        )
      case 'BOR':
        return (
          <BOR
            property={property}
            toggleCenter={toggle}
            uploadFileProperty={uploadFileProperty}
            analysisInput={analysisInput}
            assumptionsInput={assumptionsInput}
            renderInputFields={renderInputFields(shared)}
            shared={shared}
            canDrag={canDrag}
            activeUnit={activeUnit}
            setActiveUnit={setActiveUnit}
            compProperties={compProperties}
            compValues={compValues}
            determinedComps={determinedComps}
            lowestAverage={lowestAverage}
            publicDashboard={true}
            publicImageCard={publicImageCard(shared)}
          />
        )
      default:
        return null
    }
  }

  const renderStrategyPageWithImages = (property, shared) => {
    return (
      <Grid container spacing={2} style={{ padding: 25 }}>
        <Grid item xs={12} sm={6}>
          <Card className="card-box" style={{ height: '100%' }}>
            <div className="card-header d-flex align-items-center justify-content-between card-header-alt">
              <ButtonGroup variant="text">
                <Button
                  className={clsx(
                    'btn-outline-primary btn-transition-none',
                    { active: imageActiveTab === 'Images' },
                  )}
                  onClick={() => {
                    tabSwitch('Images')
                  }}
                >
                  <ImageIcon style={{ paddingRight: 5 }} />
                  Images
                </Button>
                <Button
                  className={clsx(
                    'btn-outline-primary btn-transition-none',
                    { active: imageActiveTab === 'Map' },
                  )}
                  onClick={() => {
                    tabSwitch('Map')
                  }}
                >
                  <RoomIcon style={{ paddingRight: 5 }} />
                  Map
                </Button>
                <Button
                  className={clsx(
                    'btn-outline-primary btn-transition-none',
                    { active: imageActiveTab === 'Street' },
                  )}
                  onClick={() => {
                    tabSwitch('Street')
                  }}
                >
                  <DirectionsWalkIcon style={{ paddingRight: 5 }} />
                  Street
                </Button>
                {property.videoWalkthrough && (
                  <Button
                    className={clsx(
                      'btn-outline-primary btn-transition-none',
                      { active: imageActiveTab === 'Video' },
                    )}
                    onClick={() => {
                      tabSwitch('Video')
                    }}
                  >
                    <FontAwesomeIcon
                      icon={['fas', 'video']}
                      style={{ paddingRight: 5 }}
                    />
                    Video Walkthrough
                  </Button>
                )}
              </ButtonGroup>
            </div>
            {imageActiveTab === 'Images' && (
              <div>
                <div>
                  <Slider
                    ref={slider}
                    className="card-img-top slider-dots"
                    {...widgetsCarousels1B}
                    style={{ maxWidth: 720 }}
                  >
                    {!isEmpty(compact(property.images))
                      ? map(
                          compact(property.images),
                          (image, index) => (
                            <div>
                              <Card
                                className="shadow-none"
                                style={{
                                  backgroundColor: 'white',
                                  margin: 1,
                                }}
                              >
                                <Grid
                                  container
                                  direction="column"
                                  justifyContent="center"
                                  style={{ height: 500 }}
                                >
                                  <Grid item>
                                    <div
                                      className="card-img-wrapper"
                                      style={{
                                        textAlign: 'center',
                                        backgroundColor:
                                          'transparent',
                                      }}
                                    >
                                      <div
                                        className="blur-load"
                                        style={{
                                          backgroundImage:
                                            'url(' +
                                            image.url_small +
                                            ')',
                                          height: 500,
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <img
                                          src={image.url_small}
                                          className="card-img-top rounded"
                                          style={{
                                            maxHeight: 500,
                                            height: 'auto',
                                            borderBottomRightRadius:
                                              '0px',
                                            objectFit: 'fill',
                                          }}
                                          alt="..."
                                          loading="lazy"
                                        />
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              </Card>
                            </div>
                          ),
                        )
                      : null}
                  </Slider>
                </div>
              </div>
            )}
            {imageActiveTab === 'Map' && (
              <Map
                properties={[property]}
                propertyPage
                streetView={false}
                setView={toggle}
                shared={shared}
              />
            )}
            {imageActiveTab === 'Street' && (
              <Map
                properties={[property]}
                propertyPage
                streetView={true}
                setView={toggle}
                shared={shared}
              />
            )}
            {imageActiveTab === 'Video' && (
              <div>
                <div>
                  <Card
                    className="shadow-none"
                    style={{ backgroundColor: 'white', margin: 1 }}
                  >
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      style={{ height: 500 }}
                    >
                      <Grid item>
                        <div
                          className="card-img-wrapper"
                          style={{
                            textAlign: 'center',
                            backgroundColor: 'transparent',
                          }}
                        >
                          <iframe
                            src={property.videoWalkthrough}
                            /*width="640"*/ height="500"
                            width="100%"
                            frameborder="0"
                            allow="autoplay; fullscreen"
                            allowfullscreen
                            style={{ maxWidth: 720 }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Card>
                </div>
              </div>
            )}
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card className="card-box" style={{ height: '100%' }}>
            <div className="card-header d-flex align-items-center justify-content-between card-header-alt">
              Property Description
            </div>
            <div>
              <Typography align="center">
                {property.description || 'No description included'}
              </Typography>
            </div>
          </Card>
        </Grid>
        {props.marketplace.preApprovedURL && (
          <Grid item xs={12} container justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              component="a"
              href={props.marketplace.preApprovedURL}
              target="_blank"
              style={{
                color: 'white',
                fontWeight: 700,
                padding: '0.5rem 1rem',
                fontSize: '1.25rem',
                lineHeight: '1.5',
                marginLeft: 5,
                alignContent: 'center',
              }}
            >
              Get Pre Approved
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          {renderStrategyPage(property, shared)}
        </Grid>
      </Grid>
    )
  }

  const renderCentralPage = (property, shared) => {
    switch (activeTab) {
      case 'Summary':
        return renderStrategyPage(property, shared)
      case 'Comps':
        return (
          <Comps
            organization={organization}
            property={property}
            toggleCenter={toggle}
            comps={compProperties}
            compValues={compValues}
            page={compPage}
            setPage={setCompPage}
            potentialPage={potentialCompPage}
            setPotentialPage={setPotentialCompPage}
            shared={shared}
            preferences={preferences}
            compsBackendLoading={compsBackendLoading}
          />
        )
      case 'RentalComps':
        return (
          <RentalComps
            organization={organization}
            property={property}
            toggleCenter={toggle}
            comps={rentalComps}
            compValues={compValues}
            page={rentalCompPage}
            setPage={setRentalCompPage}
            shared={shared}
            preferences={preferences}
            compsBackendLoading={compsBackendLoading}
          />
        )
      case 'Files':
        return (
          <ManageFiles
            property={property}
            toggleCenter={toggle}
            // handleUpdateMarket={handleUpdateMarket}
            shared={shared}
            public
            files={files}
            deleteFileFromList={deleteFileFromList}
            uploadFileOther={uploadFileOther}
          />
        )
      case 'MarketAnalysis':
        return (
          <MarketAnalysis
            property={property}
            toggleCenter={toggle}
            analysisInput={analysisInput}
            renderInputFields={renderInputFields(shared)}
            shared={shared}
          />
        )
      default:
        return null
    }
  }

  const [anchorElActionsMenu, setAnchorElActionsMenu] = useState(null)
  const handleClickActionsMenu = (event) => {
    setAnchorElActionsMenu(event.currentTarget)
  }
  const handleCloseActionsMenu = () => {
    setAnchorElActionsMenu(null)
  }
  const [anchorElLenderActionsMenu, setAnchorElLenderActionsMenu] =
    useState(null)
  const handleClickLenderActionsMenu = (event) => {
    setAnchorElLenderActionsMenu(event.currentTarget)
  }
  const handleCloseLenderActionsMenu = () => {
    setAnchorElLenderActionsMenu(null)
  }
  const [openActionsMenu, setOpenActionsMenu] = React.useState(false)
  const handleActionsClick = () => {
    setOpenActionsMenu(!openActionsMenu)
  }

  const [anchorElMoreOptionsMenu, setAnchorElMoreOptionsMenu] =
    useState(null)
  const handleClickMoreOptionsMenu = (event) => {
    setAnchorElMoreOptionsMenu(event.currentTarget)
  }
  const handleCloseMoreOptionsMenu = () => {
    setAnchorElMoreOptionsMenu(null)
  }
  const [anchorElMorePagesMenu, setAnchorElMorePagesMenu] =
    useState(null)
  const handleClickMorePagesMenu = (event) => {
    setAnchorElMorePagesMenu(event.currentTarget)
  }
  const handleCloseMorePagesMenu = () => {
    setAnchorElMorePagesMenu(null)
  }

  const [openFAQButtons, setOpenFAQButtons] = React.useState(false)
  const handleFAQButtonsClick = () => {
    setOpenFAQButtons(!openFAQButtons)
  }
  const [openTourButtons, setOpenTourButtons] = React.useState(false)
  const handleTourButtonsClick = () => {
    setOpenTourButtons(!openTourButtons)
  }
  const [openVideoButtons, setOpenVideoButtons] =
    React.useState(false)
  const handleVideoButtonsClick = () => {
    setOpenVideoButtons(!openVideoButtons)
  }

  const [loader, setLoader] = useState(false)

  const phpSend = (remove = false) => {
    if (remove) {
      let data = {
        _id: property._id,
        remove_property: remove,
      }

      const str_json = JSON.stringify(data)

      fetch(
        hostname === 'localhost'
          ? 'http://marketplace.housefolios.com/api/parserDev.php'
          : //? 'http://housefolios-marketplace.test/api/parserDev.php'
            hostname === 'dev-admin.housefolios.com'
            ? 'https://marketplace.housefolios.com/api/parserDev.php'
            : 'https://marketplace.housefolios.com/api/parser.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: str_json,
        },
      )
        .then(function (response) {
          if (response.status !== 200) {
            console.log(
              'Looks like there was a problem. Status Code: ' +
                response.status,
            )
            return
          }

          // Examine the text in the response
          response.json().then(function (data) {
            console.log(data)
          })
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
        })

      //  const request = new XMLHttpRequest()
      //  request.open(
      //    'POST',
      //    'https://marketplace.housefolios.com/api/parser.php',
      //    true,
      //  )
      //  request.setRequestHeader('Content-type', 'application/json')
      //  request.send(str_json)
    } else {
      let data = cloneDeep(property)
      let changeRelist = { changeRelist: true }
      let activeMP = []
      data.beds = sum(data.beds)
      data.baths = sum(data.baths)
      data.sqft = sum(data.sqft)
      if (get(data, 'acquisition.analysis')) {
        map(data.acquisition.analysis, (value, key) => {
          if (isArray(value)) {
            set(
              data,
              `acquisition.analysis.${key}`,
              sum(get(data, `acquisition.analysis.${key}`)),
            )
          }
        })
      }
      if (get(data, 'assumptions')) {
        map(data.assumptions, (value, key) => {
          if (isArray(value)) {
            set(
              data,
              `assumptions.${key}`,
              sum(get(data, `assumptions.${key}`)),
            )
          }
        })
      }
      if (get(data, 'acquisition.analytics')) {
        map(data.acquisition.analytics, (value, key) => {
          if (isArray(value)) {
            set(
              data,
              `acquisition.analytics.${key}`,
              sum(get(data, `acquisition.analytics.${key}`)),
            )
          } else if (isObject(value)) {
            map(value, (secondKey, value) => {
              if (isArray(value)) {
                set(
                  data,
                  `acquisition.analytics.${key}.${secondKey}`,
                  sum(
                    get(
                      data,
                      `acquisition.analytics.${key}.${secondKey}`,
                    ),
                  ),
                )
              }
            })
          }
        })
      }
      const { proformaType } = property.marketplaces
      const proformaLink = getProformaLink(data._id, [proformaType])

      data.proformaURL = proformaLink
      if (
        property.marketplaces &&
        !isEmpty(property.marketplaces.approvedMarketplace)
      ) {
        activeMP.push({
          marketplaces: {
            marketplaceName: property.marketplaces.marketplaceName,
            approvedMarketplace:
              property.marketplaces.approvedMarketplace,
            expirationDate: new Date(
              Date.now() + 7 * 24 * 60 * 60 * 1000,
            ),
            extraTags: property.marketplaces.extraTags,
            proformaType: proformaType,
          },
        })

        data = assign(data, activeMP[0], changeRelist)
      }

      const str_json = JSON.stringify(data)

      fetch(
        hostname === 'localhost'
          ? 'http://marketplace.housefolios.com/api/parserDev.php'
          : // ? 'http://housefolios-marketplace.test/api/parserDev.php'
            hostname === 'dev-admin.housefolios.com'
            ? 'https://marketplace.housefolios.com/api/parserDev.php'
            : 'https://marketplace.housefolios.com/api/parser.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: str_json,
        },
      )
        .then(function (response) {
          if (response.status !== 200) {
            console.log(
              'Looks like there was a problem. Status Code: ' +
                response.status,
            )
            return
          }

          // Examine the text in the response
          response.json().then(function (data) {
            console.log(data)
          })
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
        })

      //  const request = new XMLHttpRequest()
      //  request.open(
      //    'POST',
      //    'https://marketplace.housefolios.com/api/parser.php',
      //    true,
      //  )
      //  request.setRequestHeader('Content-type', 'application/json')
      //  request.send(str_json)
    }
    // setOpenUpdateMarkets(false)
  }

  const currencyFormater = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
  })

  const closingTime = new Date(get(offer, 'closing.closingDate'))
  const closingCountDown = get(offer, 'closing.closingDate')
    ? ceil(
        (closingTime.getTime() - today.getTime()) /
          24 /
          60 /
          60 /
          1000,
      )
    : null
  const dueDiligenceTime = new Date(
    get(offer, 'homeInspection.dateDueDiligence'),
  )
  const dueDiligenceCountDown = get(
    offer,
    'homeInspection.dateDueDiligence',
  )
    ? ceil(
        (dueDiligenceTime.getTime() - today.getTime()) /
          24 /
          60 /
          60 /
          1000,
      )
    : null

  const strategyCards = [
    {
      name: 'Buy & Hold',
      percentage:
        (analytics?.buyHold?.onTarget +
          analytics?.buyHold?.aboveTarget) /
        (analytics?.buyHold?.belowTarget +
          analytics?.buyHold?.onTarget +
          analytics?.buyHold?.aboveTarget),
      page: 'BuyHold',
      resellPrice: analysisInput?.resellPrice,
    },
    {
      name: 'Fix & Flip',
      percentage:
        (analytics?.fixFlip?.onTargetFlip +
          analytics?.fixFlip?.aboveTargetFlip) /
        (analytics?.fixFlip?.belowTargetFlip +
          analytics?.fixFlip?.onTargetFlip +
          analytics?.fixFlip?.aboveTargetFlip),
      page: 'FixFlip',
      resellPrice: analysisInput?.resellPriceFixFlip,
    },
    {
      name: 'Turnkey End Buyer',
      percentage:
        (analytics?.turnkey?.onTargetTurnkey +
          analytics?.turnkey?.aboveTargetTurnkey) /
        (analytics?.turnkey?.belowTargetTurnkey +
          analytics?.turnkey?.onTargetTurnkey +
          analytics?.turnkey?.aboveTargetTurnkey),
      page: 'Turnkey',
      resellPrice: analysisInput?.resellPriceTurnkey,
    },
    {
      name: 'BRRRR',
      percentage:
        (analytics?.BRRRR?.onTargetBRRRR +
          analytics?.BRRRR?.aboveTargetBRRRR) /
        (analytics?.BRRRR?.belowTargetBRRRR +
          analytics?.BRRRR?.onTargetBRRRR +
          analytics?.BRRRR?.aboveTargetBRRRR),
      page: 'BRRRR',
      resellPrice: analysisInput?.resellPriceBRRRR,
    },
    {
      name: 'Short Term Rental',
      percentage:
        (analytics?.STR?.onTargetSTR +
          analytics?.STR?.aboveTargetSTR) /
        (analytics?.STR?.belowTargetSTR +
          analytics?.STR?.onTargetSTR +
          analytics?.STR?.aboveTargetSTR),
      page: 'ShortTermRental',
      resellPrice: analysisInput?.resellPriceSTR,
    },
    {
      name: 'Wholesale',
      percentage:
        (analytics?.wholesale?.onTargetWholesale +
          analytics?.wholesale?.aboveTargetWholesale) /
        (analytics?.wholesale?.belowTargetWholesale +
          analytics?.wholesale?.onTargetWholesale +
          analytics?.wholesale?.aboveTargetWholesale),
      page: 'Wholesale',
      resellPrice: analysisInput?.resellPriceWholesale,
    },
    {
      name: 'Build to Rent',
      percentage:
        (analytics?.B2R?.onTargetB2R +
          analytics?.B2R?.aboveTargetB2R) /
        (analytics?.B2R?.belowTargetB2R +
          analytics?.B2R?.onTargetB2R +
          analytics?.B2R?.aboveTargetB2R),
      page: 'BuildToRent',
      resellPrice: analysisInput?.resellPriceB2R,
    },
    {
      name: 'BOR',
      percentage:
        (analytics?.BOR?.onTargetBOR +
          analytics?.BOR?.aboveTargetBOR) /
        (analytics?.BOR?.belowTargetBOR +
          analytics?.BOR?.onTargetBOR +
          analytics?.BOR?.aboveTargetBOR),
      page: 'BOR',
      resellPrice: analytics?.BOR?.arvTotalBOR,
    },
    {
      name: 'Astroflip',
      percentage:
        (analytics.astroFlip?.onTargetAstroFlip +
          analytics.astroFlip?.aboveTargetAstroFlip) /
        (analytics.astroFlip?.belowTargetAstroFlip +
          analytics.astroFlip?.onTargetAstroFlip +
          analytics.astroFlip?.aboveTargetAstroFlip),
      page: 'Astroflip',
    },
  ]

  const strategy =
    property.marketplaceStrategy || property.strategy || 'Buy & Hold'
  const strategyCard = find(
    strategyCards,
    (card) => card.name === strategy,
  )

  const activeTabStrategy =
    find(strategyCards, (card) => card.page === activeTab)?.name || ''

  const proformaLink = getProformaLink(
    property._id,
    [property.strategy, activeTabStrategy],
    true,
  )

  const [estimatesMenu] = useEstimatesMenu({
    property,
    determinedComps,
    activeUnit,
    assumptionsInput,
    onChangeAssumptions,
    anchorElEstimates,
    setAnchorElEstimates,
    handleDefinition,
    menu,
    setMenu,
    menuTarget,
    setMenuTarget,
    changeMenu,
    onChangeRange,
    estimatesCollapse,
    toggleCollapse,
    openNoData,
    setOpenNoData,
    affiliate,
    urlAffiliate,
  })

  if (
    propertyLoading ||
    offerLoading ||
    lotProcessesLoading ||
    !analysisInput
  )
    return 'Loading...'
  //if no property is found go to launchpad
  if (propertyError || isEmpty(property) || !property.portfolio) {
    navigate('/')
    if (propertyError) return propertyError.message
    return 'no property found'
  }
  if (compsError) return compsError.message
  //if (error) return error.message

  const strategies = !isEmpty(property.strategies)
    ? property.strategies
    : filterPreferedStrategies(
        preferences,
        props?.session?.me?.activeOrg?.member,
        true,
      )

  let propertyAccess =
    property.marketplaces &&
    includes(
      property.marketplaces.marketplaceName,
      props.marketplace ? props.marketplace.url : 'housefolios',
    ) &&
    includes(
      property.marketplaces.approvedMarketplace,
      props.marketplace ? props.marketplace.url : 'housefolios',
    )

  let shared = true
  let houseCanaryPropertyExplorerLink =
    property.houseCanaryPropertyExplorerLink || ''

  // const marketDisable =
  //   property.images.length >= 2 &&
  //   analysis.resellPrice &&
  //   sum(property.beds) > 0 &&
  //   sum(property.baths) > 0 &&
  //   sum(property.sqft) > 0 &&
  //   property.year &&
  //   property.state &&
  //   property.city

  if (propertyAccess)
    return (
      <Grid
        item
        xs={12}
        className="app-inner-content-layout"
        style={{ left: 0, right: 0 }}
      >
        <div
          className="app-inner-content-layout--main order-3 order-lg-2 card-box bg-secondary"
          style={{ overflow: 'scroll', height: '100vh' }}
        >
          {/* <PerfectScrollbar> */}
          {/* Error caused in this section */}
          <div
            className="card-header rounded-0 bg-white p-4 border-bottom sticky-header"
            style={{ top: '0px', zIndex: 500, textAlign: 'center' }}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  {hiddenSmDown ? null : (
                    <Grid item>
                      <div className="d-block p-0 avatar-icon-wrapper">
                        <div className="avatar-icon rounded d-flex justify-content-center align-items-center">
                          <div
                            className="blur-load"
                            style={{
                              backgroundImage:
                                'url(' + head(property.images)
                                  ? head(property.images).url_small
                                  : '/images/default_image.png' + ')',
                            }}
                          >
                            <img
                              src={
                                /*property.mainImage*/ head(
                                  property.images,
                                )
                                  ? head(property.images).url
                                  : '/images/default_image.png'
                              }
                              alt="..."
                            />
                          </div>
                        </div>
                      </div>
                    </Grid>
                  )}
                  <Grid item>
                    <Grid container direction="column">
                      <Grid item>
                        <Grid
                          container
                          alignItems="center"
                          spacing={2}
                        >
                          <Grid item>
                            <h5
                              className="card-title font-weight-bold"
                              style={{
                                fontSize: 26,
                                marginBottom: 0,
                              }}
                            >
                              {currencyFormater.format(
                                strategyCard.resellPrice,
                              ) || '-'}
                            </h5>
                          </Grid>
                          <Grid item>
                            <h5
                              className="card-title font-size-sm"
                              style={{ marginBottom: 0 }}
                            >
                              {sum(property.beds) || '-'} bd |{' '}
                              {sum(property.baths) || '-'} ba |{' '}
                              {formatter.format(sum(property.sqft)) ||
                                '-'}{' '}
                              sqft | {property.year || '-'} yr
                            </h5>
                          </Grid>
                        </Grid>
                      </Grid>

                      {hiddenSmUp ? null : (
                        <Grid item style={{ paddingBottom: 10 }}>
                          <Typography>
                            {!property.hideAddress
                              ? property.address + ', '
                              : ''}
                            {property.city} {property.state}{' '}
                            {property.zip}
                          </Typography>
                        </Grid>
                      )}

                      {hiddenSmDown ? null : (
                        <Grid item style={{ textAlign: 'left' }}>
                          <Typography>
                            {!property.hideAddress
                              ? property.address + ', '
                              : ''}
                            {property.city} {property.state}{' '}
                            {property.zip}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  {property.owned && activeTab === 'Summary' && (
                    <Grid item>
                      {map(summaryDate, (field) =>
                        renderInputFields(shared)(field),
                      )}
                    </Grid>
                  )}

                  {hiddenLgDown ? null : (
                    <>
                      <Grid item>
                        <Button
                          variant="contained"
                          color={
                            activeTab === 'Summary'
                              ? 'primary'
                              : 'grey'
                          }
                          onClick={() => toggle('Summary')}
                        >
                          <Typography>Property Analysis</Typography>
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color={
                            activeTab === 'MarketAnalysis'
                              ? 'primary'
                              : 'grey'
                          }
                          onClick={() => toggle('MarketAnalysis')}
                        >
                          <Typography>Neighborhood Info</Typography>
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color={
                            activeTab === 'Files' ? 'primary' : 'grey'
                          }
                          onClick={() => toggle('Files')}
                        >
                          <Typography>Documentation</Typography>
                        </Button>
                      </Grid>
                      {shared && (
                        <Grid item>
                          <Button
                            variant="contained"
                            href={proformaLink}
                            target="_blank"
                            color="grey"
                          >
                            <Typography>View Proforma</Typography>
                          </Button>
                        </Grid>
                      )}
                      {houseCanaryPropertyExplorerLink && (
                        <Grid item>
                          <Button
                            variant="contained"
                            href={houseCanaryPropertyExplorerLink}
                            target="_blank"
                            color="grey"
                          >
                            <Typography>
                              Property Explorer Report
                            </Typography>
                          </Button>
                        </Grid>
                      )}
                    </>
                  )}
                  <Grid item>
                    {property.lot &&
                      property.lot.status === 'approved' && (
                        <Button
                          component={Link}
                          to={`/${property.lot._id}/editLot`}
                          variant="contained"
                          color="primary"
                          style={{ marginRight: 10 }}
                        >
                          Connected Lot
                        </Button>
                      )}
                    {property.lot &&
                      property.lot.status !== 'approved' && (
                        <AddLot
                          propertyId={property.lot._id}
                          connected
                        />
                      )}

                    {!shared && (
                      <>
                        {/* {openUpdateMarkets && (
                          <Tooltip title="You've changed something - Update Marketplace">
                            <Button
                              variant="outlined"
                              color="primary"
                              style={{ marginRight: 15 }}
                              onClick={() => phpSend()}
                            >
                              <FontAwesomeIcon
                                icon={['far', 'sign-hanging']}
                                className="mr-2 font-size-xl"
                              />
                              <div
                                onClick={(event) =>
                                  event.stopPropagation()
                                }
                                style={{
                                  position: 'absolute',
                                  top: -10,
                                  right: -5,
                                }}
                              >
                                <IconButton
                                  size="small"
                                  style={{
                                    backgroundColor: 'black',
                                    color: 'white',
                                  }}
                                  onClick={() =>
                                    setOpenUpdateMarkets(false)
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={['fas', 'xmark']}
                                    className="font-size-sm"
                                  />
                                </IconButton>
                              </div>
                            </Button>
                          </Tooltip>
                        )} */}
                        <Button
                          data-tour="takeAction-step-1"
                          variant="contained"
                          color="primary"
                          onClick={handleClickActionsMenu}
                        >
                          <Typography>Actions</Typography>
                          <KeyboardArrowDownTwoToneIcon className="sidebar-item-label" />
                        </Button>
                      </>
                    )}
                    <Menu
                      anchorEl={anchorElActionsMenu}
                      keepMounted
                      open={Boolean(anchorElActionsMenu)}
                      onClose={handleCloseActionsMenu}
                      classes={{ list: 'p-0' }}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <div className="dropdown-menu-xl">
                        <List
                          data-tour="takeAction-step-2"
                          component="div"
                          className="nav-list-square nav-neutral-primary"
                        >
                          <ListItem
                            key="Proforma"
                            className="nav-item--header"
                          >
                            <span style={{ color: '#909497' }}>
                              Proforma Reports
                            </span>
                          </ListItem>
                          <ListItem
                            key="Report"
                            button
                            onClick={handleActionsClick}
                          >
                            <Grid
                              container
                              direction="row"
                              spacing={1}
                            >
                              <Grid item className="gridItem2">
                                <FontAwesomeIcon
                                  icon={['far', 'print']}
                                  className="font-size-lg"
                                  style={{ marginRight: 2 }}
                                />
                              </Grid>
                              <Grid item className="gridItem2">
                                <Typography>View Report</Typography>
                              </Grid>
                            </Grid>
                            <Typography style={{ flex: 1 }} />
                            {openActionsMenu ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </ListItem>
                          <Collapse
                            in={openActionsMenu}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              <ListItem
                                button
                                component="a"
                                href={proformaLink}
                                style={{ paddingLeft: 30 }}
                              >
                                <div className="nav-link-icon">
                                  <FontAwesomeIcon
                                    icon={['far', 'file-lines']}
                                  />
                                </div>
                                <span>Proforma</span>
                              </ListItem>
                              <PropertyPDF
                                property={property}
                                propertyId={property._id}
                                editProforma={false}
                                handleShareClose={
                                  handleCloseActionsMenu
                                }
                                CMA={true}
                                download={true}
                                comps={determinedComps}
                              />
                              {property.proformaFile && (
                                <PropertyPDF
                                  property={property}
                                  propertyId={property._id}
                                  editProforma={false}
                                  handleShareClose={
                                    handleCloseActionsMenu
                                  }
                                  uploaded={true}
                                  download={true}
                                />
                              )}
                              {/* <SharePDF
                                    property={property}
                                    propertyId={property._id}
                                    marketing={false}
                                    handleShareClose={handleCloseActionsMenu}
                                  /> */}
                            </List>
                          </Collapse>
                          <ListItem
                            key="zillow"
                            button
                            onClick={() =>
                              window.open(
                                `https://www.zillow.com/homes/${kebabCase(
                                  property.address,
                                )},-${property.state},-${
                                  property.zip
                                }_rb/`,
                                '_blank' /*'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400'*/,
                              )
                            }
                          >
                            <Grid
                              container
                              direction="row"
                              spacing={1}
                            >
                              <Grid item className="gridItem2">
                                <FontAwesomeIcon
                                  icon={[
                                    'far',
                                    'arrow-up-right-from-square',
                                  ]}
                                  className="font-size-lg"
                                  style={{ marginRight: 2 }}
                                />
                              </Grid>
                              <Grid item className="gridItem2">
                                <Typography>Open Zillow</Typography>
                              </Grid>
                            </Grid>
                          </ListItem>
                          <Divider />
                        </List>
                      </div>
                    </Menu>
                    <Grid item style={{ width: '100%' }}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        spacing={1}
                        wrap="nowrap"
                      >
                        {hiddenLgUp ? null : (
                          <Grid item className="gridItem2">
                            <Button
                              className="d-44 btn-first"
                              variant="contained"
                              onClick={handleClickMorePagesMenu}
                              style={{ marginLeft: 15 }}
                            >
                              <FontAwesomeIcon
                                icon={['far', 'ellipsis-vertical']}
                                className="font-size-lg"
                              />
                            </Button>
                            <Menu
                              anchorEl={anchorElMorePagesMenu}
                              keepMounted
                              open={Boolean(anchorElMorePagesMenu)}
                              onClose={handleCloseMorePagesMenu}
                              classes={{ list: 'p-0' }}
                              getContentAnchorEl={null}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                              }}
                            >
                              <div className="dropdown-menu-xl">
                                <List
                                  component="div"
                                  className="nav-list-square nav-neutral-primary"
                                >
                                  <ListItem
                                    button
                                    color={
                                      activeTab === 'Summary'
                                        ? 'primary'
                                        : 'grey'
                                    }
                                    onClick={() => toggle('Summary')}
                                  >
                                    <span>Property Analysis</span>
                                  </ListItem>
                                  <ListItem
                                    button
                                    color={
                                      activeTab === 'MarketAnalysis'
                                        ? 'primary'
                                        : 'grey'
                                    }
                                    onClick={() =>
                                      toggle('MarketAnalysis')
                                    }
                                  >
                                    <span>Neighborhod Info</span>
                                  </ListItem>
                                  <ListItem
                                    button
                                    color={
                                      activeTab === 'Files'
                                        ? 'primary'
                                        : 'grey'
                                    }
                                    onClick={() => toggle('Files')}
                                  >
                                    <span>Documentation</span>
                                  </ListItem>
                                  <ListItem
                                    button
                                    component="a"
                                    href={proformaLink}
                                    target="_blank"
                                  >
                                    <span>View Proforma</span>
                                  </ListItem>
                                </List>
                              </div>
                            </Menu>
                          </Grid>
                        )}
                        <Grid item className="gridItem2">
                          <Button
                            className="d-44 btn-warning"
                            variant="contained"
                            onClick={handleClickMoreOptionsMenu}
                            // style={{ marginLeft: 15 }}
                          >
                            <FontAwesomeIcon
                              icon={['far', 'circle-question']}
                              className="font-size-xl"
                              color="#fcfcfc"
                            />
                          </Button>
                          <Menu
                            anchorEl={anchorElMoreOptionsMenu}
                            keepMounted
                            open={Boolean(anchorElMoreOptionsMenu)}
                            onClose={handleCloseMoreOptionsMenu}
                            classes={{ list: 'p-0' }}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                          >
                            <div className="dropdown-menu-xl">
                              <List
                                component="div"
                                className="nav-list-square nav-neutral-primary"
                              >
                                <ListItem
                                  key="chat"
                                  button
                                  style={{ paddingLeft: 30 }}
                                  className="open_hubspoct nav-link-simple"
                                  onClick={() =>
                                    navigate('/support-chat')
                                  }
                                >
                                  <div className="nav-link-icon">
                                    <FontAwesomeIcon
                                      icon={['far', 'message-lines']}
                                    />
                                  </div>
                                  <span>Contact Us</span>
                                </ListItem>
                              </List>
                            </div>
                          </Menu>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          {/* <Dialog
              open={Boolean(showVideo)}
              onClose={closeVideo}
            >
              <iframe width="600" height="450" title="Demo Video"
                src={showVideo}>
              </iframe>
            </Dialog> */}
          {/* End of section */}
          {renderCentralPage(property, shared)}
          {estimatesMenu}

          {/* </PerfectScrollbar> */}
        </div>

        <Dialog
          open={openEnterActuals}
          onClose={handleCloseEnterActuals}
          fullScreen
        >
          <div className={classes.root}>
            <CssBaseline />
            <AppBar
              position="fixed"
              className={clsx(classes.appBar, {
                [classes.appBarShift]: openDrawer,
              })}
              color="grey"
            >
              <Toolbar>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  wrap="nowrap"
                  spacing={1}
                >
                  <Grid item className="gridItem2">
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={handleDrawerOpen}
                      style={{ marginRight: 0 }}
                      edge="start"
                      className={clsx(
                        classes.menuButton,
                        openDrawer && classes.hide,
                      )}
                      size="large"
                    >
                      <MenuIcon />
                    </IconButton>
                  </Grid>
                  {/* <Grid item className="gridItem2">
                     <Typography noWrap>
                       Actuals
                     </Typography>
                   </Grid> */}
                  <Grid item className="gridItem2">
                    {map(summaryDate, (field) =>
                      renderInputFields(shared)(field),
                    )}
                  </Grid>
                  <Typography style={{ flex: 1 }} />
                  <Grid
                    item
                    className="gridItem2"
                    onClick={handleCloseEnterActuals}
                  >
                    {hiddenLgUp ? null : (
                      <IconButton size="large">
                        <FontAwesomeIcon icon={['far', 'xmark']} />
                      </IconButton>
                    )}
                    {hiddenLgDown ? null : (
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={handleCloseEnterActuals}
                      >
                        Close
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
            <Drawer
              className={classes.drawer}
              variant="persistent"
              anchor="left"
              open={openDrawer}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <div className={classes.drawerHeader}>
                <Grid container direction="row" alignItems="center">
                  <Grid item className="gridItem2">
                    <Typography
                      variant="h6"
                      style={{ paddingLeft: 8 }}
                      noWrap
                    >
                      Enter Actuals
                    </Typography>
                  </Grid>
                  <Typography style={{ flex: 1 }} />
                  <Grid item className="gridItem2">
                    <IconButton
                      onClick={handleDrawerClose}
                      size="large"
                    >
                      {theme.direction === 'ltr' ? (
                        <ChevronLeftIcon />
                      ) : (
                        <ChevronRightIcon />
                      )}
                    </IconButton>
                  </Grid>
                </Grid>
              </div>
              <Divider />
              <List>
                {map(listItems, (section) => (
                  <ListItem
                    button
                    selected={tab === section.name}
                    onClick={() => {
                      setTab(section.name)
                      handleDrawerClose(false)
                    }}
                  >
                    {/* <ListItemIcon style={{ paddingLeft: 20 }}>
                       {section.icon}
                     </ListItemIcon> */}
                    <ListItemText>{section.name}</ListItemText>
                  </ListItem>
                ))}
              </List>
            </Drawer>
            <main
              className={clsx(classes.content, {
                [classes.contentShift]: openDrawer,
              })}
            >
              <div className={classes.drawerHeader} />
              {tab === 'Lease Information' && (
                <Grid
                  container
                  direction="column"
                  style={{ paddingTop: 20 }}
                >
                  <Grid item className="gridItem2">
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <Grid item className="gridItem2">
                        <Typography
                          variant="h5"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Lease Information
                        </Typography>
                      </Grid>
                      <Typography style={{ flex: 1 }} />
                      <Grid item className="gridItem2">
                        <IconButton
                          onClick={handleOpenLeaseInformation()}
                          size="large"
                        >
                          <FontAwesomeIcon icon={['far', 'plus']} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className="gridItem2">
                    <Grid container direction="row" spacing={2}>
                      {map(
                        assetManagement.lease || [],
                        (lease, index) => {
                          const start = new Date(lease.startDate)
                          const end = new Date(lease.endDate)
                          const after = endDate > start
                          const before = startDate < end
                          if (before && after)
                            return (
                              <Grid item xs={12} md={3}>
                                <Card
                                  onClick={
                                    handleOpenExpensesDescription
                                  }
                                >
                                  <CardHeader
                                    title={
                                      <Typography variant="h6">
                                        Lease
                                      </Typography>
                                    }
                                    // subheader={
                                    //   <Typography variant='subtitle2' style={{ marginTop: -5 }}>TJ Felman</Typography>
                                    // }
                                    action={
                                      <IconButton
                                        size="small"
                                        style={{ marginTop: 10 }}
                                        onClick={handleClickLeaseOptions(
                                          index,
                                        )}
                                      >
                                        <MoreVertIcon />
                                      </IconButton>
                                    }
                                    style={{ paddingBottom: 5 }}
                                  />
                                  <Divider />
                                  <CardContent>
                                    <Grid
                                      container
                                      direction="column"
                                    >
                                      <Grid item>
                                        <Grid
                                          container
                                          direction="row"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <Grid
                                            item
                                            className="gridItem2"
                                          >
                                            <Typography variant="subtitle1">
                                              Rent Amount
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            className="gridItem2"
                                          >
                                            <Typography variant="subtitle1">
                                              {currencyFormater.format(
                                                lease.rent,
                                              )}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item>
                                        <Grid
                                          container
                                          direction="row"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <Grid
                                            item
                                            className="gridItem2"
                                          >
                                            <Typography variant="subtitle1">
                                              Start Date
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            className="gridItem2"
                                          >
                                            <Typography variant="subtitle1">
                                              {formatDate(
                                                lease.startDate,
                                              )}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item>
                                        <Grid
                                          container
                                          direction="row"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <Grid
                                            item
                                            className="gridItem2"
                                          >
                                            <Typography variant="subtitle1">
                                              Terms
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            className="gridItem2"
                                          >
                                            <Typography variant="subtitle1">
                                              {formatDate(
                                                lease.endDate,
                                              )}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item>
                                        <FileUpload
                                          documentId={id}
                                          files={get(
                                            assetManagement,
                                            `lease.${index}.files`,
                                          )}
                                          uploadFile={uploadFileAsset(
                                            `lease.${index}.files`,
                                          )}
                                          // deleteFile={deleteFileAsset(
                                          //   'lease'
                                          // )}
                                          button
                                          notMulti={true}
                                          readOnly={true}
                                          collection="assetManagement"
                                        />
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                </Card>
                                <Menu
                                  id="simple-menu"
                                  anchorEl={anchorElLeaseOptions}
                                  keepMounted
                                  open={
                                    Boolean(anchorElLeaseOptions) &&
                                    addEditIndex === index
                                  }
                                  onClose={handleCloseLeaseOptions}
                                  onClick={handleCloseLeaseOptions}
                                  getContentAnchorEl={null}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }}
                                >
                                  <MenuItem
                                    component="a"
                                    button
                                    onClick={moveLease(index)}
                                  >
                                    <FontAwesomeIcon
                                      icon={['far', 'check']}
                                      className="font-size-lg"
                                      style={{ marginRight: 10 }}
                                    />
                                    Set as Active Lease
                                  </MenuItem>
                                  <MenuItem
                                    component="a"
                                    button
                                    onClick={handleOpenLeaseInformation(
                                      index,
                                    )}
                                  >
                                    <FontAwesomeIcon
                                      icon={['far', 'pen']}
                                      className="font-size-lg"
                                      style={{ marginRight: 10 }}
                                    />
                                    Edit Lease
                                  </MenuItem>
                                  <MenuItem
                                    component="a"
                                    button
                                    onClick={handleOpenDeleteAsset(
                                      'lease',
                                      index,
                                    )}
                                  >
                                    <FontAwesomeIcon
                                      icon={['far', 'xmark']}
                                      className="font-size-lg"
                                      style={{
                                        marginRight: 10,
                                        width: '1rem',
                                      }}
                                    />
                                    Delete Lease
                                  </MenuItem>
                                </Menu>
                              </Grid>
                            )
                        },
                      )}
                    </Grid>
                    <Grid
                      container
                      justifyContent="flex-end"
                      spacing={2}
                      wrap="nowrap"
                      style={{ marginTop: 15 }}
                    >
                      <Grid item className="gridItem2">
                        <Typography
                          variant="h5"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Status:
                        </Typography>
                      </Grid>
                      <Grid item className="gridItem2">
                        <Typography variant="h5">
                          {isEmpty(get(assetManagement, 'lease'))
                            ? 'For Rent'
                            : get(
                                  assetManagement,
                                  'lease.0.expirationDate',
                                ) &&
                                new Date(
                                  get(
                                    assetManagement,
                                    'lease.0.expirationDate',
                                  ),
                                ).getTime() < Date.now()
                              ? 'For Rent'
                              : get(
                                    assetManagement,
                                    'lease.0.endDate',
                                  ) &&
                                  new Date(
                                    get(
                                      assetManagement,
                                      'lease.0.endDate',
                                    ),
                                  ).getTime() > Date.now()
                                ? 'Leased'
                                : 'Month to Month'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {tab === 'Mortgage Payments' && (
                <Grid
                  container
                  direction="column"
                  style={{ paddingTop: 20 }}
                >
                  <Grid item className="gridItem2">
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item className="gridItem2">
                        <Typography
                          variant="h5"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Mortgage Payments
                        </Typography>
                      </Grid>
                      <Typography style={{ flex: 1 }} />
                      <Grid item className="gridItem2">
                        <IconButton
                          onClick={handleOpenRecordMortgagePayments()}
                          size="large"
                        >
                          <FontAwesomeIcon icon={['far', 'plus']} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className="gridItem2">
                    <Grid container direction="row" spacing={2}>
                      {map(
                        assetManagement.mortgage,
                        (mortgage, index) => {
                          const month = new Date(
                            mortgage.mortgageMonth,
                          )
                          if (startDate <= month && endDate >= month)
                            return (
                              <Grid item xs={12} md={3}>
                                <Card
                                  onClick={
                                    handleOpenExpensesDescription
                                  }
                                >
                                  <CardHeader
                                    title={
                                      <Typography variant="h6">
                                        {month.toLocaleString(
                                          'default',
                                          { month: 'long' },
                                        ) +
                                          ' ' +
                                          month.getFullYear()}
                                      </Typography>
                                    }
                                    action={
                                      <IconButton
                                        size="small"
                                        style={{ marginTop: 10 }}
                                        onClick={handleClickMortgageOptions(
                                          index,
                                        )}
                                      >
                                        <MoreVertIcon />
                                      </IconButton>
                                    }
                                    style={{ paddingBottom: 5 }}
                                  />
                                  <Divider />
                                  <CardContent>
                                    <Grid
                                      container
                                      direction="column"
                                    >
                                      <Grid item>
                                        <Grid
                                          container
                                          direction="row"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <Grid item>
                                            <Typography variant="subtitle1">
                                              Principal
                                            </Typography>
                                          </Grid>
                                          <Grid item>
                                            <Typography variant="subtitle1">
                                              {currencyFormater.format(
                                                mortgage.principal,
                                              )}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item>
                                        <Grid
                                          container
                                          direction="row"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <Grid item>
                                            <Typography variant="subtitle1">
                                              Interest
                                            </Typography>
                                          </Grid>
                                          <Grid item>
                                            <Typography variant="subtitle1">
                                              {currencyFormater.format(
                                                mortgage.interest,
                                              )}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item>
                                        <Grid
                                          container
                                          direction="row"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <Grid item>
                                            <Typography variant="subtitle1">
                                              Additional Principal
                                            </Typography>
                                          </Grid>
                                          <Grid item>
                                            <Typography variant="subtitle1">
                                              {currencyFormater.format(
                                                mortgage.additionalPrincipal,
                                              )}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                </Card>
                                <Menu
                                  id="simple-menu"
                                  anchorEl={anchorElMortgageOptions}
                                  keepMounted
                                  open={
                                    Boolean(
                                      anchorElMortgageOptions,
                                    ) && addEditIndex === index
                                  }
                                  onClose={handleCloseMortgageOptions}
                                  onClick={handleCloseMortgageOptions}
                                  getContentAnchorEl={null}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }}
                                >
                                  <MenuItem
                                    component="a"
                                    button
                                    onClick={handleOpenRecordMortgagePayments(
                                      index,
                                    )}
                                  >
                                    <FontAwesomeIcon
                                      icon={['far', 'pen']}
                                      className="font-size-lg"
                                      style={{ marginRight: 10 }}
                                    />
                                    Edit Payment
                                  </MenuItem>
                                  <MenuItem
                                    component="a"
                                    button
                                    onClick={handleOpenDeleteAsset(
                                      'mortgage',
                                      index,
                                    )}
                                  >
                                    <FontAwesomeIcon
                                      icon={['far', 'xmark']}
                                      className="font-size-lg"
                                      style={{
                                        marginRight: 10,
                                        width: '1rem',
                                      }}
                                    />
                                    Delete Payment
                                  </MenuItem>
                                </Menu>
                              </Grid>
                            )
                        },
                      )}
                    </Grid>
                    <Grid
                      container
                      justifyContent="flex-end"
                      spacing={2}
                      wrap="nowrap"
                      style={{ marginTop: 15 }}
                    >
                      <Grid item className="gridItem2">
                        <Typography
                          variant="h5"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Total Expenses
                        </Typography>
                      </Grid>
                      <Grid item className="gridItem2">
                        <Typography variant="h5">
                          {currencyFormater.format(
                            reduce(
                              assetManagement
                                ? filter(
                                    assetManagement.mortgage,
                                    (mortgage) => {
                                      const month = new Date(
                                        mortgage.mortgageMonth,
                                      )
                                      return (
                                        startDate <= month &&
                                        endDate >= month
                                      )
                                    },
                                  )
                                : [],
                              (sum, n) =>
                                sum +
                                n.principal +
                                n.interest +
                                n.additionalPrincipal,
                              0,
                            ),
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {tab === 'Current Equity' && (
                <Grid
                  container
                  direction="column"
                  style={{ paddingTop: 20 }}
                >
                  <Grid item className="gridItem2">
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item className="gridItem2">
                        <Typography
                          variant="h5"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Current Equity
                        </Typography>
                      </Grid>
                      <Typography style={{ flex: 1 }} />
                      {!get(
                        assetManagement,
                        'equity.afterRepairValue',
                      ) && (
                        <Grid item className="gridItem2">
                          <IconButton
                            onClick={handleOpenCurrentEquity}
                            size="large"
                          >
                            <FontAwesomeIcon icon={['far', 'plus']} />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item className="gridItem2">
                    <Grid container direction="row" spacing={2}>
                      {get(
                        assetManagement,
                        'equity.afterRepairValue',
                      ) && (
                        <Grid item xs={12} md={3}>
                          <Card
                            onClick={handleOpenExpensesDescription}
                          >
                            <CardHeader
                              title={
                                <Typography variant="h6">
                                  Current Equity
                                </Typography>
                              }
                              action={
                                <IconButton
                                  size="small"
                                  style={{ marginTop: 10 }}
                                  onClick={handleClickCurrentEquityOptions(
                                    null,
                                  )}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              }
                            />
                            <Divider />
                            <CardContent>
                              <Grid container direction="column">
                                <Grid item>
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Grid item className="gridItem2">
                                      <Typography variant="subtitle1">
                                        ARV
                                      </Typography>
                                    </Grid>
                                    <Grid item className="gridItem2">
                                      <Typography variant="subtitle1">
                                        {currencyFormater.format(
                                          assetManagement.equity
                                            .afterRepairValue,
                                        )}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item>
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Grid item className="gridItem2">
                                      <Typography variant="subtitle1">
                                        Current Loan Amount
                                      </Typography>
                                    </Grid>
                                    <Grid item className="gridItem2">
                                      <Typography variant="subtitle1">
                                        {currencyFormater.format(
                                          assetManagement.equity
                                            .currentLoanAmount,
                                        )}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                          <Menu
                            id="simple-menu"
                            anchorEl={anchorElCurrentEquityOptions}
                            keepMounted
                            open={anchorElCurrentEquityOptions}
                            onClose={handleCloseCurrentEquityOptions}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                          >
                            <MenuItem
                              component="a"
                              button
                              onClick={handleOpenCurrentEquity}
                            >
                              <FontAwesomeIcon
                                icon={['far', 'pen']}
                                className="font-size-lg"
                                style={{ marginRight: 10 }}
                              />
                              Edit Current Equity
                            </MenuItem>
                            <MenuItem
                              component="a"
                              button
                              onClick={handleOpenDeleteAsset(
                                'equity',
                              )}
                            >
                              <FontAwesomeIcon
                                icon={['far', 'xmark']}
                                className="font-size-lg"
                                style={{
                                  marginRight: 10,
                                  width: '1rem',
                                }}
                              />
                              Delete Current Equity
                            </MenuItem>
                          </Menu>
                        </Grid>
                      )}
                    </Grid>
                    <Grid
                      container
                      justifyContent="flex-end"
                      spacing={2}
                      wrap="nowrap"
                      style={{ marginTop: 15 }}
                    >
                      <Grid item className="gridItem2">
                        <Typography
                          variant="h5"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Total Equity
                        </Typography>
                      </Grid>
                      <Grid item className="gridItem2">
                        <Typography variant="h5">
                          {assetManagement.equity
                            ? currencyFormater.format(
                                assetManagement.equity
                                  .afterRepairValue -
                                  assetManagement.equity
                                    .currentLoanAmount,
                              )
                            : 'Missing Data'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {tab === 'Enter Income' && (
                <Grid
                  container
                  direction="column"
                  style={{ paddingTop: 20 }}
                >
                  <Grid item className="gridItem2">
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item className="gridItem2">
                        <Typography
                          variant="h5"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Enter Income
                        </Typography>
                      </Grid>
                      <Typography style={{ flex: 1 }} />
                      <Grid item className="gridItem2">
                        <IconButton
                          onClick={handleOpenReceiveRent()}
                          size="large"
                        >
                          <FontAwesomeIcon icon={['far', 'plus']} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className="gridItem2">
                    <Grid container direction="row" spacing={2}>
                      {map(
                        assetManagement.receiveRent,
                        (receiveRent, index) => {
                          const month = new Date(
                            receiveRent.rentPeriod,
                          )
                          if (startDate <= month && endDate >= month)
                            return (
                              <Grid item xs={12} md={3}>
                                <Card
                                  onClick={
                                    handleOpenExpensesDescription
                                  }
                                >
                                  <CardHeader
                                    title={
                                      <Typography variant="h6">
                                        {month.toLocaleString(
                                          'default',
                                          { month: 'long' },
                                        ) +
                                          ' ' +
                                          month.getFullYear()}
                                      </Typography>
                                    }
                                    action={
                                      <IconButton
                                        size="small"
                                        style={{ marginTop: 10 }}
                                        onClick={handleClickReceiveRentOptions(
                                          index,
                                        )}
                                      >
                                        <MoreVertIcon />
                                      </IconButton>
                                    }
                                    style={{ paddingBottom: 5 }}
                                  />
                                  <Divider />
                                  <CardContent>
                                    <Grid
                                      container
                                      direction="column"
                                    >
                                      <Grid item>
                                        <Grid
                                          container
                                          direction="row"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <Grid
                                            item
                                            className="gridItem2"
                                          >
                                            <Typography variant="subtitle1">
                                              Collected Amount
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            className="gridItem2"
                                          >
                                            <Typography variant="subtitle1">
                                              {currencyFormater.format(
                                                receiveRent.collectedAmount,
                                              )}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item>
                                        <Grid
                                          container
                                          direction="row"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <Grid
                                            item
                                            className="gridItem2"
                                          >
                                            <Typography variant="subtitle1">
                                              Applicable Month
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            className="gridItem2"
                                          >
                                            <Typography variant="subtitle1">
                                              {month.toLocaleString(
                                                'default',
                                                { month: 'long' },
                                              ) +
                                                ' ' +
                                                month.getFullYear()}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item>
                                        <Grid
                                          container
                                          direction="row"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <Grid
                                            item
                                            className="gridItem2"
                                          >
                                            <Typography variant="subtitle1">
                                              Payment Method
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            className="gridItem2"
                                          >
                                            <Typography variant="subtitle1">
                                              {
                                                receiveRent.paymentMethod
                                              }
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item>
                                        <Grid
                                          container
                                          direction="row"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <Grid
                                            item
                                            className="gridItem2"
                                          >
                                            <Typography variant="subtitle1">
                                              Collected Date
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            className="gridItem2"
                                          >
                                            <Typography variant="subtitle1">
                                              {formatDate(
                                                receiveRent.collectedDate,
                                              )}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item>
                                        <Grid
                                          container
                                          direction="row"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <Grid
                                            item
                                            className="gridItem2"
                                          >
                                            <Typography variant="subtitle1">
                                              Late Fee
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            className="gridItem2"
                                          >
                                            <Typography variant="subtitle1">
                                              {currencyFormater.format(
                                                receiveRent.lateFee,
                                              )}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item>
                                        <Grid
                                          container
                                          direction="row"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <Grid
                                            item
                                            className="gridItem2"
                                          >
                                            <Typography variant="subtitle1">
                                              Other Income
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            className="gridItem2"
                                          >
                                            <Typography variant="subtitle1">
                                              {currencyFormater.format(
                                                receiveRent.otherIncome,
                                              )}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                </Card>
                                <Menu
                                  id="simple-menu"
                                  anchorEl={
                                    anchorElReceiveRentOptions
                                  }
                                  keepMounted
                                  open={
                                    Boolean(
                                      anchorElReceiveRentOptions,
                                    ) && addEditIndex === index
                                  }
                                  onClose={
                                    handleCloseReceiveRentOptions
                                  }
                                  onClick={
                                    handleCloseReceiveRentOptions
                                  }
                                  getContentAnchorEl={null}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }}
                                >
                                  <MenuItem
                                    component="a"
                                    button
                                    onClick={handleOpenReceiveRent(
                                      index,
                                    )}
                                  >
                                    <FontAwesomeIcon
                                      icon={['far', 'pen']}
                                      className="font-size-lg"
                                      style={{ marginRight: 10 }}
                                    />
                                    Edit Income
                                  </MenuItem>
                                  <MenuItem
                                    component="a"
                                    button
                                    onClick={handleOpenDeleteAsset(
                                      'receiveRent',
                                      index,
                                    )}
                                  >
                                    <FontAwesomeIcon
                                      icon={['far', 'xmark']}
                                      className="font-size-lg"
                                      style={{
                                        marginRight: 10,
                                        width: '1rem',
                                      }}
                                    />
                                    Delete Income
                                  </MenuItem>
                                </Menu>
                              </Grid>
                            )
                        },
                      )}
                    </Grid>
                    <Grid
                      container
                      justifyContent="flex-end"
                      spacing={2}
                      wrap="nowrap"
                      style={{ marginTop: 15 }}
                    >
                      <Grid item className="gridItem2">
                        <Typography
                          variant="h5"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Total Income
                        </Typography>
                      </Grid>
                      <Grid item className="gridItem2">
                        <Typography variant="h5">
                          {currencyFormater.format(
                            reduce(
                              assetManagement
                                ? filter(
                                    assetManagement.receiveRent,
                                    (rent) => {
                                      const date = new Date(
                                        rent.rentPeriod,
                                      )
                                      return (
                                        startDate <= date &&
                                        endDate >= date
                                      )
                                    },
                                  )
                                : [],
                              (sum, n) =>
                                sum +
                                n.collectedAmount +
                                n.lateFee +
                                n.otherIncome,
                              0,
                            ),
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {tab === 'Expenses' && (
                <Grid
                  container
                  direction="column"
                  style={{ paddingTop: 20 }}
                >
                  <Grid item className="gridItem2">
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item className="gridItem2">
                        <Typography
                          variant="h5"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Record Expenses
                        </Typography>
                      </Grid>
                      <Typography style={{ flex: 1 }} />
                      <Grid item className="gridItem2">
                        <IconButton
                          onClick={handleOpenExpenses()}
                          size="large"
                        >
                          <FontAwesomeIcon icon={['far', 'plus']} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className="gridItem2">
                    <Grid container direction="row" spacing={2}>
                      {map(
                        assetManagement.expenses,
                        (expense, index) => {
                          const month = new Date(expense.expenseDate)
                          if (startDate <= month && endDate >= month)
                            return (
                              <Grid item xs={12} md={3}>
                                <Card
                                  onClick={handleOpenExpensesDescription(
                                    index,
                                  )}
                                >
                                  <CardHeader
                                    title={
                                      <Typography variant="h6">
                                        {expense.expenseType}
                                      </Typography>
                                    }
                                    subheader={
                                      <Typography
                                        variant="subtitle2"
                                        style={{ marginTop: -5 }}
                                      >
                                        {expense.updatedBy}
                                      </Typography>
                                    }
                                    action={
                                      <IconButton
                                        size="small"
                                        style={{ marginTop: 10 }}
                                        onClick={handleClickExpenseOptions(
                                          index,
                                        )}
                                      >
                                        <MoreVertIcon />
                                      </IconButton>
                                    }
                                    style={{ paddingBottom: 5 }}
                                  />
                                  <Divider />
                                  <CardContent>
                                    <Grid
                                      container
                                      direction="column"
                                      alignItems="center"
                                      justifyContent="flex-start"
                                    >
                                      <Grid
                                        item
                                        className="gridItem2"
                                      >
                                        <Typography variant="h5">
                                          {currencyFormater.format(
                                            expense.expenseAmount,
                                          )}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        className="gridItem2"
                                      >
                                        <Typography
                                          variant="subtitle2"
                                          style={{ marginTop: -5 }}
                                        >
                                          {formatDate(
                                            expense.expenseDate,
                                          )}
                                        </Typography>
                                      </Grid>
                                      <Collapse
                                        in={
                                          openExpensesDescription ===
                                          index
                                        }
                                        timeout="auto"
                                        unmountOnExit
                                      >
                                        <Grid
                                          item
                                          className="gridItem2"
                                          style={{ paddingTop: 10 }}
                                        >
                                          <Typography paragraph>
                                            {
                                              expense.expenseDescription
                                            }
                                          </Typography>
                                        </Grid>
                                      </Collapse>
                                    </Grid>
                                  </CardContent>
                                </Card>
                                <Menu
                                  id="simple-menu"
                                  anchorEl={anchorElExpenseOptions}
                                  keepMounted
                                  open={
                                    Boolean(anchorElExpenseOptions) &&
                                    addEditIndex === index
                                  }
                                  onClose={handleCloseExpenseOptions}
                                  onClick={handleCloseExpenseOptions}
                                  getContentAnchorEl={null}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }}
                                >
                                  <MenuItem
                                    component="a"
                                    button
                                    onClick={handleOpenExpenses(
                                      index,
                                    )}
                                  >
                                    <FontAwesomeIcon
                                      icon={['far', 'pen']}
                                      className="font-size-lg"
                                      style={{ marginRight: 10 }}
                                    />
                                    Edit Expense
                                  </MenuItem>
                                  <MenuItem
                                    component="a"
                                    button
                                    onClick={handleOpenDeleteAsset(
                                      'expenses',
                                      index,
                                    )}
                                  >
                                    <FontAwesomeIcon
                                      icon={['far', 'xmark']}
                                      className="font-size-lg"
                                      style={{
                                        marginRight: 10,
                                        width: '1rem',
                                      }}
                                    />
                                    Delete Expense
                                  </MenuItem>
                                </Menu>
                              </Grid>
                            )
                        },
                      )}
                    </Grid>
                    <Grid
                      container
                      justifyContent="flex-end"
                      spacing={2}
                      wrap="nowrap"
                      style={{ marginTop: 15 }}
                    >
                      <Grid item className="gridItem2">
                        <Typography
                          variant="h5"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Total Expenses
                        </Typography>
                      </Grid>
                      <Grid item className="gridItem2">
                        <Typography variant="h5">
                          {currencyFormater.format(
                            reduce(
                              assetManagement
                                ? filter(
                                    assetManagement.expenses,
                                    (expense) => {
                                      const date = new Date(
                                        expense.expenseDate,
                                      )
                                      return (
                                        startDate <= date &&
                                        endDate >= date
                                      )
                                    },
                                  )
                                : [],
                              (sum, n) => sum + n.expenseAmount,
                              0,
                            ),
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {tab === 'Enter Vacancy' && (
                <Grid
                  container
                  direction="column"
                  style={{ paddingTop: 20 }}
                >
                  <Grid item className="gridItem2">
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item className="gridItem2">
                        <Typography
                          variant="h5"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Enter Vacancy
                        </Typography>
                      </Grid>
                      <Typography style={{ flex: 1 }} />
                      <Grid item className="gridItem2">
                        <IconButton
                          onClick={handleOpenVacancy()}
                          size="large"
                        >
                          <FontAwesomeIcon icon={['far', 'plus']} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className="gridItem2">
                    <Grid container direction="row" spacing={2}>
                      {map(
                        assetManagement.vacancy || [],
                        (vacancy, index) => {
                          const start = new Date(vacancy.startDate)
                          const end = new Date(vacancy.endDate)
                          const after = endDate > start
                          const before = startDate < end
                          if (before && after)
                            return (
                              <Grid item xs={12} md={3}>
                                <Card
                                  onClick={
                                    handleOpenExpensesDescription
                                  }
                                >
                                  <CardHeader
                                    title={
                                      <Typography variant="h6">
                                        Vacant
                                      </Typography>
                                    }
                                    subheader={
                                      <Typography
                                        variant="subtitle2"
                                        style={{ marginTop: -5 }}
                                      >
                                        {start.toLocaleString(
                                          'default',
                                          { month: 'long' },
                                        ) +
                                          ' ' +
                                          start.getDate() +
                                          ', ' +
                                          start.getFullYear()}{' '}
                                        -{' '}
                                        {end.toLocaleString(
                                          'default',
                                          { month: 'long' },
                                        ) +
                                          ' ' +
                                          end.getDate() +
                                          ', ' +
                                          end.getFullYear()}
                                      </Typography>
                                    }
                                    action={
                                      <IconButton
                                        size="small"
                                        style={{ marginTop: 10 }}
                                        onClick={handleClickVacancyOptions(
                                          index,
                                        )}
                                      >
                                        <MoreVertIcon />
                                      </IconButton>
                                    }
                                    style={{ paddingBottom: 5 }}
                                  />
                                </Card>
                                <Menu
                                  id="simple-menu"
                                  anchorEl={anchorElVacancyOptions}
                                  keepMounted
                                  open={
                                    Boolean(anchorElVacancyOptions) &&
                                    addEditIndex === index
                                  }
                                  onClose={handleCloseVacancyOptions}
                                  onClick={handleCloseVacancyOptions}
                                  getContentAnchorEl={null}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }}
                                >
                                  <MenuItem
                                    component="a"
                                    button
                                    onClick={handleOpenVacancy(index)}
                                  >
                                    <FontAwesomeIcon
                                      icon={['far', 'pen']}
                                      className="font-size-lg"
                                      style={{ marginRight: 10 }}
                                    />
                                    Edit Vacancy
                                  </MenuItem>
                                  <MenuItem
                                    component="a"
                                    button
                                    onClick={handleOpenDeleteAsset(
                                      'vacancy',
                                      index,
                                    )}
                                  >
                                    <FontAwesomeIcon
                                      icon={['far', 'xmark']}
                                      className="font-size-lg"
                                      style={{
                                        marginRight: 10,
                                        width: '1rem',
                                      }}
                                    />
                                    Delete Vacancy
                                  </MenuItem>
                                </Menu>
                              </Grid>
                            )
                        },
                      )}
                    </Grid>
                    <Grid
                      container
                      justifyContent="flex-end"
                      spacing={2}
                      wrap="nowrap"
                      style={{ marginTop: 15 }}
                    >
                      <Grid item className="gridItem2">
                        <Typography
                          variant="h5"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Total Days Vacant
                        </Typography>
                      </Grid>
                      <Grid item className="gridItem2">
                        <Typography variant="h5">
                          {reduce(
                            assetManagement
                              ? filter(
                                  assetManagement.vacancy,
                                  (vacancy) => {
                                    const start = new Date(
                                      vacancy.startDate,
                                    )
                                    const end = new Date(
                                      vacancy.endDate,
                                    )
                                    const after = endDate > start
                                    const before = startDate < end
                                    return before && after
                                  },
                                )
                              : [],
                            (sum, n) => {
                              let end = new Date(n.endDate)
                              end.setHours(0)
                              end.setMinutes(0)
                              end.setSeconds(0)
                              end.setMilliseconds(0)
                              if (end > endDate)
                                end = new Date(
                                  endDate.getFullYear(),
                                  endDate.getMonth(),
                                  endDate.getDate(),
                                )
                              let start = new Date(n.startDate)
                              start.setHours(0)
                              start.setMinutes(0)
                              start.setSeconds(0)
                              start.setMilliseconds(0)
                              if (start < startDate)
                                start = new Date(
                                  startDate.getFullYear(),
                                  startDate.getMonth(),
                                  startDate.getDate(),
                                )
                              const time = end - start
                              return (
                                sum +
                                Math.ceil(
                                  time / 1000 / 60 / 60 / 24,
                                ) +
                                1
                              )
                            },
                            0,
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {tab === 'Distributions' && (
                <Grid
                  container
                  direction="column"
                  style={{ paddingTop: 20 }}
                >
                  <Grid item className="gridItem2">
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item className="gridItem2">
                        <Typography
                          variant="h5"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Distributions
                        </Typography>
                      </Grid>
                      <Typography style={{ flex: 1 }} />
                      <Grid ite className="gridItem2">
                        <IconButton
                          onClick={handleOpenDistributions()}
                          size="large"
                        >
                          <FontAwesomeIcon icon={['far', 'plus']} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className="gridItem2">
                    <Grid container direction="row" spacing={2}>
                      {map(
                        assetManagement.distributions,
                        (distribution, index) => {
                          const month = new Date(
                            distribution.distributionDate,
                          )
                          if (startDate <= month && endDate >= month)
                            return (
                              <Grid item xs={12} md={3}>
                                <Card
                                  onClick={handleOpenDistributionDescription(
                                    index,
                                  )}
                                >
                                  <CardHeader
                                    title={
                                      <Typography variant="h6">
                                        {month.toLocaleString(
                                          'default',
                                          { month: 'long' },
                                        ) +
                                          ' ' +
                                          month.getDate() +
                                          ', ' +
                                          month.getFullYear()}
                                      </Typography>
                                    }
                                    action={
                                      <IconButton
                                        size="small"
                                        style={{ marginTop: 10 }}
                                        onClick={handleClickDistributionOptions(
                                          index,
                                        )}
                                      >
                                        <MoreVertIcon />
                                      </IconButton>
                                    }
                                    style={{ paddingBottom: 5 }}
                                  />
                                  <Divider />
                                  <CardContent>
                                    <Grid
                                      container
                                      direction="column"
                                    >
                                      <Grid item>
                                        <Grid
                                          container
                                          direction="row"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <Grid
                                            item
                                            className="gridItem2"
                                          >
                                            <Typography variant="subtitle1">
                                              Amount
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            className="gridItem2"
                                          >
                                            <Typography variant="subtitle1">
                                              {currencyFormater.format(
                                                distribution.distributionAmount,
                                              )}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item>
                                        <Grid
                                          container
                                          direction="row"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <Grid
                                            item
                                            className="gridItem2"
                                          >
                                            <Typography variant="subtitle1">
                                              Distribution Payee
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            className="gridItem2"
                                          >
                                            <Typography variant="subtitle1">
                                              {
                                                distribution.distributionPayee
                                              }
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Collapse
                                        in={
                                          openDistributionDescription
                                        }
                                        timeout="auto"
                                        unmountOnExit
                                      >
                                        <Grid
                                          item
                                          style={{ paddingTop: 10 }}
                                        >
                                          <Typography paragraph>
                                            {
                                              distribution.distributionDescription
                                            }
                                          </Typography>
                                        </Grid>
                                      </Collapse>
                                    </Grid>
                                  </CardContent>
                                </Card>
                                <Menu
                                  id="simple-menu"
                                  anchorEl={
                                    anchorElDistributionOptions
                                  }
                                  keepMounted
                                  open={anchorElDistributionOptions}
                                  onClose={
                                    handleCloseDistributionOptions
                                  }
                                  getContentAnchorEl={null}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }}
                                >
                                  <MenuItem
                                    component="a"
                                    button
                                    onClick={handleOpenDistributions(
                                      index,
                                    )}
                                  >
                                    <FontAwesomeIcon
                                      icon={['far', 'pen']}
                                      className="font-size-lg"
                                      style={{ marginRight: 10 }}
                                    />
                                    Edit Distribution
                                  </MenuItem>
                                  <MenuItem
                                    component="a"
                                    button
                                    onClick={handleOpenDeleteAsset(
                                      'distributions',
                                      index,
                                    )}
                                  >
                                    <FontAwesomeIcon
                                      icon={['far', 'xmark']}
                                      className="font-size-lg"
                                      style={{
                                        marginRight: 10,
                                        width: '1rem',
                                      }}
                                    />
                                    Delete Distribution
                                  </MenuItem>
                                </Menu>
                              </Grid>
                            )
                        },
                      )}
                    </Grid>
                    <Grid
                      container
                      justifyContent="flex-end"
                      spacing={2}
                      wrap="nowrap"
                      style={{ marginTop: 15 }}
                    >
                      <Grid item className="gridItem2">
                        <Typography
                          variant="h5"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Total
                        </Typography>
                      </Grid>
                      <Grid item className="gridItem2">
                        <Typography variant="h5">
                          {currencyFormater.format(
                            sumBy(
                              filter(
                                assetManagement.distributions,
                                (distribution) => {
                                  const date = new Date(
                                    distribution.distributionDate,
                                  )
                                  return (
                                    startDate <= date &&
                                    endDate >= date
                                  )
                                },
                              ),
                              'distributionAmount',
                            ),
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {tab === 'Property Manager' && (
                <Grid
                  container
                  direction="column"
                  style={{ paddingTop: 20 }}
                >
                  <Grid item className="gridItem2">
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item className="gridItem2">
                        <Typography
                          variant="h5"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Property Manager
                        </Typography>
                      </Grid>
                      <Typography style={{ flex: 1 }} />
                      {!get(
                        assetManagement,
                        'manager.managerType',
                      ) && (
                        <Grid item className="gridItem2">
                          <IconButton
                            onClick={handleOpenPropertyManager}
                            size="large"
                          >
                            <FontAwesomeIcon icon={['far', 'plus']} />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item className="gridItem2">
                    <Grid container direction="row" spacing={2}>
                      {get(
                        assetManagement,
                        'manager.managerType',
                      ) && (
                        <Grid item xs={12} md={3}>
                          <Card
                            onClick={handleOpenExpensesDescription}
                          >
                            <CardHeader
                              title={
                                <Typography variant="h6">
                                  {assetManagement.manager
                                    .managerType === 'Self'
                                    ? 'Self managed'
                                    : assetManagement.manager
                                        .companyName}
                                </Typography>
                              }
                              action={
                                <IconButton
                                  size="small"
                                  style={{ marginTop: 10 }}
                                  onClick={handleClickPropertyManagerOptions(
                                    null,
                                  )}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              }
                              style={{ paddingBottom: 5 }}
                            />
                            <Divider />
                            <CardContent>
                              <Grid container direction="column">
                                <Grid item>
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Grid item className="gridItem2">
                                      <Typography variant="subtitle1">
                                        Property Manager
                                      </Typography>
                                    </Grid>
                                    <Grid item className="gridItem2">
                                      <Typography variant="subtitle1">
                                        {
                                          assetManagement.manager
                                            .managerType
                                        }
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item>
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Grid item className="gridItem2">
                                      <Typography variant="subtitle1">
                                        Date Hired
                                      </Typography>
                                    </Grid>
                                    <Grid item className="gridItem2">
                                      <Typography variant="subtitle1">
                                        {assetManagement.manager
                                          .managerType === 'Self'
                                          ? 'N/A'
                                          : formatDate(
                                              assetManagement.manager
                                                .dateHired,
                                            )}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                          <Menu
                            id="simple-menu"
                            anchorEl={anchorElPropertyManagerOptions}
                            keepMounted
                            open={anchorElPropertyManagerOptions}
                            onClose={
                              handleClosePropertyManagerOptions
                            }
                            getContentAnchorEl={null}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                          >
                            <MenuItem
                              component="a"
                              button
                              onClick={handleOpenPropertyManager}
                            >
                              <FontAwesomeIcon
                                icon={['far', 'pen']}
                                className="font-size-lg"
                                style={{ marginRight: 10 }}
                              />
                              Edit Property Manager
                            </MenuItem>
                            <MenuItem
                              component="a"
                              button
                              onClick={handleOpenDeleteAsset(
                                'manager',
                              )}
                            >
                              <FontAwesomeIcon
                                icon={['far', 'xmark']}
                                className="font-size-lg"
                                style={{
                                  marginRight: 10,
                                  width: '1rem',
                                }}
                              />
                              Delete Property Manager
                            </MenuItem>
                          </Menu>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {tab === 'Last Owner Statement' && (
                <Grid
                  container
                  direction="column"
                  style={{ paddingTop: 20 }}
                >
                  <Grid item className="gridItem2">
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item className="gridItem2">
                        <Typography
                          variant="h5"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Last Owner Statement
                        </Typography>
                      </Grid>
                      <Typography style={{ flex: 1 }} />
                      {!get(
                        assetManagement,
                        'lastOwner.lastOwnerStatement',
                      ) && (
                        <Grid item className="gridItem2">
                          <IconButton
                            onClick={handleOpenLastOwnerStatement}
                            size="large"
                          >
                            <FontAwesomeIcon icon={['far', 'plus']} />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item className="gridItem2">
                    <Grid container direction="row" spacing={2}>
                      {get(
                        assetManagement,
                        'lastOwner.lastOwnerStatement',
                      ) && (
                        <Grid item xs={12} md={3}>
                          <Card
                            onClick={handleOpenExpensesDescription}
                          >
                            <CardHeader
                              title={
                                <Typography variant="h6">
                                  {formatDate(
                                    assetManagement.lastOwner
                                      .lastOwnerStatement,
                                  )}
                                </Typography>
                              }
                              action={
                                <IconButton
                                  size="small"
                                  style={{ marginTop: 10 }}
                                  onClick={handleClickLastOwnerOptions(
                                    null,
                                  )}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              }
                              style={{ paddingBottom: 5 }}
                            />
                            <Divider />
                            <CardContent>
                              <Grid container direction="column">
                                <Grid item className="gridItem2">
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <FileUpload
                                      documentId={id}
                                      files={get(
                                        assetManagement,
                                        `lastOwner.files`,
                                      )}
                                      uploadFile={uploadFileAsset(
                                        'lastOwner',
                                      )}
                                      //  deleteFile={deleteFileAsset(
                                      //    'lastOwner'
                                      //  )}
                                      button
                                      notMulti={true}
                                      readOnly={true}
                                      collection="assetManagement"
                                    />
                                    {/* <Grid item>
                                       <Typography variant='subtitle1'>9/8/2021</Typography>
                                     </Grid> */}
                                    {/* <Grid item>
                                       <Typography variant='subtitle1'>89285.jpg</Typography>
                                     </Grid> */}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                          <Menu
                            id="simple-menu"
                            anchorEl={anchorElLastOwnerOptions}
                            keepMounted
                            open={anchorElLastOwnerOptions}
                            onClose={handleCloseLastOwnerOptions}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                          >
                            <MenuItem
                              component="a"
                              button
                              onClick={handleOpenLastOwnerStatement}
                            >
                              <FontAwesomeIcon
                                icon={['far', 'pen']}
                                className="font-size-lg"
                                style={{ marginRight: 10 }}
                              />
                              Edit Last Owner
                            </MenuItem>
                            <MenuItem
                              component="a"
                              button
                              onClick={handleOpenDeleteAsset(
                                'lastOwner',
                              )}
                            >
                              <FontAwesomeIcon
                                icon={['far', 'xmark']}
                                className="font-size-lg"
                                style={{
                                  marginRight: 10,
                                  width: '1rem',
                                }}
                              />
                              Delete Last Owner
                            </MenuItem>
                          </Menu>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </main>
          </div>
        </Dialog>

        {/* <Dialog
           open={openExpensesSummary}
           onClose={handleCloseExpensesSummary}
           fullScreen
         >
           <DialogTitle>
             <Grid container direction='row' alignItems='center' wrap='nowrap'>
               <Grid item>
                 <Typography variant='h5' style={{ whiteSpace: 'nowrap'}}>Record Expenses</Typography>
               </Grid>
               <Typography style={{ flex: 1 }} />
               {hiddenXsDown? null: 
                 <Grid item>
                   <Grid container alignItems='center' spacing={2}>
                     <Grid item>
                       <Button
                         size='small'
                         variant='outlined'
                         color='primary'
                         onClick={handleOpenExpenses}
                       >
                         <Typography>Add Expense</Typography>
                       </Button>
                     </Grid>
                   </Grid>
                 </Grid>
               }

               {
                hiddednSmUp? null: 

               
                 <Tooltip title='Add New Expense'>
                   <Grid item>
                     <IconButton onClick={handleOpenExpenses}>
                       <FontAwesomeIcon icon={[ 'far', 'plus' ]} />
                     </IconButton>
                   </Grid>
                 </Tooltip>
             </Grid>
           </DialogTitle>
           <DialogContent>
             <Grid container direction='row' justifyContent='center' alignItems='center' spacing={2}>
               <Grid item xs={12} md={4}>
                 <Card onClick={handleOpenExpensesDescription}>
                   <CardHeader
                     title={
                       <Typography variant='h6'>Countertops/Sinks/Toilets</Typography>
                     }
                     subheader={
                       <Typography variant='subtitle2' style={{ marginTop: -5 }}>TJ Felman</Typography>
                     }
                     action={
                       <IconButton
                         size='small'
                         style={{ marginTop: 10 }} 
                         onClick={handleClickExpenseOptions}
                       >
                         <MoreVertIcon />
                       </IconButton>
                     }
                     style={{ paddingBottom: 5 }}
                   />
                 
                   <Divider />
                   <CardContent>
                     <Grid container direction='column' alignItems='center' justifyContent='flex-start'>
                       <Grid item>
                         <Typography variant='h5'>$500.00</Typography>
                       </Grid>
                       <Grid item>
                         <Typography variant='subtitle2' style={{ marginTop: -5 }}>9/8/2021</Typography>
                       </Grid>
                       <Collapse in={openExpensesDescription} timeout="auto" unmountOnExit>
                         <Grid item style={{ paddingTop: 10 }}>
                           <Typography paragraph>
                             Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                           </Typography>
                         </Grid>
                       </Collapse>
                     </Grid>
                   </CardContent>
                 </Card>
                 <Menu
                     id="simple-menu"
                     anchorEl={anchorElExpenseOptions}
                     keepMounted
                     open={anchorElExpenseOptions}
                     onClose={handleCloseExpenseOptions}
                     anchorOrigin={{
                       vertical: 'bottom',
                       horizontal: 'center'
                     }}
                     transformOrigin={{
                       vertical: 'top',
                       horizontal: 'center'
                     }}
                   >
                     <MenuItem
                       component="a"
                       button
                     >
                       <FontAwesomeIcon
                         icon={['far', 'pen']}
                         className="font-size-lg"
                         style={{ marginRight: 10 }}
                       />
                       Edit Expense
                     </MenuItem>
                     <MenuItem
                       component="a"
                       button
                     >
                       <FontAwesomeIcon
                         icon={['far', 'xmark']}
                         className="font-size-lg"
                         style={{ marginRight: 10, width: '1rem' }}
                       />
                       Delete Expense
                     </MenuItem>
                   </Menu>
               </Grid>
             </Grid>
             <Grid container justifyContent='flex-end' spacing={2} wrap="nowrap" style={{ marginTop: 15 }}>
               <Grid item>
                 <Typography
                   variant='h5'
                   style={{ whiteSpace: 'nowrap' }}
                 >
                   Total Expenses
                 </Typography>
               </Grid>
               <Grid item>
                 <Typography variant='h5'>$2000</Typography>
               </Grid>
             </Grid>
           </DialogContent>
           <DialogActions>
             <Button variant="outlined" size="small" color="primary" onClick={handleCloseExpensesSummary}>Close</Button>
           </DialogActions>
         </Dialog> */}

        <Dialog
          open={openExpenses}
          onClose={handleCloseExpenses}
          maxWidth="md"
          fullWidth={true}
        >
          <DialogTitle>Record Expenses</DialogTitle>
          <DialogContent>
            {map(expenses, (field) =>
              renderInputFields(shared)(field),
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseExpenses}>Cancel</Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                !every(
                  expenses,
                  (field) => !field.required || !isNil(field.value),
                )
              }
              onClick={saveAssetManagementObject(handleCloseExpenses)}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openVacancy}
          onClose={handleCloseVacancy}
          maxWidth="md"
          fullWidth={true}
        >
          <DialogTitle>Enter Vacancy</DialogTitle>
          <DialogContent>
            {map(vacancy, (field) =>
              renderInputFields(shared)(field),
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseVacancy}>Cancel</Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                !every(
                  vacancy,
                  (field) => !field.required || !isNil(field.value),
                )
              }
              onClick={saveAssetManagementObject(handleCloseVacancy)}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openDistributions}
          onClose={handleCloseDistributions}
          maxWidth="md"
          fullWidth={true}
        >
          <DialogTitle>Record Distributions</DialogTitle>
          <DialogContent>
            {map(distribution, (field) =>
              renderInputFields(shared)(field),
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDistributions}>Cancel</Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                !every(
                  distribution,
                  (field) => !field.required || !isNil(field.value),
                )
              }
              onClick={saveAssetManagementObject(
                handleCloseDistributions,
              )}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openPropertyManager}
          onClose={handleClosePropertyManager}
          maxWidth="md"
          fullWidth={true}
        >
          <DialogTitle>Property Manager</DialogTitle>
          <DialogContent>
            {get(assetManagementInput, 'manager.managerType') ===
            'Self'
              ? renderInputFields(shared)(propertyManager[0])
              : map(propertyManager, (field) =>
                  renderInputFields(shared)(field),
                )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosePropertyManager}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                !every(
                  get(assetManagementInput, 'manager.managerType') ===
                    'Self'
                    ? [propertyManager[0]]
                    : propertyManager,
                  (field) => !field.required || !isNil(field.value),
                )
              }
              onClick={saveAssetManagementObject(
                handleClosePropertyManager,
              )}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openLastOwnerStatement}
          onClose={handleCloseLastOwnerStatement}
          maxWidth="md"
          fullWidth={true}
        >
          <DialogTitle>Last Owner Statement</DialogTitle>
          <DialogContent>
            {map(lastOwnerStatement, (field) =>
              renderInputFields(shared)(field),
            )}
            <FileUpload
              documentId={id}
              files={get(assetManagementInput, `lastOwner.files`)}
              uploadFile={uploadFileAsset('lastOwner')}
              deleteFile={deleteFileAsset('lastOwner')}
              button
              notMulti={true}
              collection="assetManagement"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseLastOwnerStatement}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                !every(
                  lastOwnerStatement,
                  (field) => !field.required || !isNil(field.value),
                )
              }
              onClick={saveAssetManagementObject(
                handleCloseLastOwnerStatement,
              )}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openDeleteAsset}
          onClose={handleCloseDeleteAsset}
          classes={{ paper: 'shadow-lg rounded' }}
        >
          <BlockUi
            blocking={loader}
            loader={
              <PacmanLoader color={'var(--primary)'} loading={true} />
            }
            message={
              <div className="text-primary">Deleting Asset Item</div>
            }
          >
            <div className="text-center p-5">
              <div className="avatar-icon-wrapper rounded-circle m-0">
                <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
                  <FontAwesomeIcon
                    icon={['far', 'trash-can']}
                    className="d-flex align-self-center display-3"
                  />
                </div>
              </div>
              <h4 className="font-weight-bold mt-4">
                Are you sure you want to delete this?
              </h4>
              <p className="mb-0 font-size-lg text-muted">
                <FormControl component="fieldset" className="pr-4">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={confirmDeleteAsset}
                          onChange={() =>
                            setConfirmDeleteAsset(!confirmDeleteAsset)
                          }
                        />
                      }
                      label="All data will be deleted. This action cannot be undone."
                    />
                  </FormGroup>
                </FormControl>
              </p>
              {/* <p className="mb-0 font-size-lg text-muted">
               All data will be deleted. This action cannot be undone.
             </p> */}
              <div className="pt-4">
                <Button
                  variant="text"
                  onClick={handleCloseDeleteAsset}
                  //className="btn-neutral-secondary btn-pill mx-1"
                >
                  <span className="btn-wrapper--label">Cancel</span>
                </Button>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: '#f83145',
                    color: 'white',
                  }}
                  onClick={saveAssetManagementObject(
                    handleCloseDeleteAsset,
                  )}
                  disabled={!confirmDeleteAsset}
                  //className="btn-danger btn-pill mx-1"
                >
                  <span className="btn-wrapper--label">Delete</span>
                </Button>
              </div>
            </div>
          </BlockUi>
        </Dialog>

        <Backdrop
          open={propertyLoader}
          style={{ color: '#fff', zIndex: 2000 }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignContent="center"
          >
            <Grid item>
              <CircularProgress
                color="inherit"
                style={{ marginLeft: 70 }}
              />
            </Grid>
            {/* <Grid item>
               <Typography>Please Wait</Typography>
             </Grid> */}
          </Grid>
        </Backdrop>
      </Grid>
    )
  else
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <Card>
            <CardContent>
              <div className="text-center p-5">
                <div className="avatar-icon-wrapper rounded-circle m-0">
                  <div
                    /*style={{ backgroundColor: '#b3d7ff', color: '#008aff' }}*/ className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper m-0 d-130 bg-neutral-danger text-danger"
                  >
                    <FontAwesomeIcon
                      icon={['far', 'folder-xmark']}
                      className="d-flex align-self-center display-2 "
                    />
                  </div>
                </div>
                <h4 className="font-weight-bold mt-4">We're Sorry</h4>
                <p className="mb-0 font-size-lg text-muted">
                  This property is not listed on a marketplace
                </p>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    )
}

export default graphql(GET_SIGNED_REQUEST, {
  name: 'getSignedRequest',
})(PublicPropertyDashboard)
