/**
 * @ Author: Housefolios
 * @ Create Time: 2021-12-01 15:08:58
 * @ Modified by: David Helmick
 * @ Modified time: 2024-08-07 12:04:35
 * @ Description: Layouts the page when looking at the proforma. The PropertyPDF component contains the code that actually displays the proforma.
 */

import React, { Component, useState } from 'react'
import { Query } from '@apollo/client/react/components'
import find from 'lodash/find'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import concat from 'lodash/concat'
import get from 'lodash/get'
import {
  FormControl,
  MenuItem,
  Grid,
  InputLabel,
  ListItemText,
  Select,
  List,
  ListItem,
  Collapse,
  Button,
  Menu,
  Typography,
} from '@mui/material'
import withSession from '@/housefolios-components/Session/withSession'
import { GET_COMPPROPERTIES, GET_PROPERTY } from '../queries'
import PropertyPDF from './pdf'
import { PageTitle } from '@/layout-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import KeyboardArrowDownTwoTone from '@mui/icons-material/KeyboardArrowDownTwoTone'
import { GET_PORTFOLIOS } from '@/housefolios-components/Portfolio/queries'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import Proforma from '.'
import SharePDF from './sharePDF'
import UploadProforma from '../UploadProforma'
import { NavLink, useNavigate } from 'react-router-dom'
import { DEFAULT_ANALYTICS } from '@/housefolios-components/Property/fragments'
import { getStrategyName } from '@/utils/stratagies'
import { useParams } from 'react-router-dom'

const PDFContainer = (props) => {
  const { id = '', type = 'buyHold' } = useParams()

  const [currentType, setCurrentType] = useState(type)
  const [anchorElActionsMenu, setAnchorElActionsMenu] = useState(null)
  const [openActionsMenu, setOpenActionsMenu] = useState(false)
  const [openEmailProforma, setOpenEmailProforma] = useState(false)

  let navigate = useNavigate()

  const onChangeContact = (event) => {
    const { value } = event.target
    setCurrentType(value)
    if (value) navigate(`/property-details/${id}/proforma/${value}`)
  }

  const handleClickActionsMenu = (event) => {
    setAnchorElActionsMenu(event.currentTarget)
  }
  const handleCloseActionsMenu = () => {
    setAnchorElActionsMenu(null)
  }
  const handleActionsClick = () => {
    setOpenActionsMenu(!openActionsMenu)
  }

  const handleEmailProforma = () => {
    setOpenEmailProforma(!openActionsMopenEmailProformaenu)
  }

  return (
    <Query query={GET_PROPERTY} variables={{ id }}>
      {({ loading, error, data }) => {
        if (loading) return 'Loading...'
        if (error) return `Error! ${error.message}`
        const { property } = data
        const { acquisition = {} } = property
        const { analytics = DEFAULT_ANALYTICS } = acquisition

        return (
          <>
            {includes(window.location.href, 'property-details') && (
              <Query query={GET_PORTFOLIOS}>
                {({ loading, error, data }) => {
                  if (loading) return 'Loading...'
                  if (error) return `Error! ${error.message}`
                  const { portfolios } = data
                  let propertyAccess = find(
                    concat(
                      portfolios,
                      filter(
                        props.session.me.activeOrg.sharedPortfolios,
                        (portfolio) =>
                          portfolio.sharePermission === 'canEdit',
                      ),
                    ),
                    (portfolio) =>
                      portfolio._id ===
                      get(property, 'portfolio._id'),
                  )

                  let shared = false
                  if (!propertyAccess) {
                    shared = true
                    propertyAccess = Boolean(
                      find(
                        props.session.me.activeOrg.sharedPortfolios,
                        (portfolio) =>
                          portfolio._id ===
                          get(property, 'portfolio._id'),
                      ) ||
                        find(
                          props.session.me.activeOrg.sharedProperties,
                          (propertyId) => propertyId === id,
                        ),
                    )
                  }
                  return (
                    <Query
                      query={GET_COMPPROPERTIES}
                      variables={{ propertyId: id }}
                    >
                      {({ loading, error, data }) => {
                        if (loading) return 'Loading...'
                        if (error) return `Error! ${error.message}`
                        const { compProperties = [] } = data
                        const determinedComps = filter(
                          compProperties,
                          (comp) => !comp.potential,
                        )
                        return (
                          <>
                            <PageTitle
                              titleHeading="Proforma"
                              titleDescription="Show off the property"
                              icon={
                                <FontAwesomeIcon
                                  style={{ fontSize: 30 }}
                                  icon={['far', 'file-lines']}
                                  className="text-primary"
                                />
                              }
                            >
                              {!shared && (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={handleClickActionsMenu}
                                  style={{
                                    marginRight: 5,
                                    paddingLeft: 35,
                                    paddingRight: 30,
                                  }}
                                >
                                  <Typography>Actions</Typography>
                                  <KeyboardArrowDownTwoTone className="sidebar-item-label" />
                                </Button>
                              )}
                              <Menu
                                anchorEl={anchorElActionsMenu}
                                keepMounted
                                open={Boolean(anchorElActionsMenu)}
                                onClose={handleCloseActionsMenu}
                                classes={{ list: 'p-0' }}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'center',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'center',
                                }}
                              >
                                <div className="dropdown-menu-xl">
                                  <List
                                    component="div"
                                    className="nav-list-square nav-neutral-primary"
                                  >
                                    {props.session.me.activeOrg
                                      .member !== 'RWN' && (
                                      <ListItem
                                        key="Report"
                                        button
                                        onClick={handleActionsClick}
                                      >
                                        <div className="nav-link-icon">
                                          <FontAwesomeIcon
                                            icon={['far', 'print']}
                                          />
                                        </div>
                                        <span>View Report</span>
                                        <Typography
                                          style={{ flex: 1 }}
                                        />
                                        {openActionsMenu ? (
                                          <ExpandLess />
                                        ) : (
                                          <ExpandMore />
                                        )}
                                      </ListItem>
                                    )}
                                    <Collapse
                                      in={openActionsMenu}
                                      timeout="auto"
                                      unmountOnExit
                                    >
                                      <List
                                        component="div"
                                        disablePadding
                                      >
                                        <ListItem
                                          button
                                          component="a"
                                          href={`/property-details/${property._id}/proforma/buyHold`}
                                          style={{
                                            paddingLeft: 30,
                                          }}
                                        >
                                          <div className="nav-link-icon">
                                            <FontAwesomeIcon
                                              icon={[
                                                'far',
                                                'file-lines',
                                              ]}
                                            />
                                          </div>
                                          <span>Proforma</span>
                                        </ListItem>
                                        <PropertyPDF
                                          property={property}
                                          propertyId={property._id}
                                          editProforma={false}
                                          handleShareClose={
                                            handleCloseActionsMenu
                                          }
                                          CMA={true}
                                          download={true}
                                          comps={determinedComps}
                                        />
                                        {property.proformaFile && (
                                          <PropertyPDF
                                            property={property}
                                            propertyId={property._id}
                                            editProforma={false}
                                            handleShareClose={
                                              handleCloseActionsMenu
                                            }
                                            uploaded={true}
                                            download={true}
                                          />
                                        )}
                                      </List>
                                    </Collapse>
                                    <Proforma
                                      property={property}
                                      images={property.images}
                                      propertyId={property._id}
                                      description={
                                        property.description
                                      }
                                      proformaDescription={
                                        property.proformaDescription
                                      }
                                      proformaImages={
                                        property.proformaImages
                                      }
                                      proformaImagePage={
                                        property.proformaImagePage
                                      }
                                      proformaLogo={
                                        property.proformaLogo
                                      }
                                      proformaRehabCost={
                                        property.proformaRehabCost
                                      }
                                      proformaHideAddress={
                                        property.proformaHideAddress
                                      }
                                      proformaContact={
                                        property.proformaContact
                                      }
                                      analytics={analytics}
                                      type={currentType}
                                    />
                                    <PropertyPDF
                                      property={property}
                                      propertyId={property._id}
                                      editProforma={false}
                                      handleShareClose={
                                        handleCloseActionsMenu
                                      }
                                      comps={determinedComps}
                                      type={type}
                                    />
                                    <SharePDF
                                      property={property}
                                      propertyId={property._id}
                                      handleShareClose={
                                        handleCloseActionsMenu
                                      }
                                      type={currentType}
                                    />
                                    <UploadProforma
                                      ActionsButton={true}
                                      proformaFile={
                                        property.proformaFile
                                      }
                                      propertyId={property._id}
                                    />
                                  </List>
                                </div>
                              </Menu>
                              {!shared && (
                                <FormControl
                                  variant="outlined"
                                  margin="dense"
                                  fullWidth={true}
                                >
                                  <InputLabel>
                                    {'Proforma type:'}
                                  </InputLabel>
                                  <Select
                                    name="type"
                                    className="form-control"
                                    value={currentType}
                                    onChange={(event) =>
                                      onChangeContact(event)
                                    }
                                    label="Proforma type:"
                                    inputProps={{
                                      name: 'type',
                                    }}
                                  >
                                    <MenuItem value={'buyHold'}>
                                      <ListItemText
                                        primary={getStrategyName(
                                          'Buy & Hold',
                                          props?.session?.me
                                            ?.activeOrg?.member,
                                        )}
                                      />
                                    </MenuItem>
                                    <MenuItem value={'fixFlip'}>
                                      <ListItemText
                                        primary={getStrategyName(
                                          'Fix & Flip',
                                          props?.session?.me
                                            ?.activeOrg?.member,
                                        )}
                                      />
                                    </MenuItem>
                                    <MenuItem value={'marketing'}>
                                      <ListItemText
                                        primary={
                                          props.session.me.activeOrg
                                            .southernImpressions
                                            ? getStrategyName(
                                                'Build to Rent',
                                                props?.session?.me
                                                  ?.activeOrg?.member,
                                              )
                                            : 'End Buyer'
                                        }
                                      />
                                    </MenuItem>
                                    <MenuItem
                                      value={'shortTermRental'}
                                    >
                                      <ListItemText
                                        primary={getStrategyName(
                                          'Short Term Rental',
                                          props?.session?.me
                                            ?.activeOrg?.member,
                                        )}
                                      />
                                    </MenuItem>
                                    {props?.session?.me?.activeOrg
                                      ?.member !== 'astroflip' && (
                                      <MenuItem value={'wholesale'}>
                                        <ListItemText
                                          primary={getStrategyName(
                                            'Wholesale',
                                            props?.session?.me
                                              ?.activeOrg?.member,
                                          )}
                                        />
                                      </MenuItem>
                                    )}
                                    {props?.session?.me?.activeOrg
                                      ?.member === 'astroflip' && (
                                      <MenuItem value={'astroflip'}>
                                        <ListItemText
                                          primary={getStrategyName(
                                            'Astroflip',
                                            props?.session?.me
                                              ?.activeOrg?.member,
                                          )}
                                        />
                                      </MenuItem>
                                    )}
                                  </Select>
                                </FormControl>
                              )}
                            </PageTitle>
                          </>
                        )
                      }}
                    </Query>
                  )
                }}
              </Query>
            )}
            <Grid
              container
              direction="column"
              alignItems="center"
              style={{ width: '100%' }}
            >
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                {!includes(
                  window.location.href,
                  'property-details',
                ) && (
                  <NavLink to="/signin">
                    <img
                      src="/images/NewLogoBlue.png"
                      alt="Housefolios"
                      style={{ height: 40 }}
                    />
                  </NavLink>
                )}
              </Grid>
              <Grid item xs={12} style={{ width: '100%' }}>
                <PropertyPDF
                  property={property}
                  propertyId={id}
                  type={currentType}
                  display={true}
                  uploaded={true}
                />
              </Grid>
            </Grid>
          </>
        )
      }}
    </Query>
  )
}

export default withSession(PDFContainer)
