/**
 * @ Author: Housefolios
 * @ Create Time: 2023-11-03 14:37:55
 * @ Modified by: David Helmick
 * @ Modified time: 2025-03-21 18:15:39
 * @ Description: Checks if awaitingRealeflowProperties boolean is true. If it is it sets it to false and refetches properties
 */

import React, { useEffect, useState } from 'react'

import head from 'lodash/head'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'
import find from 'lodash/find'
import orderBy from 'lodash/orderBy'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  useMutation,
  useQuery,
  useSubscription,
} from '@apollo/client'
import { GET_PORTFOLIOS } from '@/housefolios-components/Portfolio/queries'
import {
  GET_AWAITING_REALEFLOW_PROPERTIES,
  GET_UNPROCESSED_PROPERTY_LEADS,
} from './queries'
import { UPDATE_ORGANIZATION } from '@/housefolios-components/OrganizationSettings/mutations'
import { GET_ME } from '@/housefolios-components/Session/queries'
import {
  GET_PROPERTIES,
  GET_TRASH,
} from '@/housefolios-components/Properties/queries'
import { DELETE_PROPERTIES } from '@/housefolios-components/Properties/mutation'
import {
  Badge,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Grid2,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import {
  FolderOpenOutlined,
  Close as CloseIcon,
} from '@mui/icons-material'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import { GET_PROPERTIES_QUERY_LIMIT } from '@/utils/pagination'
import useImportRealeflowLeadsSubscription from '@/hooks/useImportRealeflowLeadsSubscription'
import { closeSnackbar, useSnackbar } from 'notistack'
import {
  LEADS_NOTIFICATION,
  NEW_REALEFLOW_IMPORT_SUBSCRIPTION,
} from './subscription'
import CreatePortfolio from '../Portfolio/CreatePortfolio'
import withSession from '../Session/withSession'
import { DELETE_BATCH, IMPORT_LEADS } from './mutation'
import { Stack } from '@mui/material'
import { height } from '@mui/system'
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark'

function RealeflowProperties(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [updating, setUpdating] = useState(false)

  const [selectedPortfolio, setSelectedPortfolio] = useState(
    props.portfolioId !== 'null'
      ? props.portfolioId
      : head(props.portfolios)._id,
  )
  const [selectedBatch, setSelectedBatch] = useState(null)
  const [openPortfolio, setOpenPortfolio] = useState(false)
  const [openBatch, setOpenBatch] = useState(false)
  const [openDuplicates, setOpenDuplicates] = useState(false)

  const handleOpenPortfolio = () => {
    setOpenPortfolio(true)
  }
  const handleClosePortfolio = () => {
    setOpenPortfolio(false)
  }

  const handleOpenBatch = () => {
    setOpenBatch(true)
  }
  const handleCloseBatch = () => {
    setOpenBatch(false)
  }

  const handleOpenDuplicates = () => {
    setOpenDuplicates(true)
  }
  const handleCloseDuplicates = () => {
    setOpenDuplicates(false)
  }

  const [confirm, setConfirm] = useState('true')
  const [loaderSnackKey, setLoaderSnackKey] = useState('dummyKey')

  const [newLead, setNewLead] = useState(false)

  let results = useQuery(GET_PORTFOLIOS)
  const { portfolios } = results.data || {}
  // console.log(portfolios)

  const {
    loading: loadingUnprocessedLeads,
    data: unprocessedLeads,
    error: unprocessedLeadsError,
    refetch: unprocessedLeadsRefetch,
  } = useQuery(GET_UNPROCESSED_PROPERTY_LEADS, {
    variables: {
      orgId: props.session.me.activeOrg._id,
    },
    // pollInterval: 1 * 60 * 1000,
    // pollInterval: updating ? 0 : 15 * 60 * 1000,
    // skip: updating,
  })
  const [
    importLeads,
    { loading: loadingImportLeads, error: importLeadsError },
  ] = useMutation(IMPORT_LEADS)

  const [
    deleteBatch,
    // { loading: loadingImportLeads, error: importLeadsError },
  ] = useMutation(DELETE_BATCH)

  const {
    loading: _,
    data: leadsNotificationData,
    error: leadsNoficationError,
  } = useSubscription(LEADS_NOTIFICATION, {
    variables: {
      orgId: props.session.me.activeOrg._id,
      userId: props.session.me._id,
    },
    // onData: (data) => {
    //   console.log('leads notification', data)
    // },
    // onComplete: () => {
    //   console.log('first')
    // },
  })

  // useEffect(() => {
  //   if (
  //     !loadingUnprocessedLeads &&
  //     unprocessedLeads?.getUnproccessedLeadsBatch?.success &&
  //     !isEmpty(unprocessedLeads?.getUnproccessedLeadsBatch?.batches)
  //   ) {
  //     setUpdating(true)
  //     setNewLead(false)
  //   }
  // }, [loadingUnprocessedLeads])

  const { loading, data, error } = useSubscription(
    NEW_REALEFLOW_IMPORT_SUBSCRIPTION,
    {
      variables: {
        orgId: props.session.me.activeOrg._id,
      },
    },
  )
  const { newLeadsFromRealeflow = {} } = data || {}

  const handleSubmit = () => {
    if (!selectedPortfolio) {
      enqueueSnackbar(
        'Choose a portfolio from the list',
        // leadsNotificationData?.getNewLeadsNotification.message,
        {
          variant: 'error',
          // autoHideDuration: null,
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
          action: (key) => (
            <>
              <IconButton
                onClick={() => closeSnackbar(key)}
                size="large"
              >
                <CloseIcon style={{ color: 'white' }} />
              </IconButton>
            </>
          ),
          // preventDuplicate: true,
        },
      )
    } else if (!selectedBatch) {
      enqueueSnackbar(
        'Choose a batch from the list',
        // leadsNotificationData?.getNewLeadsNotification.message,
        {
          variant: 'error',
          // autoHideDuration: null,
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
          action: (key) => (
            <>
              <IconButton
                onClick={() => closeSnackbar(key)}
                size="large"
              >
                <CloseIcon style={{ color: 'white' }} />
              </IconButton>
            </>
          ),
          // preventDuplicate: true,
        },
      )
    } else {
      importLeads({
        variables: {
          batchId: selectedBatch,
          orgId: props.session.me.activeOrg._id,
          portfolioId: selectedPortfolio,
          importDuplicates: confirm === 'true',
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_ME,
          },
          {
            query: GET_PORTFOLIOS,
          },
          {
            query: GET_PROPERTIES,
            variables: {
              portfolioId: [selectedPortfolio],
              limit: GET_PROPERTIES_QUERY_LIMIT,
            },
          },
          {
            query: GET_PROPERTIES,
            variables: {
              portfolioId: map(
                portfolios,
                (portfolio) => portfolio?._id,
              ),
              limit: GET_PROPERTIES_QUERY_LIMIT,
            },
          },
          {
            query: GET_AWAITING_REALEFLOW_PROPERTIES,
          },
          {
            query: GET_UNPROCESSED_PROPERTY_LEADS,
            variables: { orgId: props.session.me.activeOrg._id },
          },
        ],
      }).finally(() => {
        unprocessedLeadsRefetch()
      })
      setUpdating(false)
      setSelectedBatch(null)
    }
  }

  const handleDeleteBatch = () => {
    if (!selectedBatch) {
      enqueueSnackbar(
        'Choose a batch from the list',
        // leadsNotificationData?.getNewLeadsNotification.message,
        {
          variant: 'error',
          // autoHideDuration: null,
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
          action: (key) => (
            <>
              <IconButton
                onClick={() => closeSnackbar(key)}
                size="large"
              >
                <CloseIcon style={{ color: 'white' }} />
              </IconButton>
            </>
          ),
          // preventDuplicate: true,
        },
      )
    } else {
      deleteBatch({
        variables: {
          batchId: selectedBatch,
          orgId: props.session.me.activeOrg._id,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_ME,
          },
          {
            query: GET_AWAITING_REALEFLOW_PROPERTIES,
          },
          {
            query: GET_UNPROCESSED_PROPERTY_LEADS,
            variables: { orgId: props.session.me.activeOrg._id },
          },
        ],
      })
      setSelectedBatch(null)
    }
  }

  // useEffect(() => {
  //   // console.log('new leads', newLeadsFromRealeflow)
  //   if (newLeadsFromRealeflow?.awaitingRealeflow && !updating) {
  //     const newRealeflowDuplicated =
  //       newLeadsFromRealeflow.duplicateProperties

  //     setUpdating(true)
  //     setNewLead(false)
  //     setConfirm('false')
  //     if (!isEmpty(newRealeflowDuplicated)) {
  //       // setSelectedProperties(newRealeflow.duplicateProperties)
  //     } else {
  //       handleSubmit()
  //     }
  //   }
  // }, [newLeadsFromRealeflow])

  useEffect(() => {
    if (leadsNotificationData) {
      if (
        leadsNotificationData?.getNewLeadsNotification.notificationType?.toLowerCase() ===
        'info'
      ) {
        setNewLead(true)
        unprocessedLeadsRefetch().then(({ data }) => {
          if (!updating) {
            const batches = orderBy(
              data?.getUnproccessedLeadsBatch?.batches,
              ['createdAt'],
              ['desc'],
            )
            setUpdating(true)
            setSelectedBatch(head(batches)?._id ?? null)
          }
        })
        // setConfirm('false')
      } else {
        enqueueSnackbar(
          leadsNotificationData?.getNewLeadsNotification.message,
          {
            variant:
              leadsNotificationData?.getNewLeadsNotification.notificationType?.toLowerCase(),
            autoHideDuration: null,
            anchorOrigin: { horizontal: 'right', vertical: 'top' },
            action: (key) => (
              <>
                <IconButton
                  onClick={() => closeSnackbar(key)}
                  size="large"
                >
                  <CloseIcon style={{ color: 'white' }} />
                </IconButton>
              </>
            ),
            // preventDuplicate: true,
          },
        )

        if (
          includes(
            leadsNotificationData?.getNewLeadsNotification.message,
            'properties successfully',
          ) &&
          !includes(
            leadsNotificationData?.getNewLeadsNotification.message,
            'Processed comps',
          )
        ) {
          closeSnackbar(loaderSnackKey)
          let key = enqueueSnackbar(
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <CircularProgress color="inherit" size={25} />{' '}
              <span> Importing comps</span>
            </Stack>,
            {
              autoHideDuration: null,
              // anchorOrigin: { horizontal: 'right', vertical: 'top' },
              action: (key) => (
                <>
                  <IconButton
                    onClick={() => closeSnackbar(key)}
                    size="large"
                  >
                    <CloseIcon style={{ color: 'white' }} />
                  </IconButton>
                  {!loadingImportLeads && closeSnackbar(key)}
                </>
              ),
              preventDuplicate: true,
            },
          )
          setLoaderSnackKey(key)
        }
      }
    }

    // if(unprocessedLeads?.getUnproccessedLeadsBatch?.success){
    //   enqueueSnackbar(
    //   ""
    //     {
    //       variant:
    //       ,
    //       autoHideDuration: null,
    //       anchorOrigin: { horizontal: 'right', vertical: 'top' },
    //       action: (key) => (
    //         <>
    //           <IconButton onClick={() => closeSnackbar(key)}>
    //             <CloseIcon style={{ color: 'white' }} />
    //           </IconButton>
    //         </>
    //       ),
    //       // preventDuplicate: true,
    //     },
    //   )
    // }
  }, [leadsNotificationData])

  useEffect(() => {
    if (loadingImportLeads) {
      let key = enqueueSnackbar(
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <CircularProgress color="inherit" size={25} />{' '}
          <span> Importing leads</span>
        </Stack>,
        {
          autoHideDuration: null,
          // anchorOrigin: { horizontal: 'right', vertical: 'top' },
          action: (key) => (
            <>
              <IconButton
                onClick={() => closeSnackbar(key)}
                size="large"
              >
                <CloseIcon style={{ color: 'white' }} />
              </IconButton>
              {!loadingImportLeads && closeSnackbar(key)}
            </>
          ),
          preventDuplicate: true,
        },
      )
      setLoaderSnackKey(key)
    } else closeSnackbar(loaderSnackKey)
  }, [loadingImportLeads])

  const dateImported = leadsNotificationData?.getNewLeadsNotification
    .dateImported
    ? new Date(
        Number(
          leadsNotificationData?.getNewLeadsNotification.dateImported,
        ),
      ).toLocaleString()
    : 'N/A'

  const parseDate = (dateString) => {
    const date = new Date(dateString)
    return date.toLocaleString()
  }

  const displayedBatch = find(
    unprocessedLeads?.getUnproccessedLeadsBatch?.batches,
    (batch) => {
      return batch?._id === selectedBatch
    },
  )

  // console.log(dateImported)
  // console.log(props.session.me)
  return (
    <>
      <IconButton
        onClick={() => {
          setUpdating(true)
          setNewLead(false)
          setConfirm('true')
        }}
        // className={
        //   hostname === 'localhost' ||
        //   hostname === 'dev-admin.housefolios.com'
        //     ? 'mr-4'
        //     : ''
        // }
        size="large"
      >
        <Badge variant="dot" invisible={!newLead} color="error">
          <CollectionsBookmarkIcon />
        </Badge>
      </IconButton>

      <Dialog
        // open={updating && !isEmpty(duplicateProperties)}
        open={updating}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => {
          setUpdating(false)
          setSelectedBatch(null)
        }}
        classes={{ paper: 'shadow-lg rounded' }}
      >
        <DialogTitle>Leads to Portfolio</DialogTitle>
        <div className="text-center p-5">
          <Grid2 size={12}>
            <FormControl
              variant="outlined"
              margin="dense"
              style={{ width: '100%' }}
            >
              <InputLabel id="demo-simple-select-label">
                Select Batch
              </InputLabel>
              <Select
                variant="outlined"
                margin="dense"
                fullWidth
                //id=""
                label="Select Batch"
                value={selectedBatch}
                onChange={(event) =>
                  setSelectedBatch(event.target.value)
                }
                open={openBatch}
                onOpen={handleOpenBatch}
                onClose={handleCloseBatch}
                MenuProps={{ keepMounted: true }}
                // InputProps={{
                //   startAdornment: (
                //     <InputAdornment position="start">
                //       <FolderOpenOutlined />
                //     </InputAdornment>
                //   ),
                //   endAdornment: (
                //     <InputAdornment position="end"></InputAdornment>
                //   ),
                // }}
                // disabled={props?.session?.me?.activeOrg?.member === 'RWN'}
              >
                {map(
                  orderBy(
                    unprocessedLeads?.getUnproccessedLeadsBatch
                      ?.batches,
                    ['createdAt'],
                    ['desc'],
                  ),
                  (batch) => (
                    <MenuItem key={batch._id} value={batch._id}>
                      {parseDate(batch?.createdAt)}
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
          </Grid2>
          <Grid2 size={12} style={{ marginTop: 15 }}>
            <h6>My Leads</h6>
          </Grid2>
          <Grid2
            style={{
              height: 300,
              border: '1px solid grey',
              overflow: 'scroll',
            }}
          >
            {map(displayedBatch?.leads, (lead) => {
              // const leadProperty = lead?.SubjectProperty || lead
              // const {
              //   PropertyAddress = leadProperty.Address, // Fallback to Address
              //   PropertyCity = leadProperty.City, // Fallback to City
              //   PropertyState = leadProperty.State, // Fallback to State
              //   PropertyPostalCode = leadProperty.ZipCode, // Fallback to ZipCode
              // } = {
              //   PropertyAddress:
              //     leadProperty.PropertyAddress ||
              //     leadProperty.Address,
              //   PropertyCity:
              //     leadProperty.PropertyCity || leadProperty.City,
              //   PropertyState:
              //     leadProperty.PropertyState || leadProperty.State,
              //   PropertyPostalCode:
              //     leadProperty.PropertyPostalCode ||
              //     leadProperty.ZipCode,
              // }

              // const addr = `${PropertyAddress} ${PropertyCity}, ${PropertyState} ${PropertyPostalCode}`
              // return <Typography>{addr}</Typography>

              //Leads should now only have addresses included
              return <Typography>{lead.address}</Typography>
            })}
          </Grid2>
          <Grid2 size={12} style={{ marginTop: 15 }}>
            <h6>
              In which portfolio do we save the{' '}
              {displayedBatch?.leads?.length || ''} leads
            </h6>
          </Grid2>
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              margin="dense"
              style={{ width: '100%' }}
            >
              <InputLabel id="demo-simple-select-label">
                Select Portfolio
              </InputLabel>
              <Select
                variant="outlined"
                margin="dense"
                fullWidth
                //id=""
                select
                label="Select Portfolio"
                value={selectedPortfolio}
                onChange={(event) =>
                  setSelectedPortfolio(event.target.value)
                }
                open={openPortfolio}
                onOpen={handleOpenPortfolio}
                onClose={handleClosePortfolio}
                MenuProps={{ keepMounted: true }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FolderOpenOutlined />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end"></InputAdornment>
                  ),
                }}
                // disabled={props?.session?.me?.activeOrg?.member === 'RWN'}
              >
                {props?.session?.me?.activeOrg?.member === 'RWN' && (
                  <MenuItem
                    key="My Properties"
                    value={portfolios ? portfolios[0]._id : null}
                  >
                    My Properties
                  </MenuItem>
                )}
                {props?.session?.me?.activeOrg?.member !== 'RWN' &&
                  map(portfolios, (option) => (
                    <MenuItem key={option.name} value={option._id}>
                      {option.name}
                    </MenuItem>
                  ))}
                {props?.session?.me?.activeOrg?.member !== 'RWN' && (
                  <CreatePortfolio
                    dropdown={true}
                    setNewPortfolio={setSelectedPortfolio}
                    closeMenu={handleClosePortfolio}
                  />
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid2 size={12} style={{ marginTop: 15 }}>
            <h6>If you have one of these properties already:</h6>
          </Grid2>
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              margin="dense"
              style={{ width: '100%' }}
            >
              <InputLabel id="demo-simple-select-label">
                Duplicates
              </InputLabel>
              <Select
                variant="outlined"
                margin="dense"
                fullWidth
                label="Duplicates"
                value={confirm}
                onChange={(event) => setConfirm(event.target.value)}
                open={openDuplicates}
                onOpen={handleOpenDuplicates}
                onClose={handleCloseDuplicates}
                MenuProps={{ keepMounted: true }}
                // disabled={props?.session?.me?.activeOrg?.member === 'RWN'}
              >
                <MenuItem key="Save Duplicates" value={'true'}>
                  Save Duplicates
                </MenuItem>
                <MenuItem key="Skip Duplicates" value={'false'}>
                  Skip Duplicates
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <DialogActions>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleDeleteBatch()}
            >
              <span className="btn-wrapper--label">Delete</span>
            </Button>
            <Button
              variant="text"
              onClick={() => {
                setUpdating(false)
                setSelectedBatch(null)
              }}
            >
              <span className="btn-wrapper--label">Cancel</span>
            </Button>
            <Button
              variant="contained"
              color="primary"
              // style={{ backgroundColor: '#f83145', color: 'white' }}
              onClick={() => handleSubmit()}
              // disabled={!confirm}
            >
              <span className="btn-wrapper--label">Save</span>
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  )
}

export default withSession(RealeflowProperties)
