/**
 * @ Author: Housefolios
 * @ Create Time: 2022-06-28 11:54:04
 * @ Modified by: David Helmick
 * @ Modified time: 2025-03-04 16:12:39
 * @ Description: The render input function
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import clsx from 'clsx'
import map from 'lodash/map'
import includes from 'lodash/includes'
import find from 'lodash/find'
import cloneDeep from 'lodash/cloneDeep'
import pullAt from 'lodash/pullAt'
import concat from 'lodash/concat'
import isNil from 'lodash/isNil'
import filter from 'lodash/filter'

import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  Menu,
  MenuItem,
  Slider,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'

import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import FileUpload from '@/housefolios-components/PropertyContainer/PropertyDashboard/FileUpload'
import Autocomplete from '@mui/material/Autocomplete'
import RemoveOption from '@/housefolios-components/Dialogs/RemoveOption'
import AddOption from '@/housefolios-components/Dialogs/AddOption'
import { NumericFormat } from 'react-number-format'
import { replaceStrategyName } from '@/utils/stratagies'
import { RehabCalcInputField } from '@/utils/rehabCalc'

const currencyFormater = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

const formatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
})

const renderInput = (
  section,
  member,
  disabled,
  onChangeFunctions,
  dropdownProps,
) => {
  const {
    onChangeProperty,
    onChangeAnalysis,
    onChangeAssumptions,
    onChangeLot,
    onChangeLotProcess,
    onChangeAsset,
    onChangeOffer,
    onChangeExchange,
  } = onChangeFunctions
  const {
    showFormatDropdown,
    anchorElAssumptions,
    toggleAssumptionsMenuFormat,
    handleAssumptionsMenuClose,
    handleAdornmentChange,
    handleAnalysisAdornmentChange,
    handleDefinition,
  } = dropdownProps
  const marks = [
    {
      value: section.min,
      label: section.minLabel,
    },
    {
      value: section.max,
      label: section.maxLabel,
    },
  ]

  return section.display ? (
    <Grid
      item
      container
      xs={section.xs}
      sm={section.sm}
      md={section.md}
      lg={section.lg}
      xl={section.xl}
      justifyContent="space-between"
    >
      <Grid item>
        <Typography>
          {replaceStrategyName(section.label, member)}
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          {section.type === 'dollar'
            ? currencyFormater.format(section.value) || '-'
            : section.value || '-'}
          {section.type === 'percentage' ? '%' : ''}
        </Typography>
      </Grid>
    </Grid>
  ) : section.type === 'file' ? (
    <Grid
      item
      xs={section.xs}
      sm={section.sm}
      md={section.md}
      lg={section.lg}
      xl={section.xl}
    >
      <FileUpload
        documentId={section.documentId}
        files={section.value}
        uploadFile={section.uploadFile}
        deleteFile={section.deleteFile}
        button
        filetypes={section.filetypes}
        notMulti={!section.multiple}
        shared={disabled}
        collection={section.collection}
        buttonText={section.buttonText}
      />
    </Grid>
  ) : section.type === 'rehabCalc' ? (
    <Grid
      item
      xs={section.xs}
      sm={section.sm}
      md={section.md}
      lg={section.lg}
      xl={section.xl}
    >
      <RehabCalcInputField
        value={section.value}
        label={section.label}
        name={section.name}
        property={section.property}
        assumptionsInput={section.assumptionsInput}
        analysisInput={section.analysisInput}
        onChangeAnalysis={onChangeAnalysis}
        onChangeAssumptions={onChangeAssumptions}
        disabled={disabled}
        adornmentNumber={section.adornmentNumber}
      />
    </Grid>
  ) : section.type === 'budgetLines' ? (
    <Grid
      item
      xs={section.xs}
      sm={section.sm}
      md={section.md}
      lg={section.lg}
      xl={section.xl}
    >
      <Grid item alignItems="center">
        <Typography>
          {replaceStrategyName(section.label, member)}
        </Typography>
      </Grid>
      <Divider />
      <div
        style={{
          maxHeight: section?.showall ? '' : '230px',
          overflow: section?.showall ? '' : 'scroll',
        }}
      >
        {map(section.value, (item, index) => (
          <Grid
            container
            item
            xs={12}
            spacing={3}
            alignItems="center"
          >
            <Grid item xs={1}>
              <IconButton size="large">
                <FontAwesomeIcon
                  icon={['far', 'circle-xmark']}
                  className="font-size-md"
                  onClick={() => {
                    let newValues = cloneDeep(section.value || [])
                    pullAt(newValues, index)
                    const event = {
                      target: {
                        name: section.name,
                        value: newValues,
                        type: 'array',
                      },
                      preventDefault: () => {
                        return null
                      },
                    }
                    if (section.disabled || disabled) return null
                    if (section.lotProcess) onChangeLotProcess(event)
                    else if (section.offer) onChangeOffer(event)
                    else if (section.asset) onChangeAsset(event)
                    else if (section.assumptions)
                      onChangeAssumptions(event)
                    else if (section.lot) onChangeLot(event)
                    else if (section.details)
                      onChangeProperty(
                        event,
                        section.definition ? 'userInput' : null,
                      )
                    else
                      onChangeAnalysis(
                        event,
                        section.definition ? 'userInput' : null,
                      )
                  }}
                />
              </IconButton>
            </Grid>
            <Grid item xs={5}>
              <TextField
                name={section.name + `.${index}.type`}
                select={!section.disabled}
                fullWidth={true}
                variant="outlined"
                margin="dense"
                label="Type"
                value={
                  includes(
                    concat(section.memberOptions, section.options),
                    item.type,
                  ) ||
                  find(
                    concat(section.memberOptions, section.options),
                    { value: item.type },
                  )
                    ? item.type
                    : ''
                }
                disabled={disabled || section.disabled}
                required={section.required}
                onChange={(event) =>
                  section.onChange
                    ? section.onChange(event)
                    : section.lotProcess
                      ? onChangeLotProcess(event, true)
                      : section.offer
                        ? onChangeOffer(event, true)
                        : section.asset
                          ? onChangeAsset(event)
                          : section.assumptions
                            ? onChangeAssumptions(event, true)
                            : section.lot
                              ? onChangeLot(event, null, true)
                              : section.details
                                ? onChangeProperty(event, null, true)
                                : onChangeAnalysis(event, null, true)
                }
                SelectProps={{
                  renderValue: (x) => {
                    let option = find(
                      concat(section.memberOptions, section.options),
                      { value: x },
                    )
                    if (option) return option.label
                    else if (
                      includes(
                        concat(
                          section.memberOptions,
                          section.options,
                        ),
                        x,
                      )
                    )
                      return x
                    else return null
                  },
                }}
              >
                {map(section.memberOptions, (option) =>
                  option.value ? (
                    option.tip ? (
                      <Tooltip
                        title={option.tip}
                        key={option.value}
                        value={option.value}
                        followCursor
                      >
                        <MenuItem>
                          <Grid
                            container
                            justifyContent="space-between"
                          >
                            <Grid item>
                              <Typography>{option.title}</Typography>
                            </Grid>
                            <Grid item>
                              <Typography>{option.label}</Typography>
                            </Grid>
                          </Grid>
                        </MenuItem>
                      </Tooltip>
                    ) : (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                      >
                        <Grid container alignItems="center">
                          <Grid item>{option.label}</Grid>
                          <Typography style={{ flex: 1 }} />
                          <Typography>MO</Typography>
                        </Grid>
                      </MenuItem>
                    )
                  ) : (
                    <MenuItem key={option} value={option}>
                      <Grid container alignItems="center">
                        <Grid item>{option}</Grid>
                        <Typography style={{ flex: 1 }} />
                        <Typography>MO</Typography>
                      </Grid>
                    </MenuItem>
                  ),
                )}
                {map(section.options, (option) =>
                  option.value ? (
                    option.tip ? (
                      <Tooltip
                        title={option.tip}
                        key={option.value}
                        value={option.value}
                        followCursor
                      >
                        <MenuItem>
                          <Grid
                            container
                            justifyContent="space-between"
                          >
                            <Grid item>
                              <Typography>{option.title}</Typography>
                            </Grid>
                            <Grid item>
                              <Typography>{option.label}</Typography>
                            </Grid>
                            <Grid item>
                              <Typography>MO</Typography>
                            </Grid>
                          </Grid>
                        </MenuItem>
                      </Tooltip>
                    ) : (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                      >
                        <Grid container alignItems="center">
                          <Grid item>{option.label}</Grid>
                          <Typography style={{ flex: 1 }} />
                          {section.editOption &&
                            (option.active ||
                              isNil(option.active)) && (
                              <AddOption
                                currentValue={
                                  includes(
                                    section.options,
                                    item.type,
                                  ) ||
                                  find(section.options, {
                                    value: item.type,
                                  })
                                    ? item.type
                                    : ''
                                }
                                addOption={section.optionName}
                                currentOption={option}
                                edit
                                addOptionLabel={
                                  section.label + ' Type'
                                }
                                addOptionTarget={
                                  section.name + '.' + index + '.type'
                                }
                                onChangeAnalysis={onChangeAnalysis}
                                onChangeLotProcess={
                                  onChangeLotProcess
                                }
                                onChangeProperty={onChangeProperty}
                                onChangeOffer={onChangeOffer}
                                onChangeAssumptions={
                                  onChangeAssumptions
                                }
                              />
                            )}
                          {section.removeOption &&
                            (option.active ||
                              isNil(option.active)) && (
                              <RemoveOption
                                removeOption={section.optionName}
                                removeOptionLabel={
                                  section.label + ' Type'
                                }
                                removeOptionValue={option.value}
                                removeOptionItemLabel={option.label}
                              />
                            )}
                        </Grid>
                      </MenuItem>
                    )
                  ) : (
                    <MenuItem key={option} value={option}>
                      <Grid container alignItems="center">
                        <Grid item>{option}</Grid>
                        <Typography style={{ flex: 1 }} />
                        {section.editOption &&
                          (option.active || isNil(option.active)) && (
                            <AddOption
                              currentValue={
                                includes(
                                  section.options,
                                  item.type,
                                ) ||
                                find(section.options, {
                                  value: item.type,
                                })
                                  ? item.type
                                  : ''
                              }
                              addOption={section.optionName}
                              currentOption={option}
                              edit
                              addOptionLabel={section.label + ' Type'}
                              addOptionTarget={
                                section.name + '.' + index + '.type'
                              }
                              onChangeAnalysis={onChangeAnalysis}
                              onChangeLotProcess={onChangeLotProcess}
                              onChangeProperty={onChangeProperty}
                              onChangeOffer={onChangeOffer}
                              onChangeAssumptions={
                                onChangeAssumptions
                              }
                            />
                          )}
                        {section.removeOption &&
                          (option.active || isNil(option.active)) && (
                            <RemoveOption
                              removeOption={section.optionName}
                              removeOptionLabel={
                                section.label + ' Type'
                              }
                              removeOptionValue={option}
                            />
                          )}
                      </Grid>
                    </MenuItem>
                  ),
                )}
                {section.optionName && (
                  <AddOption
                    currentValue={
                      includes(section.options, item.type) ||
                      find(section.options, { value: item.type })
                        ? item.type
                        : ''
                    }
                    addOption={section.optionName}
                    addOptionLabel={section.label + ' Type'}
                    addOptionTarget={
                      section.name + '.' + index + '.type'
                    }
                    onChangeAnalysis={onChangeAnalysis}
                    onChangeLotProcess={onChangeLotProcess}
                    onChangeProperty={onChangeProperty}
                    onChangeOffer={onChangeOffer}
                    onChangeAssumptions={onChangeAssumptions}
                  />
                )}
              </TextField>
            </Grid>
            <Grid item xs={5}>
              <NumericFormat
                customInput={TextField}
                thousandSeparator={true}
                prefix={'$'}
                fullWidth={true}
                isNumericString={true}
                decimalScale={0}
                key={section.name}
                name={section.name + `.${index}.value`}
                value={Math.round(item.value) || ''}
                disabled={section.disabled || disabled}
                onValueChange={(values) => {
                  const { value } = values
                  const event = {
                    target: {
                      name: section.name + `.${index}.value`,
                      value: value,
                      type: 'number',
                    },
                    preventDefault: () => {
                      return null
                    },
                  }
                  if (section.disabled || disabled) return null
                  if (section.lotProcess) onChangeLotProcess(event)
                  else if (section.offer) onChangeOffer(event)
                  else if (section.asset) onChangeAsset(event)
                  else if (section.assumptions)
                    onChangeAssumptions(event)
                  else if (section.lot) onChangeLot(event)
                  else if (section.details)
                    onChangeProperty(
                      event,
                      section.definition ? 'userInput' : null,
                    )
                  else
                    onChangeAnalysis(
                      event,
                      section.definition ? 'userInput' : null,
                    )
                  //onChange(event, saveAnalysis)
                }}
                type={'text'}
                onFocus={(event) => {
                  event.target.select()
                }}
                variant="outlined"
                margin="dense"
                label="Amount"
                fontSize={section.font}
                required={section.required}
                helperText={
                  <Typography
                    color="primary"
                    variant="caption"
                    style={
                      section.helperStyle ? section.helperStyle : null
                    }
                  >
                    {section.helper}
                  </Typography>
                }
                InputProps={{
                  startAdornment: section.adornmentNumber ? (
                    <InputAdornment
                      position="start"
                      sx={{ marginRight: 0.25 }}
                    >
                      <Typography
                        sx={{
                          width: 20, // Smaller size
                          height: 20,
                          borderRadius: '50%',
                          backgroundColor: 'black', // Black background
                          color: 'white', // White text
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontWeight: 'bold',
                          fontSize: '0.75rem', // Smaller text
                          padding: 0, // Remove padding
                          textAlign: 'center', // Ensure centered alignment
                          marginLeft: '-6px', // Adjust spacing on the left
                        }}
                      >
                        {section.adornmentNumber}
                      </Typography>
                    </InputAdornment>
                  ) : null,
                  endAdornment: section.definition ? (
                    <InputAdornment position="end">
                      <Tooltip
                        title={section.tooltipButtonText || ''}
                        followCursor
                      >
                        <IconButton
                          onClick={(event) =>
                            section.disabled || disabled
                              ? null
                              : handleDefinition(
                                  section.definition,
                                  event.target,
                                  section.definitionTarget,
                                )
                          }
                          className="d-30 rounded-sm border-0 mx-auto card-icon-wrapper bg-first text-white btn-icon text-center"
                          size="large"
                        >
                          <FontAwesomeIcon
                            icon={['fal', 'database']}
                            className="font-size-xl"
                          />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ) : null,
                }}
              />
            </Grid>
          </Grid>
        ))}
      </div>
      <Grid item xs={12}>
        <Typography style={{ flex: 1 }} />
        <Typography
          variant="subtitle2"
          onClick={() => {
            let newValues = cloneDeep(section.value || [])
            newValues.push({
              type: '',
              value: 0,
            })
            const event = {
              target: {
                name: section.name,
                value: newValues,
                type: 'array',
              },
              preventDefault: () => {
                return null
              },
            }
            if (section.disabled || disabled) return null
            if (section.lotProcess) onChangeLotProcess(event)
            else if (section.offer) onChangeOffer(event)
            else if (section.asset) onChangeAsset(event)
            else if (section.assumptions) onChangeAssumptions(event)
            else if (section.lot) onChangeLot(event)
            else if (section.details)
              onChangeProperty(
                event,
                section.definition ? 'userInput' : null,
              )
            else
              onChangeAnalysis(
                event,
                section.definition ? 'userInput' : null,
              )
          }}
        >
          <FontAwesomeIcon
            icon={['far', 'plus']}
            className="font-size-sm mr-1"
          />
          Add Line Item
        </Typography>
      </Grid>
      <Divider />
    </Grid>
  ) : section.type === 'slider' ? (
    <Grid
      item
      xs={section.xs}
      sm={section.sm}
      md={section.md}
      lg={section.lg}
      xl={section.xl}
      style={{ marginLeft: 10, marginRight: 10 }}
      data-tour={section.dataTour}
    >
      <Typography
        style={{ color: '#9198a8', fontSize: 14, marginTop: 5 }}
      >
        {replaceStrategyName(section.label, member)}
      </Typography>
      <Slider
        className="slider-primary"
        track="inverted"
        valueLabelDisplay="auto"
        //getAriaValueText={section.label}
        onChange={(e, value) => {
          const event = {
            target: {
              name: section.name,
              value: value,
              type: 'number',
            },
            preventDefault: () => {
              return null
            },
          }
          section.lotProcess
            ? onChangeLotProcess(event)
            : section.offer
              ? onChangeOffer(event)
              : section.asset
                ? onChangeAsset(event)
                : section.assumptions
                  ? onChangeAssumptions(event)
                  : section.lot
                    ? onChangeLot(event)
                    : section.details
                      ? onChangeProperty(event)
                      : onChangeAnalysis(event)
        }}
        onChangeCommitted={(e, value) => {
          const event = {
            target: {
              name: section.name,
              value: value,
              type: 'number',
            },
            preventDefault: () => {
              return null
            },
          }
          section.lotProcess
            ? onChangeLotProcess(event, true)
            : section.offer
              ? onChangeOffer(event, true)
              : section.asset
                ? onChangeAsset(event)
                : section.assumptions
                  ? onChangeAssumptions(event, true)
                  : section.lot
                    ? onChangeLot(event)
                    : section.details
                      ? onChangeProperty(event, null, true)
                      : onChangeAnalysis(event, null, true)
        }}
        value={Number(section.value)}
        min={section.min}
        max={section.max}
        step={section.step ? section.step : 1}
        valueLabelFormat={
          section.valueLabelFormat
            ? section.valueLabelFormat
            : (x) => (x === section.max ? `${x}+` : `${x}%`)
        }
        marks={marks}
        disabled={section.disabled || disabled}
      />
    </Grid>
  ) : section.type === 'reactQuill' ? (
    <Grid
      item
      xs={section.xs}
      sm={section.sm}
      md={section.md}
      lg={section.lg}
      xl={section.xl}
    >
      <TextField
        name={section.name}
        variant="outlined"
        margin="dense"
        value={section.value}
        onChange={(event) =>
          section.onChange
            ? section.onChange(event)
            : section.lotProcess
              ? onChangeLotProcess(event)
              : section.offer
                ? onChangeOffer(event)
                : section.asset
                  ? onChangeAsset(event)
                  : section.assumptions
                    ? onChangeAssumptions(event)
                    : section.lot
                      ? onChangeLot(event)
                      : section.details
                        ? onChangeProperty(event)
                        : onChangeAnalysis(event)
        }
        placeholder={replaceStrategyName(section.label, member)}
        label={replaceStrategyName(section.label, member)}
        required={section.required}
        type="text"
        fullWidth={true}
        disabled={section.disabled || disabled}
        multiline
        rows={10}
      />
      {/* <ReactQuill
          theme="snow"
          defaultValue={section.value}
          onChange={(value) => {
            const regex = /<([^>]+)>/ig
            const event = {
              target: {
                name: section.name,
                value: replace(value, regex, ''),
                type: 'text',
              }
            }
            section.onChange ? section.onChange(event) : section.assumptions ? onChangeAssumptions(event) : section.lot ? onChangeLot(event) : section.details ? onChangeProperty(event) : onChangeAnalysis(event)
          }}
          placeholder="This is the property description..."
          style={{ height: 320 }}
        /> */}
    </Grid>
  ) : section.type === 'autocomplete' ? (
    <Grid
      item
      xs={section.xs}
      sm={section.sm}
      md={section.md}
      lg={section.lg}
      xl={section.xl}
    >
      <Autocomplete
        id="checkboxes-tags-demo"
        options={section.options}
        value={section.value}
        disabled={section.disabled || disabled}
        onChange={(e, newValue) => {
          const event = {
            target: {
              name: section.name,
              value: newValue,
              type: 'text',
            },
            preventDefault: () => {
              return null
            },
          }
          section.lotProcess
            ? onChangeLotProcess(event)
            : section.offer
              ? onChangeOffer(event)
              : section.asset
                ? onChangeAsset(event)
                : section.assumptions
                  ? onChangeAssumptions(event)
                  : section.lot
                    ? onChangeLot(event)
                    : section.details
                      ? onChangeProperty(event)
                      : onChangeAnalysis(event)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={replaceStrategyName(section.label, member)}
            placeholder={replaceStrategyName(section.label, member)}
            margin="dense"
          />
        )}
      />
    </Grid>
  ) : section.type === 'select' ? (
    <Grid
      item
      xs={section.xs}
      sm={section.sm}
      md={section.md}
      lg={section.lg}
      xl={section.xl}
      data-tour={section.dataTour}
    >
      <TextField
        name={section.name}
        select={!section.disabled}
        fullWidth={true}
        variant="outlined"
        margin="dense"
        label={section.label}
        value={
          includes(
            concat(
              section.dynamicMemberOptions,
              section.memberOptions,
              section.options,
            ),
            section.value,
          ) ||
          includes(
            concat(
              section.dynamicMemberOptions,
              section.memberOptions,
              section.options,
            ),
            Number(section.value),
          ) ||
          find(
            concat(
              section.dynamicMemberOptions,
              section.memberOptions,
              section.options,
            ),
            {
              value: section.value,
            },
          )
            ? section.value
            : find(
                  concat(
                    section.dynamicMemberOptions,
                    section.memberOptions,
                    section.options,
                  ),
                  {
                    value: Number(section.value),
                  },
                )
              ? Number(section.value)
              : Number(section.value)
        }
        helperText={
          <Typography
            color="primary"
            variant="caption"
            style={section.helperStyle ? section.helperStyle : null}
          >
            {section.helper}
          </Typography>
        }
        disabled={disabled || section.disabled}
        required={section.required}
        onChange={(event) =>
          section.onChange
            ? section.onChange(event)
            : section.lotProcess
              ? onChangeLotProcess(event, true)
              : section.offer
                ? onChangeOffer(event, true)
                : section.asset
                  ? onChangeAsset(event)
                  : section.assumptions
                    ? onChangeAssumptions(event, true)
                    : section.lot
                      ? onChangeLot(event, null, true)
                      : section.details
                        ? onChangeProperty(event, null, true)
                        : onChangeAnalysis(event, null, true)
        }
        SelectProps={{
          renderValue: (x) => {
            let option =
              find(
                concat(
                  section.dynamicMemberOptions,
                  section.memberOptions,
                  section.options,
                ),
                { value: x },
              ) ||
              find(
                concat(
                  section.dynamicMemberOptions,
                  section.memberOptions,
                  section.options,
                ),
                { value: Number(x) },
              )
            if (option)
              return option.active || isNil(option.active)
                ? option.label
                : `Deactivated - ${option.label}`
            else if (
              includes(
                concat(
                  section.dynamicMemberOptions,
                  section.memberOptions,
                  section.options,
                ),
                x,
              )
            )
              return x
            else if (!x) return ''
            else return 'Deleted Option - ' + x
          },
        }}
        InputProps={{
          startAdornment: section.adornmentNumber ? (
            <InputAdornment
              position="start"
              sx={{ marginRight: 0.25 }}
            >
              <Typography
                sx={{
                  width: 20, // Smaller size
                  height: 20,
                  borderRadius: '50%',
                  backgroundColor: 'black', // Black background
                  color: 'white', // White text
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 'bold',
                  fontSize: '0.75rem', // Smaller text
                  padding: 0, // Remove padding
                  textAlign: 'center', // Ensure centered alignment
                  marginLeft: '-6px', // Adjust spacing on the left
                }}
              >
                {section.adornmentNumber}
              </Typography>
            </InputAdornment>
          ) : null,
        }}
      >
        {map(
          filter(section.dynamicMemberOptions, (option) => {
            return (
              option.active ||
              isNil(option.active) ||
              option === section.value ||
              option.value === section.value ||
              Number(option.value) === section.value
            )
          }),
          (option) =>
            option.tip || option.title ? (
              <Tooltip
                title={option.tip}
                key={option.title}
                value={option.value}
              >
                <MenuItem>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <Typography>{option.title}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{option.label}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>MO</Typography>
                    </Grid>
                  </Grid>
                </MenuItem>
              </Tooltip>
            ) : (
              <MenuItem key={option.value} value={option.value}>
                <Grid container alignItems="center">
                  <Grid item>{option.label}</Grid>
                  <Typography style={{ flex: 1 }} />
                  <Grid item>
                    <Typography>MO</Typography>
                  </Grid>
                </Grid>
              </MenuItem>
            ),
        )}
        {map(
          filter(section.memberOptions, (option) => {
            return (
              option.active ||
              isNil(option.active) ||
              option === section.value ||
              option.value === section.value ||
              Number(option.value) === section.value
            )
          }),
          (option) =>
            option.value ? (
              option.tip || option.title ? (
                <Tooltip
                  title={option.tip}
                  key={option.value}
                  value={option.value}
                  followCursor
                >
                  <MenuItem>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <Typography>{option.title}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography>{option.label}</Typography>
                      </Grid>
                      <Typography>MO</Typography>
                    </Grid>
                  </MenuItem>
                </Tooltip>
              ) : (
                <MenuItem key={option.value} value={option.value}>
                  <Grid container alignItems="center">
                    <Grid item>{option.label}</Grid>
                    <Typography style={{ flex: 1 }} />
                    <Typography>MO</Typography>
                  </Grid>
                </MenuItem>
              )
            ) : (
              <MenuItem key={option} value={option}>
                <Grid container alignItems="center">
                  <Grid item>{option}</Grid>
                  <Typography style={{ flex: 1 }} />
                  <Typography>MO</Typography>
                </Grid>
              </MenuItem>
            ),
        )}
        {map(
          filter(section.options, (option) => {
            return (
              option.active ||
              isNil(option.active) ||
              option === section.value ||
              option.value === section.value ||
              Number(option.value) === Number(section.value)
            )
          }),
          (option) =>
            option.value ? (
              option.tip || option.title ? (
                <Tooltip
                  title={option.tip}
                  key={option.value}
                  value={option.value}
                  followCursor
                >
                  <MenuItem>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <Typography>{option.title}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography>{option.label}</Typography>
                      </Grid>
                      <Grid item>
                        {section.editOption &&
                          (option.active || isNil(option.active)) && (
                            <AddOption
                              currentValue={
                                includes(
                                  section.options,
                                  section.value,
                                ) ||
                                find(section.options, {
                                  value: section.value,
                                })
                                  ? section.value
                                  : ''
                              }
                              edit
                              currentOption={option}
                              addOption={section.optionName}
                              addOptionLabel={section.label}
                              addOptionTarget={section.name}
                              onChangeAnalysis={onChangeAnalysis}
                              onChangeLotProcess={onChangeLotProcess}
                              onChangeProperty={onChangeProperty}
                              onChangeOffer={onChangeOffer}
                              onChangeAssumptions={
                                onChangeAssumptions
                              }
                            />
                          )}
                        {section.removeOption &&
                          (option.active || isNil(option.active)) && (
                            <Grid item>
                              <RemoveOption
                                removeOption={section.optionName}
                                removeOptionLabel={section.label}
                                removeOptionValue={option.value}
                                removeOptionItemLabel={option.title}
                              />
                            </Grid>
                          )}
                      </Grid>
                    </Grid>
                  </MenuItem>
                </Tooltip>
              ) : (
                <MenuItem key={option.value} value={option.value}>
                  <Grid container alignItems="center">
                    <Grid item>{option.label}</Grid>
                    <Typography style={{ flex: 1 }} />
                    {section.editOption &&
                      (option.active || isNil(option.active)) && (
                        <AddOption
                          currentValue={
                            includes(
                              section.options,
                              section.value,
                            ) ||
                            find(section.options, {
                              value: section.value,
                            })
                              ? section.value
                              : ''
                          }
                          edit
                          currentOption={option}
                          addOption={section.optionName}
                          addOptionLabel={section.label}
                          addOptionTarget={section.name}
                          onChangeAnalysis={onChangeAnalysis}
                          onChangeLotProcess={onChangeLotProcess}
                          onChangeProperty={onChangeProperty}
                          onChangeOffer={onChangeOffer}
                          onChangeAssumptions={onChangeAssumptions}
                        />
                      )}
                    {section.removeOption &&
                      (option.active || isNil(option.active)) && (
                        <Grid item>
                          <RemoveOption
                            removeOption={section.optionName}
                            removeOptionLabel={section.label}
                            removeOptionValue={option.value}
                            removeOptionItemLabel={option.title}
                          />
                        </Grid>
                      )}
                  </Grid>
                </MenuItem>
              )
            ) : (
              <MenuItem key={option} value={option}>
                <Grid container alignItems="center">
                  <Grid item>{option}</Grid>
                  <Typography style={{ flex: 1 }} />
                  {section.editOption &&
                    (option.active || isNil(option.active)) && (
                      <AddOption
                        currentValue={
                          includes(section.options, section.value) ||
                          find(section.options, {
                            value: section.value,
                          })
                            ? section.value
                            : ''
                        }
                        edit
                        currentOption={option}
                        addOption={section.optionName}
                        addOptionLabel={section.label}
                        addOptionTarget={section.name}
                        onChangeAnalysis={onChangeAnalysis}
                        onChangeLotProcess={onChangeLotProcess}
                        onChangeProperty={onChangeProperty}
                        onChangeOffer={onChangeOffer}
                        onChangeAssumptions={onChangeAssumptions}
                      />
                    )}
                  {section.removeOption &&
                    (option.active || isNil(option.active)) && (
                      <Grid item>
                        <RemoveOption
                          removeOption={section.optionName}
                          removeOptionLabel={section.label}
                          removeOptionValue={option}
                        />
                      </Grid>
                    )}
                </Grid>
              </MenuItem>
            ),
        )}
        {section.optionName && (
          <AddOption
            currentValue={
              includes(section.options, section.value) ||
              find(section.options, { value: section.value })
                ? section.value
                : ''
            }
            addOption={section.optionName}
            addOptionLabel={section.label}
            addOptionTarget={section.name}
            onChangeAnalysis={onChangeAnalysis}
            onChangeLotProcess={onChangeLotProcess}
            onChangeProperty={onChangeProperty}
            onChangeOffer={onChangeOffer}
            onChangeAssumptions={onChangeAssumptions}
          />
        )}
      </TextField>
    </Grid>
  ) : // )
  section.dropdown ? (
    <Grid
      item
      xs={section.xs}
      sm={section.sm}
      md={section.md}
      lg={section.lg}
      xl={section.xl}
      data-tour={section.dataTour}
    >
      <NumericFormat
        customInput={TextField}
        thousandSeparator={section.adornment === '$'}
        prefix={section.adornment === '$' ? '$' : ''}
        suffix={section.adornment === '%' ? '%' : ''}
        fullWidth={true}
        isNumericString={true}
        // decimalScale={0}
        key={section.name}
        name={section.name}
        value={Math.round(section.value) || ''}
        disabled={section.disabled || disabled}
        onValueChange={(values) => {
          const { value } = values
          const event = {
            target: {
              name: section.name,
              value: value,
              type: 'number',
            },
            preventDefault: () => {
              return null
            },
          }
          if (section.disabled || disabled) return null
          if (section.onChange) section.onChange(event)
          else if (section.lotProcess) onChangeLotProcess(event)
          else if (section.offer) onChangeOffer(event)
          else if (section.asset) onChangeAsset(event)
          else if (section.assumptions) onChangeAssumptions(event)
          else if (section.lot) onChangeLot(event)
          else if (section.details)
            onChangeProperty(
              event,
              section.definition ? 'userInput' : null,
            )
          else
            onChangeAnalysis(
              event,
              section.definition ? 'userInput' : null,
            )
          //onChange(event, saveAnalysis)
        }}
        type={'text'}
        onFocus={(event) => {
          event.target.select()
        }}
        variant="outlined"
        margin="dense"
        placeholder={replaceStrategyName(section.label, member)}
        label={replaceStrategyName(section.label, member)}
        fontSize={section.font}
        required={section.required}
        helperText={
          <Typography
            color="primary"
            variant="caption"
            style={section.helperStyle ? section.helperStyle : null}
          >
            {section.helper}
          </Typography>
        }
        InputProps={{
          startAdornment: section.adornmentNumber ? (
            <InputAdornment
              position="start"
              sx={{ marginRight: 0.25 }}
            >
              <Typography
                sx={{
                  width: 20, // Smaller size
                  height: 20,
                  borderRadius: '50%',
                  backgroundColor: 'black', // Black background
                  color: 'white', // White text
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 'bold',
                  fontSize: '0.75rem', // Smaller text
                  padding: 0, // Remove padding
                  textAlign: 'center', // Ensure centered alignment
                  marginLeft: '-6px', // Adjust spacing on the left
                }}
              >
                {section.adornmentNumber}
              </Typography>
            </InputAdornment>
          ) : null,
          endAdornment: (
            <InputAdornment position="end" margin="dense">
              <Button
                onClick={(e) =>
                  section.disabled || disabled
                    ? null
                    : toggleAssumptionsMenuFormat(
                        e,
                        section.adornmentName,
                      )
                }
                size="small"
                margin="dense"
                style={{ padding: 3 }}
                className="btn-neutral-dark d-flex align-items-center"
              >
                <span className="btn-wrapper--label">
                  {section.adornment}
                </span>
                <span className="btn-wrapper--icon d-flex">
                  <FontAwesomeIcon
                    icon={['fas', 'chevron-down']}
                    className="opacity-8 font-size-xs ml-1"
                  />
                </span>
              </Button>
              {section.definition ? (
                <InputAdornment position="end">
                  <Tooltip
                    title={section.tooltipButtonText || ''}
                    followCursor
                  >
                    <IconButton
                      onClick={(event) =>
                        section.disabled || disabled
                          ? null
                          : handleDefinition(
                              section.definition,
                              event.target,
                              section.definitionTarget,
                            )
                      }
                      className="d-30 rounded-sm border-0 mx-auto card-icon-wrapper bg-first text-white btn-icon text-center"
                      size="large"
                    >
                      <FontAwesomeIcon
                        icon={['fal', 'database']}
                        className="font-size-xl"
                      />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ) : null}
              <Menu
                anchorEl={anchorElAssumptions}
                keepMounted
                name={section.adornmentName}
                open={
                  Boolean(anchorElAssumptions) &&
                  section.adornmentName === showFormatDropdown
                }
                onClose={handleAssumptionsMenuClose}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'center',
                }}
                classes={{ list: 'p-0' }}
              >
                <div className="p-2">
                  <List
                    component="div"
                    className="nav-pills p-0 m-0 nav-neutral-dark flex-column"
                  >
                    <ListItem
                      key="$"
                      button
                      href={null}
                      onClick={(e) =>
                        section.assumptions
                          ? handleAdornmentChange(
                              e,
                              section.adornmentName,
                              includes(
                                section.adornmentName,
                                'term',
                              ) ||
                                includes(
                                  section.adornmentName,
                                  'Term',
                                )
                                ? 'Months'
                                : section.adornmentName ===
                                    'estimatedRehabType'
                                  ? 'SQFT $'
                                  : includes(
                                        section.adornmentName,
                                        'avgRateType',
                                      )
                                    ? 'Daily'
                                    : '$',
                            )
                          : handleAnalysisAdornmentChange(
                              e,
                              section.adornmentName,
                              includes(section.adornmentName, 'HOA')
                                ? 'Monthly'
                                : '$',
                            )
                      }
                      selected={
                        section.adornment === '$' ||
                        section.adornment === 'SQFT $' ||
                        section.adornment === 'Months' ||
                        section.adornment === 'Monthly'
                      }
                      className="px-3 mx-2"
                    >
                      <span>
                        {section.assumptions
                          ? includes(section.adornmentName, 'term') ||
                            includes(section.adornmentName, 'Term')
                            ? 'Months'
                            : section.adornmentName ===
                                'estimatedRehabType'
                              ? 'SQFT $'
                              : includes(
                                    section.adornmentName,
                                    'avgRateType',
                                  )
                                ? 'Daily'
                                : '$'
                          : includes(section.adornmentName, 'HOA')
                            ? 'Monthly'
                            : '$'}
                      </span>
                    </ListItem>
                    <ListItem
                      key="%"
                      button
                      href={null}
                      onClick={(e) =>
                        section.assumptions
                          ? handleAdornmentChange(
                              e,
                              section.adornmentName,
                              includes(
                                section.adornmentName,
                                'term',
                              ) ||
                                includes(
                                  section.adornmentName,
                                  'Term',
                                )
                                ? 'Years'
                                : section.adornmentName ===
                                    'estimatedRehabType'
                                  ? 'Total $'
                                  : includes(
                                        section.adornmentName,
                                        'avgRateType',
                                      )
                                    ? 'Weekly'
                                    : '%',
                            )
                          : handleAnalysisAdornmentChange(
                              e,
                              section.adornmentName,
                              includes(section.adornmentName, 'HOA')
                                ? 'Yearly'
                                : '%',
                            )
                      }
                      selected={
                        section.adornment === '%' ||
                        section.adornment === 'Total $' ||
                        section.adornment === 'Years' ||
                        section.adornment === 'Yearly'
                      }
                      className="px-3 mx-2"
                    >
                      <span>
                        {section.assumptions
                          ? includes(section.adornmentName, 'term') ||
                            includes(section.adornmentName, 'Term')
                            ? 'Years'
                            : section.adornmentName ===
                                'estimatedRehabType'
                              ? 'Total $'
                              : includes(
                                    section.adornmentName,
                                    'avgRateType',
                                  )
                                ? 'Weekly'
                                : '%'
                          : includes(section.adornmentName, 'HOA')
                            ? 'Yearly'
                            : '%'}
                      </span>
                    </ListItem>
                  </List>
                </div>
              </Menu>
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  ) : section.dropdownDate ? (
    <Grid item style={{ marginRight: 5 }}>
      <TextField
        select
        variant="outlined"
        margin="dense"
        label="Summary Date"
        fullWidth={true}
        style={{ width: 175 }}
        value={section.value}
        onChange={section.onChange}
        required={true}
      >
        <MenuItem /*key={'Fix & Flip'}*/ value={'Current Month'}>
          {'Current Month'}
        </MenuItem>
        <MenuItem /*key={'Buy & Hold'}*/ value={'YTD'}>
          {'YTD'}
        </MenuItem>
        <MenuItem /*key={'Buy & Hold'}*/ value={'12 Month Rolling'}>
          {'12 Month Rolling'}
        </MenuItem>
      </TextField>
    </Grid>
  ) : section.name === 'exchange' ? (
    <Grid
      container
      item
      xs={section.xs}
      sm={section.sm}
      md={section.md}
      lg={section.lg}
      xl={section.xl}
      direction="row"
      alignItems="center"
    >
      <Grid item>
        <Checkbox
          color="primary"
          key={section.name + '_key'}
          name={section.name}
          type="checkbox"
          value={section.value}
          checked={section.value}
          required={section.required}
          onChange={(event) =>
            section.disabled || disabled
              ? null
              : onChangeExchange(event, section.label, true)
          }
        />
      </Grid>
      <Grid item>
        <Typography>
          {replaceStrategyName(section.label, member)}
        </Typography>
      </Grid>
    </Grid>
  ) : section.type === 'checkbox' ? (
    <Grid
      item
      xs={section.xs}
      sm={section.sm}
      md={section.md}
      lg={section.lg}
      xl={section.xl}
      data-tour={section.dataTour}
    >
      <FormControlLabel
        onChange={
          section.onChange
            ? section.onChange
            : section.lotProcess
              ? (event) => onChangeLotProcess(event, true)
              : section.offer
                ? (event) => onChangeOffer(event, true)
                : section.asset
                  ? onChangeAsset
                  : section.assumptions
                    ? (event) => onChangeAssumptions(event, true)
                    : section.lot
                      ? (event) => onChangeLot(event, null, true)
                      : section.details
                        ? (event) =>
                            onChangeProperty(event, null, true)
                        : (event) =>
                            onChangeAnalysis(event, null, true)
        }
        control={
          <Checkbox
            id={section.label}
            checked={Boolean(section.value)}
            name={section.name}
          />
        }
        label={replaceStrategyName(section.label, member)}
        disabled={section.disabled || disabled}
        required={section.required}
      />
    </Grid>
  ) : section.type === 'dollar' ? (
    <Grid
      item
      xs={section.xs}
      sm={section.sm}
      md={section.md}
      lg={section.lg}
      xl={section.xl}
      data-tour={section.dataTour}
    >
      <NumericFormat
        customInput={TextField}
        thousandSeparator={true}
        prefix={'$'}
        fullWidth={true}
        isNumericString={true}
        decimalScale={0}
        key={section.name}
        name={section.name}
        value={Math.round(section.value) || ''}
        disabled={section.disabled || disabled}
        onValueChange={(values) => {
          const { value } = values
          const event = {
            target: {
              name: section.name,
              value: value,
              type: 'number',
            },
            preventDefault: () => {
              return null
            },
          }
          if (section.disabled || disabled) return null
          if (section.onChange) section.onChange(event)
          else if (section.lotProcess) onChangeLotProcess(event)
          else if (section.offer) onChangeOffer(event)
          else if (section.asset) onChangeAsset(event)
          else if (section.assumptions) onChangeAssumptions(event)
          else if (section.lot) onChangeLot(event)
          else if (section.details)
            onChangeProperty(
              event,
              section.definition ? 'userInput' : null,
            )
          else
            onChangeAnalysis(
              event,
              section.definition ? 'userInput' : null,
            )
          //onChange(event, saveAnalysis)
        }}
        type={'text'}
        onFocus={(event) => {
          event.target.select()
        }}
        variant="outlined"
        margin="dense"
        label={replaceStrategyName(section.label, member)}
        fontSize={section.font}
        required={section.required}
        helperText={
          <Typography
            color="primary"
            variant="caption"
            style={section.helperStyle ? section.helperStyle : null}
          >
            {section.helper}
          </Typography>
        }
        InputProps={{
          // inputComponent: NumericFormatCustom, // Use the custom NumericFormat component
          startAdornment: section.adornmentNumber ? (
            <InputAdornment
              position="start"
              sx={{ marginRight: 0.25 }}
            >
              <Typography
                sx={{
                  width: 20, // Smaller size
                  height: 20,
                  borderRadius: '50%',
                  backgroundColor: 'black', // Black background
                  color: 'white', // White text
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 'bold',
                  fontSize: '0.75rem', // Smaller text
                  padding: 0, // Remove padding
                  textAlign: 'center', // Ensure centered alignment
                  marginLeft: '-6px', // Adjust spacing on the left
                }}
              >
                {section.adornmentNumber}
              </Typography>
            </InputAdornment>
          ) : null,
          // endAdornment: (
          //   <InputAdornment
          //     position="end"
          //     sx={{ marginRight: '-8px' }} // Reduce spacing on the right
          //   >
          //     <Tooltip title={''} followCursor>
          //       <IconButton
          //         className="d-30 rounded-sm border-0 mx-auto card-icon-wrapper bg-first text-white btn-icon text-center"
          //         size="large"
          //       >
          //         <FontAwesomeIcon
          //           icon={['fal', 'database']}
          //           className="font-size-xl"
          //         />
          //       </IconButton>
          //     </Tooltip>
          //   </InputAdornment>
          // ),
          endAdornment: section.definition ? (
            <InputAdornment position="end">
              <Tooltip
                title={section.tooltipButtonText || ''}
                followCursor
              >
                <IconButton
                  data-tour={section.dataTourDatabase}
                  onClick={(event) =>
                    section.disabled || disabled
                      ? null
                      : handleDefinition(
                          section.definition,
                          event.target,
                          section.definitionTarget,
                        )
                  }
                  className="d-30 rounded-sm border-0 mx-auto card-icon-wrapper bg-first text-white btn-icon text-center"
                  size="large"
                >
                  <FontAwesomeIcon
                    data-tour={section.dataTourDatabase}
                    icon={['fal', 'database']}
                    className="font-size-xl"
                  />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ) : null,
        }}
      />
    </Grid>
  ) : section.type === 'datePicker' ? (
    <Grid item xs={section.xs} lg={section.lg}>
      <DatePicker
        name={section.name}
        label={replaceStrategyName(section.label, member)}
        value={section.value ? section.value : null}
        minDate={section.min}
        maxDate={section.max}
        format="MM/dd/yyyy"
        required={section.required}
        onChange={(newValue) => {
          const event = {
            target: {
              name: section.name,
              value: newValue,
              type: 'date',
            },
            preventDefault: () => {
              return null
            },
          }
          if (section.disabled || disabled) return null
          if (section.onChange) section.onChange(event)
          else if (section.lotProcess) onChangeLotProcess(event)
          else if (section.offer) onChangeOffer(event)
          else if (section.asset) onChangeAsset(event)
          else if (section.assumptions) onChangeAssumptions(event)
          else if (section.lot) onChangeLot(event)
          else if (section.details) onChangeProperty(event)
          else onChangeAnalysis(event)
          //onChange(event, saveAnalysis)
        }}
        fullWidth={true}
        sx={{ width: '100% ' }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            margin="dense"
            required={section.required}
          />
        )}
      />
    </Grid>
  ) : section.type === 'date' ? (
    <Grid
      item
      xs={section.xs}
      lg={section.lg}
      data-tour={section.dataTour}
    >
      <DatePicker
        name={section.name}
        label={replaceStrategyName(section.label, member)}
        value={section.value ? section.value : null}
        minDate={section.min}
        maxDate={section.max}
        format="MM/dd/yyyy"
        required={section.required}
        fullWidth={true}
        sx={{ width: '100% ' }}
        onChange={(newValue) => {
          const event = {
            target: {
              name: section.name,
              value: newValue,
              type: 'date',
            },
            preventDefault: () => {
              return null
            },
          }
          if (section.disabled || disabled) return null
          if (section.onChange) section.onChange(event)
          else if (section.lotProcess) onChangeLotProcess(event)
          else if (section.offer) onChangeOffer(event)
          else if (section.asset) onChangeAsset(event)
          else if (section.assumptions) onChangeAssumptions(event)
          else if (section.lot) onChangeLot(event)
          else if (section.details) onChangeProperty(event)
          else onChangeAnalysis(event)
          //onChange(event, saveAnalysis)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            margin="dense"
            required={section.required}
            fullWidth={true}
          />
        )}
      />
    </Grid>
  ) : (
    <Grid
      item
      xs={section.xs}
      sm={section.sm}
      md={section.md}
      lg={section.lg}
      xl={section.xl}
      data-tour={section.dataTour}
      //alignItems='center'
    >
      <TextField
        name={section.name}
        variant="outlined"
        margin="dense"
        value={section.value || ''}
        onChange={(event) =>
          section.onChange
            ? section.onChange(event)
            : section.lotProcess
              ? onChangeLotProcess(event)
              : section.offer
                ? onChangeOffer(event)
                : section.asset
                  ? onChangeAsset(event)
                  : section.assumptions
                    ? onChangeAssumptions(event)
                    : section.lot
                      ? onChangeLot(event)
                      : section.details
                        ? onChangeProperty(event)
                        : onChangeAnalysis(event)
        }
        onBlur={section.onBlur ? section.onBlur : null}
        placeholder={replaceStrategyName(section.label, member)}
        label={replaceStrategyName(section.label, member)}
        type={section.type === 'percentage' ? 'number' : section.type}
        fullWidth={true}
        disabled={section.disabled || disabled}
        required={section.required}
        helperText={
          <Typography
            color="primary"
            variant="caption"
            style={section.helperStyle ? section.helperStyle : null}
          >
            {section.helper}
          </Typography>
        }
        InputProps={{
          startAdornment: section.adornmentNumber ? (
            <InputAdornment
              position="start"
              sx={{ marginRight: 0.25 }}
            >
              <Typography
                sx={{
                  width: 20, // Smaller size
                  height: 20,
                  borderRadius: '50%',
                  backgroundColor: 'black', // Black background
                  color: 'white', // White text
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 'bold',
                  fontSize: '0.75rem', // Smaller text
                  padding: 0, // Remove padding
                  textAlign: 'center', // Ensure centered alignment
                  marginLeft: '-6px', // Adjust spacing on the left
                }}
              >
                {section.adornmentNumber}
              </Typography>
            </InputAdornment>
          ) : null,
          endAdornment:
            section.type === 'percentage' ? (
              <>
                <InputAdornment position="end">%</InputAdornment>
                {section.definition ? (
                  <InputAdornment position="end">
                    <Tooltip
                      title={section.tooltipButtonText || ''}
                      followCursor
                    >
                      <IconButton
                        onClick={(event) =>
                          section.disabled || disabled
                            ? null
                            : handleDefinition(
                                section.definition,
                                event.target,
                                section.definitionTarget,
                              )
                        }
                        className="d-30 rounded-sm border-0 mx-auto card-icon-wrapper bg-first text-white btn-icon text-center"
                        size="large"
                      >
                        <FontAwesomeIcon
                          icon={['fal', 'database']}
                          className="font-size-xl"
                        />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ) : null}
              </>
            ) : section.adornmentPosition === 'end' ? (
              <>
                <InputAdornment position="end">
                  {section.adornment}
                </InputAdornment>
                {section.definition ? (
                  <InputAdornment position="end">
                    <Tooltip
                      title={section.tooltipButtonText || ''}
                      followCursor
                    >
                      <IconButton
                        onClick={(event) =>
                          section.disabled || disabled
                            ? null
                            : handleDefinition(
                                section.definition,
                                event.target,
                                section.definitionTarget,
                              )
                        }
                        className="d-30 rounded-sm border-0 mx-auto card-icon-wrapper bg-first text-white btn-icon text-center"
                        size="large"
                      >
                        <FontAwesomeIcon
                          icon={['fal', 'database']}
                          className="font-size-xl"
                        />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ) : null}
              </>
            ) : null,
        }}
        InputLabelProps={{
          shrink: section.type === 'date' || section.value,
        }}
      />
    </Grid>
  )
}

const renderInputFieldsFunction =
  (onChangeFunctions, dropdownProps, member) =>
  (disabled) =>
  (section) => {
    if (section.tooltipField)
      return (
        <>
          {/* <Tooltip
          title={
            dropdownProps &&
            Boolean(dropdownProps.anchorElAssumptions)
              ? ''
              : section.tooltipField
          }
          followCursor
        > */}
          {renderInput(
            section,
            member,
            disabled,
            onChangeFunctions,
            dropdownProps,
          )}
          {/* </Tooltip> */}
        </>
      )
    return renderInput(
      section,
      member,
      disabled,
      onChangeFunctions,
      dropdownProps,
    )
  }

export default renderInputFieldsFunction
